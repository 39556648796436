import React, { useContext, useState } from "react";
import { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";
import { NotificationContext } from "../../contexts/notification.contexts";
import { PostVloggersHttp } from "../../http/vloggers/post.vloggers.http";
import {
  IVloggersFormData,
  VloggerFormVloggersOrganism,
} from "./vlogger-form.vloggers.organisms";

export const AddVloggerFormVloggersOrganism: FunctionComponent = () => {
  const errorContext = useContext(NotificationContext);
  const navigate = useNavigate();

  const [creatingVlogger, setCreatingVlogger] = useState<boolean>(false);

  async function addVlogger(data: IVloggersFormData) {
    setCreatingVlogger(true);

    const response = await new PostVloggersHttp().create({
      name: data.name,
      urlSlug: data.urlSlug,
      priceRange: data.priceRange,
      description: data.description,
      uuidListGallery: data.gallery.map((i) => ({
        uuid: i.uuid,
        index: i.index,
      })),
      uuidProfilePicture: data.profilePicture?.uuid || null,
      visibleOnSite: data.visibleOnSite,
    });
    if (response.error) {
      await errorContext.showError({
        message: response.errorData.message,
      });
      return setCreatingVlogger(false);
    }
    navigate(-1);
  }

  return (
    <VloggerFormVloggersOrganism
      initialData={{
        name: "",
        urlSlug: "",
        description: "",
        visibleOnSite: false,
        gallery: [],
        priceRange: null,
        profilePicture: null,
      }}
      formType="add"
      submitInProgress={creatingVlogger}
      setSubmitInProgress={setCreatingVlogger}
      onSubmitForm={addVlogger}
    />
  );
};
