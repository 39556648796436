const STRING_DEFAULT_MAX_LENGTH = 10000
const STRING_DEFAULT_MIN_LENGTH = 1

export class ValidatorConstraints {
  static RESULT_DEFAULT_LIMIT = 100
  static URL_SLUG = {
    MIN_LENGTH: 1,
    MAX_LENGTH: 1000,
  }

  static ACCOUNT = {
    NAME: {
      MAX_LENGTH: STRING_DEFAULT_MAX_LENGTH,
      MIN_LENGHT: STRING_DEFAULT_MIN_LENGTH,
    },
    EMAIL: {
      MAX_LENGTH: STRING_DEFAULT_MAX_LENGTH,
      MIN_LENGHT: STRING_DEFAULT_MIN_LENGTH,
    },
    PASSWORD: {
      MAX_LENGTH: STRING_DEFAULT_MAX_LENGTH,
      MIN_LENGHT: 5,
    },
  }

  static LOCATION = {
    NAME: {
      MAX_LENGTH: STRING_DEFAULT_MAX_LENGTH,
      MIN_LENGHT: STRING_DEFAULT_MIN_LENGTH,
    },
    DESCRIPTION: {
      MAX_LENGTH: STRING_DEFAULT_MAX_LENGTH,
      MIN_LENGHT: STRING_DEFAULT_MIN_LENGTH,
    },
  }

  static VLOGGERS = {
    NAME: {
      MAX_LENGTH: STRING_DEFAULT_MAX_LENGTH,
      MIN_LENGHT: STRING_DEFAULT_MIN_LENGTH,
    },
    DESCRIPTION: {
      MAX_LENGTH: STRING_DEFAULT_MAX_LENGTH,
      MIN_LENGHT: STRING_DEFAULT_MIN_LENGTH,
    },
  }

  static ACTIVITIES = {
    NAME: {
      MAX_LENGTH: STRING_DEFAULT_MAX_LENGTH,
      MIN_LENGHT: STRING_DEFAULT_MIN_LENGTH,
    },
    DESCRIPTION: {
      MAX_LENGTH: STRING_DEFAULT_MAX_LENGTH,
      MIN_LENGHT: STRING_DEFAULT_MIN_LENGTH,
    },
  }

  static PROPERTIES = {
    NAME: {
      MAX_LENGTH: STRING_DEFAULT_MAX_LENGTH,
      MIN_LENGHT: STRING_DEFAULT_MIN_LENGTH,
    },
    DESCRIPTION: {
      MAX_LENGTH: STRING_DEFAULT_MAX_LENGTH,
      MIN_LENGHT: STRING_DEFAULT_MIN_LENGTH,
    },
  }

  static ASSETS = {
    URL: {
      MAX_LENGTH: STRING_DEFAULT_MAX_LENGTH,
      MIN_LENGHT: 5,
    },
  }
}
