import React from "react";
import { FunctionComponent } from "react";
import { ContentContainer } from "../../elements/content-container.elements";
import { HeaderElement } from "../../elements/header.elements";
import { AddLocationFormLocationsOrganism } from "../../organisms/locations/add-location-form.locations.organisms";

export const AddLocationsPage: FunctionComponent = () => {
  return (
    <>
      <HeaderElement title="Add Location" />
      <ContentContainer>
        <AddLocationFormLocationsOrganism />
      </ContentContainer>
    </>
  );
};
