import React from "react";
import { FunctionComponent } from "react";
import { ContentContainer } from "../../elements/content-container.elements";
import { HeaderElement } from "../../elements/header.elements";
import { AddEntertainmentCategoryFormOrganism } from "../../organisms/entertainments-categories/add-form.entertainments-categories.organisms";

export const AddEntertainmentsCategoriesPage: FunctionComponent = () => {
  return (
    <>
      <HeaderElement title="Add Entertainment Category" />
      <ContentContainer>
        <AddEntertainmentCategoryFormOrganism />
      </ContentContainer>
    </>
  );
};
