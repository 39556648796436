import React, {
  forwardRef,
  useContext,
  useImperativeHandle,
  useState,
} from "react";
import Dropzone from "react-dropzone";
import { NotificationContext } from "../contexts/notification.contexts";
import { PostAssetsHttp } from "../http/assets/post.assets.http";
import { IAssetCommon } from "../interfaces/common/asset.common.interfaces";
import { IFunctionReturn } from "../interfaces/common/function-return.common.interfaces";

export interface IGetFinalPictureResult {
  picture: IAssetCommon | null;
}

export interface IDropzoneSinglePictureElementRef {
  getFinalPicture: () => Promise<IFunctionReturn<IGetFinalPictureResult>>;
}

export interface IDropzoneSinglePictureElementProps {
  picture: IAssetCommon | null;
}

export const DropzoneSinglePictureElement = forwardRef<
  IDropzoneSinglePictureElementRef,
  IDropzoneSinglePictureElementProps
>(({ picture }, ref) => {
  const notificationContext = useContext(NotificationContext);
  const [pictureFile, setPictureFile] = useState<File | null>(null);

  useImperativeHandle(ref, () => ({
    getFinalPicture,
  }));

  async function getFinalPicture(): Promise<
    IFunctionReturn<IGetFinalPictureResult>
  > {
    let finalPicture: IAssetCommon | null = picture;
    if (pictureFile !== null) {
      const fileUploadResponse = await new PostAssetsHttp().create({
        file: pictureFile,
      });
      if (fileUploadResponse.error) {
        await notificationContext.showError({
          message: fileUploadResponse.errorData.message,
        });
        return {
          error: true,
          message: fileUploadResponse.errorData.message,
        };
      }
      finalPicture = {
        uuid: fileUploadResponse.successData.asset.uuid,
        url: fileUploadResponse.successData.asset.url,
      };
    }
    return {
      error: false,
      data: {
        picture: finalPicture,
      },
    };
  }

  return (
    <div className="flex flex-1 flex-col aspect-video">
      <Dropzone
        maxFiles={1}
        accept={{
          "image/png": [".png", ".jpg", ".jpeg"],
        }}
        onDrop={(acceptedFiles) => {
          const file = acceptedFiles[0];
          if (file) {
            setPictureFile(file);
          }
        }}
      >
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            <div className="cursor-pointer flex flex-1 w-full max-w-lg aspect-video overflow-hidden bg-neutral-200 rounded-md">
              {pictureFile ? (
                <img
                  src={URL.createObjectURL(pictureFile)}
                  className="w-full h-full object-contain"
                  alt="Thumbnail"
                />
              ) : (
                <>
                  {picture ? (
                    <img
                      src={picture.url}
                      className="w-full h-full object-contain"
                      alt="Thumbnail"
                    />
                  ) : (
                    <p className="flex flex-1 p-4 text-center">
                      Drag 'n' drop an image, or click to select a file
                    </p>
                  )}
                </>
              )}
            </div>
          </div>
        )}
      </Dropzone>
    </div>
  );
});
