import { FunctionComponent, useState } from "react";
import { Dialog } from "@headlessui/react";
import React from "react";

import { ReactComponent as DeleteSvg } from "../assets/svgs/delete.svg";
import LoadingElement from "./loading.elements";

export interface IDeleteModalButtonElementProps {
  uuid: string;
  itemToDelete: string;
  onClickDelete: (uuid: string) => Promise<{ success: boolean }>;
}

export const DeleteModalButtonElement: FunctionComponent<
  IDeleteModalButtonElementProps
> = ({ uuid, itemToDelete, onClickDelete }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [deletionInProgress, setDeletionInProgress] = useState(false);

  async function handleClickDelete() {
    setDeletionInProgress(true);
    const result = await onClickDelete(uuid);
    if (result.success) {
      setIsOpen(false);
    } else {
      setDeletionInProgress(false);
    }
  }

  async function handleClickCancel() {
    setIsOpen(false);
    setDeletionInProgress(false);
  }

  async function handClickButton() {
    setIsOpen(true);
  }

  return (
    <>
      <button onClick={handClickButton}>
        <DeleteSvg className="w-8 h-8 cursor-pointer fill-neutral-600 hover:fill-red-600" />
      </button>

      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        className="relative z-10"
      >
        <div className="fixed inset-0 z-20 bg-black/40 flex flex-1 items-center">
          <Dialog.Panel
            className={
              "container max-w-screen-sm mx-auto bg-white border rounded-xl p-12"
            }
          >
            <Dialog.Title className={"text-2xl font-semibold pb-4"}>
              Delete {itemToDelete}?
            </Dialog.Title>

            <Dialog.Description className={"pb-4"}>
              Are you sure you want to delete this {itemToDelete}? This action
              cannot be undone.
            </Dialog.Description>

            <button
              onClick={handleClickDelete}
              className="relative px-4 py-2 bg-red-200 rounded-md font-semibold overflow-hidden"
            >
              Delete
              {deletionInProgress && (
                <div className="absolute inset-0 flex items-center justify-center bg-red-200">
                  <LoadingElement colorClasses="border-t-red-400" />
                </div>
              )}
            </button>
            <button onClick={handleClickCancel} className="px-4 py-2">
              Cancel
            </button>
          </Dialog.Panel>
        </div>
      </Dialog>
    </>
  );
};
