import React, { useContext, useState } from "react";
import { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";
import { NotificationContext } from "../../contexts/notification.contexts";
import { PostCollectionHttp } from "../../http/collections/post.collections.http";
import {
  ICollectionsFormData,
  CollectionsFormCollectionsOrganism,
} from "./collection-form.collections.organisms";

export const AddCollectionFormCollectionsOrganism: FunctionComponent = () => {
  const errorContext = useContext(NotificationContext);
  const navigate = useNavigate();

  const [creatingCollection, setCreatingCollection] = useState<boolean>(false);

  async function addCollection(data: ICollectionsFormData) {
    setCreatingCollection(true);
    const response = await new PostCollectionHttp().create({
      name: data.name,
      urlSlug: data.urlSlug,
      description: data.description,
      visibleOnSite: data.visibleOnSite,
      uuidThumbnailPicture: data.thumbnailPicture?.uuid || null,
    });
    if (response.error) {
      await errorContext.showError({
        message: response.errorData.message,
      });
      return setCreatingCollection(false);
    }
    navigate(-1);
  }

  return (
    <CollectionsFormCollectionsOrganism
      initialData={{
        name: "",
        urlSlug: "",
        description: "",
        visibleOnSite: false,
        thumbnailPicture: null,
      }}
      formType="add"
      submitInProgress={creatingCollection}
      setSubmitInProgress={setCreatingCollection}
      onSubmitForm={addCollection}
    />
  );
};
