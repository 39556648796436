import React, { useState } from "react";
import { FunctionComponent } from "react";
import { useLocation } from "react-router-dom";
import { ContentContainer } from "../../elements/content-container.elements";
import { HeaderElement } from "../../elements/header.elements";
import { TemplateInvoiceOrganism } from "../../organisms/invoice/template.invoice.organisms";

export const IndexInvoicePage: FunctionComponent = () => {
  const { pathname } = useLocation();

  const [isPreview, setIsPreview] = useState(false);
  const [isIgst, setIsIgst] = useState(false);

  return (
    <>
      <HeaderElement
        title="Invoice"
        action={{
          title: "Add Client",
          href: `${pathname}/add`,
        }}
      />
      <ContentContainer>
        <div className="flex flex-1 flex-col">
          <div className="flex items-center flex-col">
            <div className="flex flex-1 border rounded-md w-[794px]">
              <div className="flex p-4">
                <input
                  checked={isPreview}
                  type="checkbox"
                  name="isPreview"
                  onChange={(ev) => setIsPreview(ev.target.checked)}
                />
                <label htmlFor="isPreview">Preview</label>
              </div>

              <div className="flex p-4">
                <input
                  checked={isIgst}
                  type="checkbox"
                  name="isIgst"
                  onChange={(ev) => setIsIgst(ev.target.checked)}
                />
                <label htmlFor="isIgst">IGST</label>
              </div>
            </div>
            <div className="px-4 py-8 max-w-[794px] min-w-[794px]">
              <TemplateInvoiceOrganism
                isIgst={isIgst}
                isPreview={isPreview}
                invoiceData={{
                  invoiceNo: "",
                  invoiceDate: "",
                  dueDate: "",
                  billedBy: {
                    name: "Plithos Technologies Private Limited",
                    address:
                      "7/J, 1st Floor, Above Cafe Iris 18th Cross Road, Sector 3,\nHSR Layout,\nBangalore,\nKarnataka, India - 560102",
                    gstin: "29AAMCP5110F1ZG",
                  },
                  billedTo: {
                    name: "",
                    address: "",
                    gstin: "",
                  },
                  account: {
                    holderName: "Plithos Technologies Private Limited",
                    number: "50200064051034",
                    ifsc: "HFDFC0001993",
                    type: "Current",
                    bank: "HDFC Bank",
                  },
                  invoiceItems: {
                    [Date.now()]: {
                      item: "",
                      hsnSac: "",
                      gstRate: "",
                      quantity: "1",
                      rate: "",
                      amount: "",
                      note: "",
                      gstAmount: "",
                      total: "",
                    },
                  },
                  amount: "",
                  discount: "",
                  advance: "",
                  gstAmount: "",
                  totalAmount: "",
                }}
              />
            </div>
          </div>
        </div>
      </ContentContainer>
    </>
  );
};
