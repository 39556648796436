import React from "react";
import { FunctionComponent } from "react";
import { ContentContainer } from "../../elements/content-container.elements";
import { HeaderElement } from "../../elements/header.elements";
import { AddCollectionFormCollectionsOrganism } from "../../organisms/collections/add-collection-form.collection.organisms";

export const AddCollectionsPage: FunctionComponent = () => {
  return (
    <>
      <HeaderElement title="Add Collection" />
      <ContentContainer>
        <AddCollectionFormCollectionsOrganism />
      </ContentContainer>
    </>
  );
};
