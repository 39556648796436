import React, { FunctionComponent, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import LoadingElement from "../../elements/loading.elements";

import { ReactComponent as EditSvg } from "../../assets/svgs/edit.svg";
import { IVloggersModel } from "../../interfaces/models/vloggers.models.interfaces";
import { DeleteModalButtonElement } from "../../elements/delete-modal-button.elements";
import { DeleteVloggersHttp } from "../../http/vloggers/delete.vloggers.http";
import { NotificationContext } from "../../contexts/notification.contexts";

export interface IVloggerListOrganismProps {
  loading: boolean;
  vloggers?: Array<IVloggersModel>;
  setVloggers: (vloggers: Array<IVloggersModel>) => void;
}

export const VloggersListOrganism: FunctionComponent<
  IVloggerListOrganismProps
> = ({ loading, vloggers, setVloggers }) => {
  const notificationContext = useContext(NotificationContext);
  const { pathname } = useLocation();

  if (!vloggers || loading) {
    return (
      <div className="flex flex-1 justify-center">
        <LoadingElement />
      </div>
    );
  }

  async function deleteVlogger(
    uuidVloggerToDelete: string
  ): Promise<{ success: boolean }> {
    if (!vloggers) throw new Error("vloggers cannot be undefined");
    const response = await new DeleteVloggersHttp().deleteByUuid({
      uuid: uuidVloggerToDelete,
    });
    if (response.error) {
      await notificationContext.showError({
        message: response.errorData.message,
      });
      return {
        success: false,
      };
    }
    setVloggers(vloggers.filter((loc) => loc.uuid !== uuidVloggerToDelete));
    return {
      success: true,
    };
  }

  if (vloggers.length === 0) {
    return <p className="w-full text-center">No Vloggers found</p>;
  }

  return (
    <ul className="flex flex-1 flex-col">
      {vloggers.map((vlogger) => {
        return (
          <li
            key={vlogger.uuid}
            className="flex flex-1 flex-col border rounded-xl p-4 mb-4 grid grid-cols-5 gap-6"
          >
            <div className="aspect-square overflow-hidden bg-neutral-200 col-span-2 rounded-md">
              {vlogger.profilePicture && (
                <img
                  src={vlogger.profilePicture.url}
                  alt=""
                  className="w-full h-full object-cover"
                />
              )}
            </div>
            <div className="flex flex-1 flex-row col-span-3">
              <div className="flex flex-1 flex-col">
                <h2 className="text-2xl font-semibold mb-4">{vlogger.name}</h2>
                <p>{vlogger.description}</p>
              </div>
              <ul className="flex flex-col pl-4">
                <Link to={`${pathname}/${vlogger.uuid}/edit`}>
                  <EditSvg className="w-8 h-8 fill-neutral-600 hover:fill-blue-600" />
                </Link>
                <div className="mt-6">
                  <DeleteModalButtonElement
                    uuid={vlogger.uuid}
                    itemToDelete="Vlogger"
                    onClickDelete={deleteVlogger}
                  />
                </div>
              </ul>
            </div>
          </li>
        );
      })}
    </ul>
  );
};
