import React, { FunctionComponent, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import LoadingElement from "../../elements/loading.elements";

import { ReactComponent as EditSvg } from "../../assets/svgs/edit.svg";
import { IActivityModel } from "../../interfaces/models/activities.models.interfaces";
import { DeleteModalButtonElement } from "../../elements/delete-modal-button.elements";
import { DeleteActivitiesHttp } from "../../http/activities/delete.activities.http";
import { NotificationContext } from "../../contexts/notification.contexts";

export interface IActivityListOrganismProps {
  loading: boolean;
  activities?: Array<IActivityModel>;
  setActivities: (activities: Array<IActivityModel>) => void;
}

export const ActivitiesListOrganism: FunctionComponent<
  IActivityListOrganismProps
> = ({ loading, activities, setActivities }) => {
  const notificationContext = useContext(NotificationContext);
  const { pathname } = useLocation();

  if (!activities || loading) {
    return (
      <div className="flex flex-1 justify-center">
        <LoadingElement />
      </div>
    );
  }

  async function deleteActivity(
    uuidActivityToDelete: string
  ): Promise<{ success: boolean }> {
    if (!activities) throw new Error("activities cannot be undefined");
    const response = await new DeleteActivitiesHttp().deleteByUuid({
      uuid: uuidActivityToDelete,
    });
    if (response.error) {
      await notificationContext.showError({
        message: response.errorData.message,
      });
      return {
        success: false,
      };
    }
    setActivities(
      activities.filter((loc) => loc.uuid !== uuidActivityToDelete)
    );
    return {
      success: true,
    };
  }

  if (activities.length === 0) {
    return <p className="w-full text-center">No Activities found</p>;
  }

  return (
    <ul className="flex flex-1 flex-col">
      {activities.map((activity) => {
        return (
          <li
            key={activity.uuid}
            className="flex flex-1 flex-col border rounded-xl p-4 mb-4 grid grid-cols-5 gap-6"
          >
            <div className="aspect-video overflow-hidden bg-neutral-200 col-span-2 rounded-md">
              {activity.heroPictures[0] && (
                <img src={activity.heroPictures[0].url} alt="" />
              )}
            </div>
            <div className="flex flex-1 flex-row col-span-3">
              <div className="flex flex-1 flex-col">
                <h2 className="text-2xl font-semibold mb-4">{activity.name}</h2>
                <p>{activity.description}</p>
              </div>
              <ul className="flex flex-col pl-4">
                <Link to={`${pathname}/${activity.uuid}/edit`}>
                  <EditSvg className="w-8 h-8 fill-neutral-600 hover:fill-blue-600" />
                </Link>
                <div className="mt-6">
                  <DeleteModalButtonElement
                    uuid={activity.uuid}
                    itemToDelete="Activity"
                    onClickDelete={deleteActivity}
                  />
                </div>
              </ul>
            </div>
          </li>
        );
      })}
    </ul>
  );
};
