import { IResponse } from "../../utils/response.utils";
import Net from "../net.http";

export interface IGetPdfGeneratorHttpData {
  url: string;
  filename: string;
}

export interface IGetPdfGeneratorHttpResult {
  url: string;
}

export class GetPdfGeneratorHttp {
  async generate(
    data: IGetPdfGeneratorHttpData
  ): Promise<IResponse<IGetPdfGeneratorHttpResult>> {
    const response = await Net.getInstance().post<IGetPdfGeneratorHttpResult>({
      path: "/pdf-generator/invoice",
      body: {
        url: data.url,
        filename: data.filename,
      },
    });
    return response;
  }
}
