import React, { useContext, useEffect, useState } from "react";
import { FunctionComponent } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { NotificationContext } from "../../contexts/notification.contexts";
import LoadingElement from "../../elements/loading.elements";
import { GetEntertainmentsCategoriesHttp } from "../../http/entertainments-categories/get.entertainments-categories.http";
import { PutEntertainmentsCategoriesHttp } from "../../http/entertainments-categories/put.entertainments-categories.http";
import { IEntertainmentsCategoryModel } from "../../interfaces/models/entertainments-categories.models.interfaces";
import {
  IEntertainmentsCategoryFormData,
  FormEntertainmentsCategoryOrganism,
} from "./form.entertainments-categories.organisms";

export interface IEditFormEntertainmentsCategoryOrganismProps {}

export const EditFormEntertainmentsCategoryOrganism: FunctionComponent<
  IEditFormEntertainmentsCategoryOrganismProps
> = () => {
  const { uuidEntertainmentsCategory } = useParams();
  const navigate = useNavigate();
  const errorContext = useContext(NotificationContext);

  const [gettingEntertainmentsCategory, setGettingEntertainmentsCategory] =
    useState<boolean>(true);
  const [updaingEntertainmentsCategory, setUpdatingEntertainmentsCategory] =
    useState<boolean>(false);
  const [initialEntertainmentsCategory, setInitialEntertainmentsCategory] =
    useState<IEntertainmentsCategoryModel>();

  useEffect(() => {
    if (!uuidEntertainmentsCategory) {
      return;
    }
    fetchEntertainmentsCategory(uuidEntertainmentsCategory);
  }, [uuidEntertainmentsCategory]);

  async function fetchEntertainmentsCategory(localUuidEntertainment: string) {
    setGettingEntertainmentsCategory(true);
    const response = await new GetEntertainmentsCategoriesHttp().getByUuid({
      uuidEntertainment: localUuidEntertainment,
    });
    if (response.error) {
      await errorContext.showError({
        message: response.errorData.message,
      });
      return setGettingEntertainmentsCategory(false);
    }
    setInitialEntertainmentsCategory(
      response.successData.entertainmentsCategory
    );
    setGettingEntertainmentsCategory(false);
  }

  async function updateEntertainmentsCategory(
    data: IEntertainmentsCategoryFormData
  ) {
    setUpdatingEntertainmentsCategory(true);
    if (!initialEntertainmentsCategory) {
      throw new Error("Initial entertainment not found");
    }
    const response = await new PutEntertainmentsCategoriesHttp().updateByUuid({
      uuid: initialEntertainmentsCategory.uuid,
      name: data.name,
      urlSlug: data.urlSlug,
      description: data.description,
      visibleOnSite: data.visibleOnSite,
    });
    if (response.error) {
      await errorContext.showError({
        message: response.errorData.message,
      });
      return setGettingEntertainmentsCategory(false);
    }

    setUpdatingEntertainmentsCategory(false);
    navigate(-1);
  }

  if (!uuidEntertainmentsCategory) {
    throw new Error("Couldn't find uuidEntertainmentsCategory param");
  }

  if (gettingEntertainmentsCategory) {
    return (
      <div className="flex flex-1 justify-center">
        <LoadingElement />
      </div>
    );
  }

  if (!initialEntertainmentsCategory) {
    return (
      <p className="w-full text-center">Entertainments Category not found</p>
    );
  }

  return (
    <FormEntertainmentsCategoryOrganism
      initialData={{
        name: initialEntertainmentsCategory.name,
        urlSlug: initialEntertainmentsCategory.urlSlug,
        description: initialEntertainmentsCategory.description || "",
        visibleOnSite: initialEntertainmentsCategory.visibleOnSite,
      }}
      formType="edit"
      submitInProgress={updaingEntertainmentsCategory}
      setSubmitInProgress={setUpdatingEntertainmentsCategory}
      onSubmitForm={updateEntertainmentsCategory}
    />
  );
};
