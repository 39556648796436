import Net, { IBody } from "../net.http";
import { IResponse } from "../../utils/response.utils";
import { IPriceRange } from "../../validators/common/price-range.common.validators";
import { IShortUuidListSortable } from "../../validators/common/uuid.common.validations";
import { IRoomsQuantity } from "../../validators/common/rooms-quantity.common.data-validators";

export interface IPutPropertiesHttpUpdateByUuidData {
  readonly uuid: string;
  readonly name: string;
  readonly urlSlug: string;
  readonly summary: string | null;
  readonly description: string | null;
  readonly uuidLocation: string;
  readonly uuidThumbnailPicture: string | null;
  readonly priceRange: IPriceRange;
  readonly roomsQuanity: IRoomsQuantity;
  readonly visibleOnSite: boolean;
  readonly starRatings: number | null;
  readonly uuidListGallery: IShortUuidListSortable;
  readonly uuidListCollection: IShortUuidListSortable;
}

export interface IPutPropertiesHttpUpdateByUuidResponse {}

export interface IPutPropertiesHttpUpdateByUuidBestSellingData {
  readonly uuid: string;
  readonly bestSelling: boolean;
}

export interface IPutPropertiesHttpUpdateByUuidBestSellingResponse {
  success: boolean;
}

export class PutPropertiesHttp {
  async updateByUuid(
    data: IPutPropertiesHttpUpdateByUuidData
  ): Promise<IResponse<IPutPropertiesHttpUpdateByUuidResponse>> {
    const body: IBody = {
      name: data.name,
      urlSlug: data.urlSlug,
      summary: data.summary,
      priceRange: data.priceRange,
      starRatings: data.starRatings,
      uuidLocation: data.uuidLocation,
      roomsQuantity: data.roomsQuanity,
      visibleOnSite: data.visibleOnSite,
      uuidListGallery: data.uuidListGallery,
      uuidListCollection: data.uuidListCollection,
      uuidThumbnailPicture: data.uuidThumbnailPicture,
    };
    if (data.description && data.description.length > 0) {
      body.description = data.description;
    }

    const response =
      await Net.getInstance().put<IPutPropertiesHttpUpdateByUuidResponse>({
        path: `/properties/uuid/${data.uuid}`,
        body,
      });
    return response;
  }

  async updateByUuidBestSelling(
    data: IPutPropertiesHttpUpdateByUuidBestSellingData
  ): Promise<IResponse<IPutPropertiesHttpUpdateByUuidBestSellingResponse>> {
    const response =
      await Net.getInstance().put<IPutPropertiesHttpUpdateByUuidBestSellingResponse>(
        {
          path: `/properties/uuid/${data.uuid}/best-selling`,
          body: {
            bestSelling: data.bestSelling,
          },
        }
      );

    return response;
  }
}
