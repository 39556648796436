import React, { useRef, useState } from "react";
import { FunctionComponent } from "react";
import { Formik, Field, Form } from "formik";
import { ActivitiesFormSchema } from "../../validators/activities-form.validation";
import {
  DropzoneMultiplePictureElement,
  IDropzoneMultiplePictureElementRef,
} from "../../elements/dropzone-multiple-picture.elements";
import LoadingElement from "../../elements/loading.elements";
import { IPictureListSortable } from "../../validators/common/picture-list-sortable.common.validators";
import { IPriceRange } from "../../validators/common/price-range.common.validators";
import { Switch } from "@headlessui/react";
import { IDuration } from "../../validators/common/duration.common.validators";
import { IGroupSize } from "../../validators/common/group-size.common.validators";
import { IWhereToPlay } from "../../validators/common/where-to-play.common.validators";
import { DurationTypes } from "../../constants/duration-types.constants";
import { DurationTypeDropdownMolecule } from "../../molecules/duration-type-dropdown.molecules";

export interface IActivitiesFormData {
  name: string;
  urlSlug: string;
  description: string;
  visibleOnSite: boolean;
  priceRange: IPriceRange;
  duration: IDuration;
  groupSize: IGroupSize;
  whereToPlay: IWhereToPlay;
  gallery: IPictureListSortable;
  heroPictures: IPictureListSortable;
}

interface IActivitiesFormDataInternal extends IActivitiesFormData {
  priceRangeMin: string;
  priceRangeMax: string;
  groupSizeMin: string;
  groupSizeMax: string;
  durationMin: string;
  durationMax: string;
  durationType: DurationTypes;
  whereToPlayIndoor: boolean;
  whereToPlayOutdoor: boolean;
}

export interface IActivityFormActivitiesOrganismProps {
  initialData: IActivitiesFormData;
  submitInProgress: boolean;
  setSubmitInProgress: (state: boolean) => void;
  formType: "add" | "edit";
  onSubmitForm: (data: IActivitiesFormData) => Promise<void>;
}

export const ActivityFormActivitiesOrganism: FunctionComponent<
  IActivityFormActivitiesOrganismProps
> = ({
  submitInProgress,
  setSubmitInProgress,
  formType,
  initialData,
  onSubmitForm,
}) => {
  const [visibleOnSite, setVisibleOnSite] = useState(initialData.visibleOnSite);
  const [durationType, setDurationType] = useState<DurationTypes | null>(
    initialData.duration?.type || null
  );
  const galleryRef = useRef<IDropzoneMultiplePictureElementRef>(null);
  const heroPicturesRef = useRef<IDropzoneMultiplePictureElementRef>(null);

  return (
    <Formik<IActivitiesFormDataInternal>
      initialValues={{
        name: initialData.name,
        urlSlug: initialData.urlSlug,
        description: initialData.description,
        visibleOnSite: initialData.visibleOnSite,
        priceRangeMin: initialData.priceRange?.min
          ? `${initialData.priceRange?.min}`
          : "",
        priceRangeMax: initialData.priceRange?.max
          ? `${initialData.priceRange?.max}`
          : "",
        durationMin: initialData.duration?.min
          ? `${initialData.duration?.min}`
          : "",
        durationMax: initialData.duration?.max
          ? `${initialData.duration?.max}`
          : "",
        groupSizeMin: initialData.groupSize?.min
          ? `${initialData.groupSize?.min}`
          : "",
        groupSizeMax: initialData.groupSize?.max
          ? `${initialData.groupSize?.max}`
          : "",
        whereToPlayIndoor: initialData.whereToPlay?.indoor || false,
        whereToPlayOutdoor: initialData.whereToPlay?.outdoor || false,
        /**
         * @deprecated durationType is managed seperately
         */
        durationType: DurationTypes.MINUTES,
        /**
         * @deprecated priceRange is managed seperately
         */
        priceRange: null,
        /**
         * @deprecated duration is managed seperately
         */
        duration: null,
        /**
         * @deprecated whereToPlay is managed seperately
         */
        whereToPlay: null,
        /**
         * @deprecated groupSize is managed seperately
         */
        groupSize: null,
        /**
         * @deprecated gallery is managed seperately
         */
        gallery: [],
        /**
         * @deprecated heroPictures is managed seperately
         */
        heroPictures: [],
      }}
      validationSchema={ActivitiesFormSchema}
      onSubmit={async (formData) => {
        if (!galleryRef.current) {
          throw new Error("galleryRef is null");
        }
        if (!heroPicturesRef.current) {
          throw new Error("heroPicturesRef is null");
        }
        setSubmitInProgress(true);

        const heroPictures = await heroPicturesRef.current.getFinalPictures();
        if (heroPictures.error) {
          return setSubmitInProgress(false);
        }
        const galleryPictures = await galleryRef.current.getFinalPictures();
        if (galleryPictures.error) {
          return setSubmitInProgress(false);
        }

        setSubmitInProgress(true);
        await onSubmitForm({
          ...formData,
          visibleOnSite,
          priceRange: formData.priceRangeMin
            ? {
                min: formData.priceRangeMin as unknown as number,
                max: formData.priceRangeMax as unknown as number,
              }
            : null,
          duration:
            formData.durationMin && durationType
              ? {
                  type: durationType,
                  min: formData.durationMin as unknown as number,
                  max: formData.durationMax as unknown as number,
                }
              : null,
          groupSize: formData.groupSizeMin
            ? {
                min: formData.groupSizeMin as unknown as number,
                max: formData.groupSizeMax as unknown as number,
              }
            : null,
          whereToPlay: {
            indoor: formData.whereToPlayIndoor,
            outdoor: formData.whereToPlayOutdoor,
          },
          gallery: galleryPictures.data.pictures,
          heroPictures: heroPictures.data.pictures,
        });
      }}
    >
      {({ errors, touched }) => {
        return (
          <Form className="flex flex-1 flex-col">
            <label className="mt-4">Hero Pictures</label>
            <div className="flex flex-1 border border-neutral-200 rounded-lg px-4 py-4 mt-1 flex-col items-center">
              <DropzoneMultiplePictureElement
                ref={heroPicturesRef}
                pictures={initialData.heroPictures}
              />
            </div>

            <label className="mt-4">
              URL Slug{" "}
              {errors.urlSlug && touched.urlSlug ? (
                <span className="mt-2 text-red-600 text-base font-normal">
                  ({errors.urlSlug})
                </span>
              ) : null}
            </label>
            <Field
              name="urlSlug"
              type="text"
              placeholder="URL Slug"
              className="flex border border-neutral-200 rounded-lg px-4 py-2 mt-1"
            />

            <label className="mt-4">
              Name{" "}
              {errors.name && touched.name ? (
                <span className="mt-2 text-red-600 text-base font-normal">
                  ({errors.name})
                </span>
              ) : null}
            </label>
            <Field
              name="name"
              type="text"
              placeholder="Name"
              className="flex border rounded-lg px-4 py-2 mt-1"
            />

            <label className="mt-4">
              Description{" "}
              {errors.description && touched.description ? (
                <span className="mt-2 text-red-600 text-base font-normal">
                  ({errors.description})
                </span>
              ) : null}
            </label>
            <Field
              name="description"
              as="textarea"
              placeholder="Description"
              className="flex border rounded-lg px-4 py-2 mt-1"
            />

            <div className="flex flex-1 mt-4 grid grid-cols-2 gap-6">
              <div className="flex flex-1 flex-col">
                <label className="">
                  Minimum Price{" "}
                  {errors.priceRangeMin && touched.priceRangeMin ? (
                    <span className="mt-2 text-red-600 text-base font-normal">
                      ({errors.priceRangeMin})
                    </span>
                  ) : null}
                </label>
                <Field
                  name="priceRangeMin"
                  type="text"
                  placeholder="Minimum Price"
                  className="flex border rounded-lg px-4 py-2 mt-1"
                />
              </div>

              <div className="flex flex-1 flex-col">
                <label className="">
                  Maximum Price{" "}
                  {errors.priceRangeMax && touched.priceRangeMax ? (
                    <span className="mt-2 text-red-600 text-base font-normal">
                      ({errors.priceRangeMax})
                    </span>
                  ) : null}
                </label>
                <Field
                  name="priceRangeMax"
                  type="text"
                  placeholder="Maximum Price"
                  className="flex border rounded-lg px-4 py-2 mt-1"
                />
              </div>
            </div>

            <div className="flex flex-1 mt-4 grid grid-cols-2 gap-6">
              <div className="flex flex-1 flex-col">
                <label className="">
                  Minimum Group Size{" "}
                  {errors.groupSizeMin && touched.groupSizeMin ? (
                    <span className="mt-2 text-red-600 text-base font-normal">
                      ({errors.groupSizeMin})
                    </span>
                  ) : null}
                </label>
                <Field
                  name="groupSizeMin"
                  type="text"
                  placeholder="Minimum Group Size"
                  className="flex border rounded-lg px-4 py-2 mt-1"
                />
              </div>

              <div className="flex flex-1 flex-col">
                <label className="">
                  Maximum Group Size{" "}
                  {errors.groupSizeMax && touched.groupSizeMax ? (
                    <span className="mt-2 text-red-600 text-base font-normal">
                      ({errors.groupSizeMax})
                    </span>
                  ) : null}
                </label>
                <Field
                  name="groupSizeMax"
                  type="text"
                  placeholder="Maximum Group Size"
                  className="flex border rounded-lg px-4 py-2 mt-1"
                />
              </div>
            </div>

            <div className="flex flex-1 mt-4 grid grid-cols-3 gap-6">
              <div className="flex flex-1 flex-col">
                <label className="">
                  Duration Type{" "}
                  {errors.durationMin && touched.durationMin ? (
                    <span className="mt-2 text-red-600 text-base font-normal">
                      ({errors.durationMin})
                    </span>
                  ) : null}
                </label>
                <DurationTypeDropdownMolecule
                  selected={durationType}
                  onChange={(selected) => setDurationType(selected)}
                />
              </div>

              <div className="flex flex-1 flex-col">
                <label className="">
                  Duration Minimum{" "}
                  {errors.durationMin && touched.durationMin ? (
                    <span className="mt-2 text-red-600 text-base font-normal">
                      ({errors.durationMin})
                    </span>
                  ) : null}
                </label>
                <Field
                  name="durationMin"
                  type="text"
                  placeholder="Duration Minimum"
                  className="flex border rounded-lg px-4 py-2 mt-1"
                />
              </div>

              <div className="flex flex-1 flex-col">
                <label className="">
                  Duration Maximum{" "}
                  {errors.durationMax && touched.durationMax ? (
                    <span className="mt-2 text-red-600 text-base font-normal">
                      ({errors.durationMax})
                    </span>
                  ) : null}
                </label>
                <Field
                  name="durationMax"
                  type="text"
                  placeholder="Duration Maximum"
                  className="flex border rounded-lg px-4 py-2 mt-1"
                />
              </div>
            </div>

            <label className="mt-4">
              Where To Play{" "}
              {errors.name && touched.name ? (
                <span className="mt-2 text-red-600 text-base font-normal">
                  ({errors.name})
                </span>
              ) : null}
            </label>
            <div className="flex flex-col border rounded-lg px-4 py-2 mt-1">
              <div className="flex items-center mb-2">
                <Field
                  id="whereToPlayIndoor"
                  name="whereToPlayIndoor"
                  type="checkbox"
                  placeholder="Indoor Venues"
                  className="w-4 h-4"
                />
                <label htmlFor="whereToPlayIndoor" className="pl-2">
                  Indoor Venues
                </label>
              </div>
              <div className="flex items-center">
                <Field
                  id="whereToPlayOutdoor"
                  name="whereToPlayOutdoor"
                  type="checkbox"
                  placeholder="Outdoor Venues"
                  className="w-4 h-4"
                />
                <label htmlFor="whereToPlayOutdoor" className="pl-2">
                  Outdoor Venues
                </label>
              </div>
            </div>

            <label className="mt-4">Gallery</label>
            <div className="flex flex-1 border border-neutral-200 rounded-lg px-4 py-4 mt-1 flex-col items-center">
              <DropzoneMultiplePictureElement
                ref={galleryRef}
                pictures={initialData.gallery}
              />
            </div>

            <label className="mt-4">Visible on site</label>
            <div className="flex flex-1 border border-neutral-200 rounded-lg px-4 py-4 mt-1 flex-row items-center">
              <Switch
                checked={visibleOnSite}
                onChange={setVisibleOnSite}
                className={`${visibleOnSite ? "bg-sky-300" : "bg-gray-200"}
          relative inline-flex h-[38px] w-[74px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
              >
                <span
                  aria-hidden="true"
                  className={`${
                    visibleOnSite ? "translate-x-9" : "translate-x-0"
                  }
            pointer-events-none inline-block h-[34px] w-[34px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                />
              </Switch>
              <p className="pl-4">
                Item will be shown on website only when the toggle is on
              </p>
            </div>

            <button
              type="submit"
              className="border rounded-lg px-4 py-2 mt-6 bg-sky-200 border-sky-200 font-semibold text-center cursor-pointer"
            >
              {submitInProgress ? (
                <LoadingElement />
              ) : (
                <>{formType === "add" ? "Add Activity" : "Save Changes"}</>
              )}
            </button>
          </Form>
        );
      }}
    </Formik>
  );
};
