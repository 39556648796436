import Net from "../net.http";
import { IResponse } from "../../utils/response.utils";

export interface IDeleteEntertainmentsHttpDeleteByUuidData {
  readonly uuid: string;
}

export interface IDeleteEntertainmentsHttpDeleteByUuidResponse {}

export class DeleteEntertainmentsHttp {
  async deleteByUuid(
    data: IDeleteEntertainmentsHttpDeleteByUuidData
  ): Promise<IResponse<IDeleteEntertainmentsHttpDeleteByUuidResponse>> {
    const response =
      await Net.getInstance().delete<IDeleteEntertainmentsHttpDeleteByUuidResponse>(
        {
          path: `/entertainments/uuid/${data.uuid}`,
        }
      );
    return response;
  }
}
