import React, { FunctionComponent, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import LoadingElement from "../../elements/loading.elements";

import { ReactComponent as EditSvg } from "../../assets/svgs/edit.svg";
import { DeleteModalButtonElement } from "../../elements/delete-modal-button.elements";
import { NotificationContext } from "../../contexts/notification.contexts";
import { IEntertainmentsModel } from "../../interfaces/models/entertainments.models.interfaces";
import { DeleteEntertainmentsHttp } from "../../http/entertainments/delete.entertainments.http";

export interface IEntertainmentsListOrganismProps {
  loading: boolean;
  entertainments?: Array<IEntertainmentsModel>;
  setEntertainments: (entertainments: Array<IEntertainmentsModel>) => void;
}

export const EntertainmentsListOrganism: FunctionComponent<
  IEntertainmentsListOrganismProps
> = ({
  loading,
  entertainments: entertainments,
  setEntertainments: setEntertainments,
}) => {
  const notificationContext = useContext(NotificationContext);
  const { pathname } = useLocation();

  if (!entertainments || loading) {
    return (
      <div className="flex flex-1 justify-center">
        <LoadingElement />
      </div>
    );
  }

  async function deleteEntertainment(
    uuidEntertainmentToDelete: string
  ): Promise<{ success: boolean }> {
    if (!entertainments) throw new Error("entertainments cannot be undefined");
    const response = await new DeleteEntertainmentsHttp().deleteByUuid({
      uuid: uuidEntertainmentToDelete,
    });
    if (response.error) {
      await notificationContext.showError({
        message: response.errorData.message,
      });
      return {
        success: false,
      };
    }
    setEntertainments(
      entertainments.filter((loc) => loc.uuid !== uuidEntertainmentToDelete)
    );
    return {
      success: true,
    };
  }

  if (entertainments.length === 0) {
    return <p className="w-full text-center">No Entertainments found</p>;
  }

  return (
    <ul className="flex flex-1 flex-col">
      {entertainments.map((entertainment) => {
        return (
          <li
            key={entertainment.uuid}
            className="flex flex-1 flex-col border rounded-xl p-4 mb-4 grid grid-cols-5 gap-6"
          >
            <div className="aspect-video overflow-hidden bg-neutral-200 col-span-2 rounded-md">
              {entertainment.heroPictures[0] && (
                <img src={entertainment.heroPictures[0].url} alt="" />
              )}
            </div>
            <div className="flex flex-1 flex-row col-span-3">
              <div className="flex flex-1 flex-col">
                <h2 className="text-2xl font-semibold mb-4">
                  {entertainment.name}
                </h2>
                <p>{entertainment.description}</p>
              </div>
              <ul className="flex flex-col pl-4">
                <Link to={`${pathname}/${entertainment.uuid}/edit`}>
                  <EditSvg className="w-8 h-8 fill-neutral-600 hover:fill-blue-600" />
                </Link>
                <div className="mt-6">
                  <DeleteModalButtonElement
                    uuid={entertainment.uuid}
                    itemToDelete="Entertainment"
                    onClickDelete={deleteEntertainment}
                  />
                </div>
              </ul>
            </div>
          </li>
        );
      })}
    </ul>
  );
};
