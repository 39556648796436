import { IEntertainmentsCategoryModel } from "../../interfaces/models/entertainments-categories.models.interfaces";
import { IResponse } from "../../utils/response.utils";
import Net, { IQuery } from "../net.http";

export interface IGetEntertainmentsCategoriesHttpData {
  readonly limit?: number;
  readonly offset?: number;
}

export interface IGetEntertainmentsCategoriesHttpResult {
  readonly entertainmentsCategories: Array<IEntertainmentsCategoryModel>;
}

export interface IGetEntertainmentsCategoriesHttpGetByUuidData {
  readonly uuidEntertainment: string;
}

export interface IGetEntertainmentsCategoriesHttpGetByUuidResult {
  readonly entertainmentsCategory: IEntertainmentsCategoryModel;
}

export class GetEntertainmentsCategoriesHttp {
  async getAll(
    data: IGetEntertainmentsCategoriesHttpData
  ): Promise<IResponse<IGetEntertainmentsCategoriesHttpResult>> {
    const query: IQuery = {};
    if (data.limit) query.limit = data.limit;
    if (data.offset) query.offset = data.offset;
    const response =
      await Net.getInstance().get<IGetEntertainmentsCategoriesHttpResult>({
        path: "/entertainments-categories",
        query,
      });
    return response;
  }

  async getByUuid(
    data: IGetEntertainmentsCategoriesHttpGetByUuidData
  ): Promise<IResponse<IGetEntertainmentsCategoriesHttpGetByUuidResult>> {
    const response =
      await Net.getInstance().get<IGetEntertainmentsCategoriesHttpGetByUuidResult>(
        {
          path: `/entertainments-categories/uuid/${data.uuidEntertainment}`,
        }
      );
    return response;
  }
}
