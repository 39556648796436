import { IResponse } from "../utils/response.utils";

export enum METHODS {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  DELETE = "DELETE",
}

export interface IBody {
  [key: string]: any;
}

export interface IHeaders {
  [key: string]: any;
}

export interface IQuery extends IBody {}

export interface IGetParams {
  path: string;
  query?: IQuery;
  headers?: IHeaders;
}

export interface IPostParams extends IGetParams {
  body?: IBody | FormData;
  multipart?: boolean;
}

export interface IPatchParams extends IPostParams {}

export interface IDeleteParams extends IPostParams {}

export default class Net {
  private static instance: Net;
  private baseUrl?: string;
  private bearerToken?: string;

  private constructor() {}

  public static getInstance(): Net {
    if (!this.instance) {
      this.instance = new Net();
      this.instance.baseUrl = process.env.REACT_APP_API_URL;
    }
    return this.instance;
  }

  login(bearerToken: string) {
    this.bearerToken = bearerToken;
  }

  logout() {
    this.bearerToken = undefined;
  }

  async get<T>({
    path,
    query,
    headers = {},
  }: IGetParams): Promise<IResponse<T>> {
    const finalUrl = this.buildUrl(path, query);
    const finalHeaders = this.finalHeaders(headers, METHODS.GET);

    try {
      const response = await fetch(finalUrl, {
        method: "get",
        headers: finalHeaders,
      });
      return await response.json();
    } catch (error) {
      throw error;
    }
  }

  async post<T>({
    path,
    query,
    headers = {},
    multipart = false,
    body,
  }: IPostParams): Promise<IResponse<T>> {
    const finalUrl = this.buildUrl(path, query);
    const finalHeaders = this.finalHeaders(headers, METHODS.POST, multipart);

    try {
      const response = await fetch(finalUrl, {
        method: "post",
        headers: finalHeaders,
        body: multipart ? (body as FormData) : JSON.stringify(body),
      });
      return await response.json();
    } catch (error) {
      throw error;
    }
  }

  async put<T>({
    path,
    query,
    headers = {},
    multipart = false,
    body,
  }: IPostParams): Promise<IResponse<T>> {
    const finalUrl = this.buildUrl(path, query);
    const finalHeaders = this.finalHeaders(headers, METHODS.PUT, multipart);

    try {
      const response = await fetch(finalUrl, {
        method: "put",
        headers: finalHeaders,
        body: multipart ? (body as FormData) : JSON.stringify(body),
      });
      return await response.json();
    } catch (error) {
      throw error;
    }
  }

  async delete<T>({
    path,
    query,
    headers = {},
    multipart = false,
    body,
  }: IPostParams): Promise<IResponse<T>> {
    const finalUrl = this.buildUrl(path, query);
    const finalHeaders = this.finalHeaders(headers, METHODS.PUT, multipart);

    try {
      const response = await fetch(finalUrl, {
        method: "delete",
        headers: finalHeaders,
        body: multipart ? (body as FormData) : JSON.stringify(body),
      });
      return await response.json();
    } catch (error) {
      throw error;
    }
  }

  private buildUrl(path: string, query?: IQuery) {
    const queryString = this.generateQueryString(query);
    return `${this.baseUrl}${path}${queryString}`;
  }

  private finalHeaders(
    headers: IHeaders,
    method: METHODS,
    multipart: boolean = false
  ) {
    if (this.bearerToken)
      headers["Authorization"] = `Bearer ${this.bearerToken}`;

    switch (method) {
      case METHODS.GET:
        break;
      case METHODS.POST:
      case METHODS.PUT:
        if (!multipart) headers["Content-Type"] = "application/json";
        break;
      case METHODS.DELETE:
        break;
      default:
        throw new Error("Unhandled method");
    }

    return headers;
  }

  private generateQueryString(query?: IQuery) {
    if (!query) {
      return "";
    }
    const queryKeys = Object.keys(query);
    return queryKeys.length > 0
      ? `?${Object.keys(query)
          .map((key) => `${key}=${query[key]}`)
          .join("&")}`
      : "";
  }
}
