import React, { useRef, useState } from "react";
import { FunctionComponent } from "react";
import { Formik, Field, Form } from "formik";
import {
  DropzoneSinglePictureElement,
  IDropzoneSinglePictureElementRef,
} from "../../elements/dropzone-single-picture.elements";
import { IAssetCommon } from "../../interfaces/common/asset.common.interfaces";
import LoadingElement from "../../elements/loading.elements";
import { CollectionFormSchema } from "../../validators/collection-form.validation";
import { Switch } from "@headlessui/react";

export interface ICollectionsFormData {
  name: string;
  urlSlug: string;
  description: string;
  visibleOnSite: boolean;
  thumbnailPicture: IAssetCommon | null;
}

export interface ICollectionsFormCollectionsOrganismProps {
  initialData: ICollectionsFormData;
  formType: "add" | "edit";
  submitInProgress: boolean;
  setSubmitInProgress: (state: boolean) => void;
  onSubmitForm: (data: ICollectionsFormData) => Promise<void>;
}

export const CollectionsFormCollectionsOrganism: FunctionComponent<
  ICollectionsFormCollectionsOrganismProps
> = ({
  formType,
  submitInProgress,
  setSubmitInProgress,
  initialData,
  onSubmitForm,
}) => {
  const [visibleOnSite, setVisibleOnSite] = useState(initialData.visibleOnSite);
  const thumbnailImageRef = useRef<IDropzoneSinglePictureElementRef>(null);

  return (
    <Formik<ICollectionsFormData>
      initialValues={{
        name: initialData.name,
        urlSlug: initialData.urlSlug,
        description: initialData.description,
        visibleOnSite: initialData.visibleOnSite,
        thumbnailPicture: initialData.thumbnailPicture,
      }}
      validationSchema={CollectionFormSchema}
      validateOnMount={true}
      onSubmit={async (values) => {
        if (!thumbnailImageRef.current) {
          throw new Error("thumbnailImageRef is null");
        }

        setSubmitInProgress(true);

        const thumbnailPicture =
          await thumbnailImageRef.current.getFinalPicture();
        if (thumbnailPicture.error) {
          return setSubmitInProgress(false);
        }

        await onSubmitForm({
          ...values,
          visibleOnSite,
          thumbnailPicture: thumbnailPicture.data.picture,
        });
      }}
    >
      {({ errors, touched }) => (
        <Form className="flex flex-1 flex-col">
          <label className="mt-4">Thumbnail Picture</label>
          <div className="flex flex-1 border border-neutral-200 rounded-lg px-4 py-4 mt-1 flex-col items-center">
            <DropzoneSinglePictureElement
              ref={thumbnailImageRef}
              picture={initialData.thumbnailPicture}
            />
          </div>

          <label className="mt-4">
            URL Slug{" "}
            {errors.urlSlug && touched.urlSlug ? (
              <span className="mt-2 text-red-600 text-base font-normal">
                ({errors.urlSlug})
              </span>
            ) : null}
          </label>
          <Field
            name="urlSlug"
            type="text"
            placeholder="URL Slug"
            className="flex border border-neutral-200 rounded-lg px-4 py-2 mt-1"
          />

          <label className="mt-4">
            Name{" "}
            {errors.name && touched.name ? (
              <span className="mt-2 text-red-600 text-base font-normal">
                ({errors.name})
              </span>
            ) : null}
          </label>
          <Field
            name="name"
            type="text"
            placeholder="Name"
            className="flex border border-neutral-200 rounded-lg px-4 py-2 mt-1"
          />

          <label className="mt-4">
            Description{" "}
            {errors.description && touched.description ? (
              <span className="mt-2 text-red-600 text-base font-normal">
                ({errors.description})
              </span>
            ) : null}
          </label>
          <Field
            name="description"
            as="textarea"
            placeholder="Description"
            className="flex border border-neutral-200 rounded-lg px-4 py-2 mt-1"
          />

          <label className="mt-4">Visible on site</label>
          <div className="flex flex-1 border border-neutral-200 rounded-lg px-4 py-4 mt-1 flex-row items-center">
            <Switch
              checked={visibleOnSite}
              onChange={setVisibleOnSite}
              className={`${visibleOnSite ? "bg-sky-300" : "bg-gray-200"}
          relative inline-flex h-[38px] w-[74px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
            >
              <span
                aria-hidden="true"
                className={`${visibleOnSite ? "translate-x-9" : "translate-x-0"}
            pointer-events-none inline-block h-[34px] w-[34px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
              />
            </Switch>
            <p className="pl-4">
              Item will be shown on website only when the toggle is on
            </p>
          </div>

          <button
            type="submit"
            className="border border-neutral-200 rounded-lg px-4 py-2 mt-6 bg-sky-200 border-sky-200 font-semibold text-center cursor-pointer"
          >
            {submitInProgress ? (
              <LoadingElement />
            ) : (
              <>{formType === "add" ? "Add Collection" : "Save Changes"}</>
            )}
          </button>
        </Form>
      )}
    </Formik>
  );
};
