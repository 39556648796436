import React from "react";
import { FunctionComponent } from "react";
import { Helmet } from "react-helmet";
import { useSearchParams } from "react-router-dom";
import {
  IInvoiceData,
  TemplateInvoiceOrganism,
} from "../organisms/invoice/template.invoice.organisms";
import { ReactComponent as InvoiceFooterSvg } from "../assets/svgs/invoice-footer-bg.svg";

export const InvoicePrintViewPage: FunctionComponent = () => {
  const [searchParams] = useSearchParams();

  const isIgst = searchParams.get("isIgst") === "true";
  const invoiceData = searchParams.get("invoiceData");

  if (!invoiceData) {
    return <p>Invoice Data Not found</p>;
  }

  const invoiceDataJson = JSON.parse(invoiceData) as IInvoiceData;

  return (
    <>
      <Helmet
        style={[
          {
            cssText: `
            #root {
              position: relative;
            }`,
          },
        ]}
      ></Helmet>
      <div className="flex justify-center">
        <div className="max-w-[794px] min-w-[794px] min-h-screen">
          <TemplateInvoiceOrganism
            invoiceData={invoiceDataJson}
            isIgst={isIgst}
            isPreview={true}
          />
        </div>
      </div>
    </>
  );
};
