import React from "react";
import { FunctionComponent } from "react";
import { ContentContainer } from "../../elements/content-container.elements";
import { HeaderElement } from "../../elements/header.elements";
import { EditVloggerFormVloggersOrganism } from "../../organisms/vloggers/edit-vlogger-form.vloggers.organisms";

export const EditVloggersPage: FunctionComponent = () => {
  return (
    <>
      <HeaderElement title="Edit Vlogger" />
      <ContentContainer>
        <EditVloggerFormVloggersOrganism />
      </ContentContainer>
    </>
  );
};
