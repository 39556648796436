import * as yup from "yup";

export enum RoomsQuantityTypes {
  EQUAL_TO = 1,
  MORE_THAN = 2,
}

export const RoomsQuantityTypesString: Record<RoomsQuantityTypes, string> = {
  [RoomsQuantityTypes.EQUAL_TO]: "Equal to",
  [RoomsQuantityTypes.MORE_THAN]: "More than",
};

export const RoomsQuantityTypesValues = Object.values(
  RoomsQuantityTypes
).filter((x) => typeof x === "number") as Array<RoomsQuantityTypes>;

const RoomsQuantityTypesCommonDataValidator = yup
  .mixed<RoomsQuantityTypes>()
  .oneOf(RoomsQuantityTypesValues as Array<number>)
  .strict()
  .required();

export const RoomsQuantityCommonDataValidator = yup
  .object({
    quantity: yup.number().required().min(1),
    type: RoomsQuantityTypesCommonDataValidator,
  })
  .nullable()
  .default(null);

export type IRoomsQuantity = yup.InferType<
  typeof RoomsQuantityCommonDataValidator
>;
