import React from "react";
import { FunctionComponent } from "react";
import { Link } from "react-router-dom";

export interface IHeaderElementsProps {
  title: string;
  action?: {
    title: string;
    href: string;
  };
}

export const HeaderElement: FunctionComponent<IHeaderElementsProps> = ({
  title,
  action,
}) => {
  return (
    <div className="h-16 border-b border-b-neutral-200">
      <div className="flex flex-1 flex-row items-center container mx-auto max-w-screen-lg h-full px-2">
        <p className="text-2xl font-medium">{title}</p>
        {action && (
          <div className="flex flex-1 flex-row justify-end">
            <Link to={action.href}>
              <p className="px-4 py-2 bg-sky-200 rounded-lg font-semibold uppercase">
                {action.title}
              </p>
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};
