import { Listbox } from "@headlessui/react";
import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from "react";
import { NotificationContext } from "../contexts/notification.contexts";
import LoadingElement from "../elements/loading.elements";
import { GetEntertainmentsCategoriesHttp } from "../http/entertainments-categories/get.entertainments-categories.http";
import { IEntertainmentsCategoryModel } from "../interfaces/models/entertainments-categories.models.interfaces";

export interface IEntertainmentsCategoryDropdownMoleculeProps {
  selected: IEntertainmentsCategoryModel | null;
  onChange: (category: IEntertainmentsCategoryModel) => void;
}

const ListItem: FunctionComponent<{
  category?: IEntertainmentsCategoryModel;
}> = ({ category }) => {
  return (
    <div className="flex flex-1 items-center h-8">
      {category !== undefined ? (
        <>
          <p>{category.name}</p>
        </>
      ) : (
        <p className="font-semibold text-center w-full">Select Category</p>
      )}
    </div>
  );
};

const EntertainmentsCategoryList: FunctionComponent = () => {
  const notificationContext = useContext(NotificationContext);
  const [gettingCategories, setGettingCategories] = useState<boolean>(true);
  const [categories, setCategories] = useState<
    Array<IEntertainmentsCategoryModel>
  >([]);

  useEffect(() => {
    getEntertainmentsCategories();
  }, []);

  async function getEntertainmentsCategories() {
    setGettingCategories(true);
    const response = await new GetEntertainmentsCategoriesHttp().getAll({});
    if (response.error) {
      await notificationContext.showError({
        message: response.errorData.message,
      });
      return setGettingCategories(false);
    }
    setCategories(response.successData.entertainmentsCategories);
    setGettingCategories(false);
  }

  if (gettingCategories) {
    return (
      <div className="flex flex-1 justify-center py-2">
        <LoadingElement />
      </div>
    );
  }

  return (
    <>
      {categories.map((category) => (
        <Listbox.Option
          key={category.uuid}
          value={category}
          className="flex flex-1 px-4 py-2 cursor-pointer"
        >
          <ListItem category={category} />
        </Listbox.Option>
      ))}
    </>
  );
};

export const EntertainmentsCategoryDropdownMolecule: FunctionComponent<
  IEntertainmentsCategoryDropdownMoleculeProps
> = ({ selected, onChange }) => {
  return (
    <div className="relative flex flex-1 mt-1">
      <Listbox value={selected} onChange={onChange}>
        <Listbox.Button className={"flex flex-1 border rounded-md p-2"}>
          {<ListItem category={selected || undefined} />}
        </Listbox.Button>
        <Listbox.Options className="flex flex-1 flex-col bg-white border rounded-md mt-2 absolute bottom-14 left-0 right-0 max-h-[60vh] overflow-auto">
          <EntertainmentsCategoryList />
        </Listbox.Options>
      </Listbox>
    </div>
  );
};
