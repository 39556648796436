import React, { useContext, useEffect, useState } from "react";
import { FunctionComponent } from "react";
import { useLocation } from "react-router-dom";
import { NotificationContext } from "../../contexts/notification.contexts";
import { ContentContainer } from "../../elements/content-container.elements";
import { HeaderElement } from "../../elements/header.elements";
import { GetEntertainmentsCategoriesHttp } from "../../http/entertainments-categories/get.entertainments-categories.http";
import { IEntertainmentsCategoryModel } from "../../interfaces/models/entertainments-categories.models.interfaces";
import { EntertainmentsCategoriesListOrganism } from "../../organisms/entertainments-categories/list.entertainments-categories.organisms";

export const IndexEntertainmentsCategoriesPage: FunctionComponent = () => {
  const { pathname } = useLocation();
  const errorContext = useContext(NotificationContext);

  const [
    fetchingEntertainmentsCategories,
    setFetchingEntertainmentsCategories,
  ] = useState<boolean>(false);
  const [entertainmentsCategories, setEntertainmentsCategories] =
    useState<Array<IEntertainmentsCategoryModel>>();

  useEffect(() => {
    fetchEntertainmentsCategories();
  }, []);

  async function fetchEntertainmentsCategories() {
    setFetchingEntertainmentsCategories(true);
    const response = await new GetEntertainmentsCategoriesHttp().getAll({
      offset: entertainmentsCategories ? entertainmentsCategories.length : 0,
    });
    if (response.error) {
      await errorContext.showError({
        message: response.errorData.message,
      });
      return setFetchingEntertainmentsCategories(false);
    }
    const tempEntertainmentsCategories = (
      entertainmentsCategories || []
    ).concat(response.successData.entertainmentsCategories);
    setEntertainmentsCategories(tempEntertainmentsCategories);
    setFetchingEntertainmentsCategories(false);
  }

  return (
    <>
      <HeaderElement
        title="Entertainment Categories"
        action={{
          title: "Add Category",
          href: `${pathname}/add`,
        }}
      />
      <ContentContainer>
        <EntertainmentsCategoriesListOrganism
          loading={fetchingEntertainmentsCategories}
          entertainmentsCategories={entertainmentsCategories}
          setEntertainmentsCategories={setEntertainmentsCategories}
        />
      </ContentContainer>
    </>
  );
};
