import Net, { IBody } from "../net.http";
import { IResponse } from "../../utils/response.utils";
import { IShortUuidListSortable } from "../../validators/common/uuid.common.validations";
import { IPriceRange } from "../../validators/common/price-range.common.validators";

export interface IPutVloggersHttpUpdateByUuidData {
  readonly uuid: string;
  readonly name: string;
  readonly urlSlug: string;
  readonly description: string;
  readonly priceRange: IPriceRange;
  readonly visibleOnSite: boolean;
  readonly uuidProfilePicture: string | null;
  readonly uuidListGallery: IShortUuidListSortable;
}

export interface IPutVloggersHttpUpdateByUuidResponse {}

export class PutVloggersHttp {
  async updateByUuid(
    data: IPutVloggersHttpUpdateByUuidData
  ): Promise<IResponse<IPutVloggersHttpUpdateByUuidResponse>> {
    const body: IBody = {
      name: data.name,
      urlSlug: data.urlSlug,
      priceRange: data.priceRange,
      uuidListGallery: data.uuidListGallery,
      uuidProfilePicture: data.uuidProfilePicture,
      visibleOnSite: data.visibleOnSite,
    };
    if (data.description && data.description.length > 0) {
      body.description = data.description;
    }
    const response =
      await Net.getInstance().put<IPutVloggersHttpUpdateByUuidResponse>({
        path: `/vloggers/uuid/${data.uuid}`,
        body,
      });
    return response;
  }
}
