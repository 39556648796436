import { IVloggersModel } from "../../interfaces/models/vloggers.models.interfaces";
import { IResponse } from "../../utils/response.utils";
import Net, { IQuery } from "../net.http";

export interface IGetVloggersHttpData {
  limit?: number;
  offset?: number;
}

export interface IGetVloggersHttpResult {
  vloggers: Array<IVloggersModel>;
}

export interface IGetVloggersHttpGetByUuidData {
  uuidVlogger: string;
}

export interface IGetVloggersHttpGetByUuidResult {
  vlogger: IVloggersModel;
}

export class GetVloggersHttp {
  async getAll(
    data: IGetVloggersHttpData
  ): Promise<IResponse<IGetVloggersHttpResult>> {
    const query: IQuery = {};
    if (data.limit) query.limit = data.limit;
    if (data.offset) query.offset = data.offset;
    const response = await Net.getInstance().get<IGetVloggersHttpResult>({
      path: "/vloggers",
      query,
    });
    return response;
  }

  async getByUuid(
    data: IGetVloggersHttpGetByUuidData
  ): Promise<IResponse<IGetVloggersHttpGetByUuidResult>> {
    const response =
      await Net.getInstance().get<IGetVloggersHttpGetByUuidResult>({
        path: `/vloggers/uuid/${data.uuidVlogger}`,
      });
    return response;
  }
}
