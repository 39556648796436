import Net, { IBody } from "../net.http";
import { IResponse } from "../../utils/response.utils";
import { IShortUuidListSortable } from "../../validators/common/uuid.common.validations";
import { IPriceRange } from "../../validators/common/price-range.common.validators";

export interface IPutEntertainmentsCategoriesHttpUpdateByUuidData {
  readonly uuid: string;
  readonly name: string;
  readonly urlSlug: string;
  readonly description: string;
  readonly visibleOnSite: boolean;
}

export interface IPutEntertainmentsCategoriesHttpUpdateByUuidResponse {}

export class PutEntertainmentsCategoriesHttp {
  async updateByUuid(
    data: IPutEntertainmentsCategoriesHttpUpdateByUuidData
  ): Promise<IResponse<IPutEntertainmentsCategoriesHttpUpdateByUuidResponse>> {
    const body: IBody = {
      name: data.name,
      urlSlug: data.urlSlug,
      visibleOnSite: data.visibleOnSite,
    };
    if (data.description && data.description.length > 0) {
      body.description = data.description;
    }

    const response =
      await Net.getInstance().put<IPutEntertainmentsCategoriesHttpUpdateByUuidResponse>(
        {
          path: `/entertainments-categories/uuid/${data.uuid}`,
          body,
        }
      );
    return response;
  }
}
