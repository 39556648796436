import React, { ReactNode } from "react";
import { FunctionComponent, useContext } from "react";
import { Navigate } from "react-router-dom";
import { AccountContext } from "../contexts/account.contexts";
import LoadingElement from "../elements/loading.elements";

export interface IRouteHelperMoleculeProps {
  children: ReactNode;
}

export const RouteHelperMolecule: FunctionComponent<
  IRouteHelperMoleculeProps
> = ({ children }) => {
  const accountContext = useContext(AccountContext);

  if (accountContext.isLoggedIn === null) {
    return <LoadingElement />;
  }

  if (!accountContext.isLoggedIn) {
    return <Navigate to={"/auth/login"} />;
  }

  return <>{children}</>;
};
