import { ILocationModel } from "../../interfaces/models/location.models.interfaces";
import { IResponse } from "../../utils/response.utils";
import Net, { IQuery } from "../net.http";

export interface IGetLocationHttpData {
  limit?: number;
  offset?: number;
}

export interface IGetLocationHttpResult {
  locations: Array<ILocationModel>;
}

export interface IGetLocationHttpGetByUuidData {
  uuidLocation: string;
}

export interface IGetLocationHttpGetByUuidResult {
  location: ILocationModel;
}

export class GetLocationHttp {
  async getAll(
    data: IGetLocationHttpData
  ): Promise<IResponse<IGetLocationHttpResult>> {
    const query: IQuery = {};
    if (data.limit) query.limit = data.limit;
    if (data.offset) query.offset = data.offset;
    const response = await Net.getInstance().get<IGetLocationHttpResult>({
      path: "/locations",
      query,
    });
    return response;
  }

  async getByUuid(
    data: IGetLocationHttpGetByUuidData
  ): Promise<IResponse<IGetLocationHttpGetByUuidResult>> {
    const response =
      await Net.getInstance().get<IGetLocationHttpGetByUuidResult>({
        path: `/locations/uuid/${data.uuidLocation}`,
      });
    return response;
  }
}
