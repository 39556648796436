import { IActivityModel } from "../../interfaces/models/activities.models.interfaces";
import { IResponse } from "../../utils/response.utils";
import Net, { IQuery } from "../net.http";

export interface IGetActivitiesHttpData {
  readonly limit?: number;
  readonly offset?: number;
}

export interface IGetActivitiesHttpResult {
  readonly activities: Array<IActivityModel>;
}

export interface IGetActivitiesHttpGetByUuidData {
  readonly uuidActivity: string;
}

export interface IGetActivitiesHttpGetByUuidResult {
  readonly activity: IActivityModel;
}

export class GetActivitiesHttp {
  async getAll(
    data: IGetActivitiesHttpData
  ): Promise<IResponse<IGetActivitiesHttpResult>> {
    const query: IQuery = {};
    if (data.limit) query.limit = data.limit;
    if (data.offset) query.offset = data.offset;
    const response = await Net.getInstance().get<IGetActivitiesHttpResult>({
      path: "/activities",
      query,
    });
    return response;
  }

  async getByUuid(
    data: IGetActivitiesHttpGetByUuidData
  ): Promise<IResponse<IGetActivitiesHttpGetByUuidResult>> {
    const response =
      await Net.getInstance().get<IGetActivitiesHttpGetByUuidResult>({
        path: `/activities/uuid/${data.uuidActivity}`,
      });
    return response;
  }
}
