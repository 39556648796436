import * as yup from "yup";
import { UrlSlugCommonValidator } from "./common/url-slug.common.validators";

export const CollectionFormSchema = yup
  .object({
    name: yup.string().required("Required"),
    description: yup.string(),
    urlSlug: UrlSlugCommonValidator.required("Required"),
  })
  .required();
