import React from "react";
import { FunctionComponent, useContext } from "react";
import {
  NotificationContext,
  NotificationTypes,
} from "../contexts/notification.contexts";
import { Dialog } from "@headlessui/react";
import { ReactComponent as CloseSvg } from "../assets/svgs/close.svg";

const NOTIFICATION_COLORS: Record<NotificationTypes, string> = {
  error: "border-red-400",
  success: "border-green-400",
  general: "border-blue-400",
};

const NOTIFICATION_TITLE: Record<NotificationTypes, string> = {
  error: "Something is not right",
  success: "Success",
  general: "Info",
};

export const NotificationOrganism: FunctionComponent = () => {
  const notificationContext = useContext(NotificationContext);

  function getOffset(index: number) {
    return (notificationContext.notifications.length - index) * 24;
  }

  return (
    <>
      {notificationContext.notifications
        .sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime())
        .map((notification, index) => {
          return (
            <Dialog
              key={notification.uuid}
              open={true}
              onClose={() => undefined}
              className="relative"
              style={{
                zIndex: index + 40,
              }}
            >
              <Dialog.Panel
                className={`fixed right-6 max-w-md bg-white rounded-2xl px-4 py-2 border-2 shadow-2xl	 ${
                  NOTIFICATION_COLORS[notification.type]
                }`}
                style={{ top: 24 + getOffset(index) }}
              >
                <Dialog.Title
                  className={"flex flex-1 justify-between items-center mb-1"}
                >
                  <p className="font-semibold ">
                    {NOTIFICATION_TITLE[notification.type]}
                  </p>
                  <button
                    className="font-bold"
                    onClick={() =>
                      notificationContext.deleteNotification(notification.uuid)
                    }
                  >
                    <CloseSvg className="w-6 h-6 fill-neutral-600 hover:fill-neutral-900" />
                  </button>
                </Dialog.Title>

                <Dialog.Description className={""}>
                  {notification.message}
                </Dialog.Description>
              </Dialog.Panel>
            </Dialog>
          );
        })}
    </>
  );
};
