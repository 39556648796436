import Net from "../net.http";
import { IResponse } from "../../utils/response.utils";

export interface IDeleteCollectionsHttpDeleteByUuidData {
  uuid: string;
}

export interface IDeleteCollectionsHttpDeleteByUuidResponse {}

export class DeleteCollectionsHttp {
  async deleteByUuid(
    data: IDeleteCollectionsHttpDeleteByUuidData
  ): Promise<IResponse<IDeleteCollectionsHttpDeleteByUuidResponse>> {
    const response =
      await Net.getInstance().delete<IDeleteCollectionsHttpDeleteByUuidResponse>(
        {
          path: `/collections/uuid/${data.uuid}`,
        }
      );
    return response;
  }
}
