import Net, { IBody } from "../net.http";
import { IResponse } from "../../utils/response.utils";
import { IShortUuidListSortable } from "../../validators/common/uuid.common.validations";
import { IPriceRange } from "../../validators/common/price-range.common.validators";

export interface IPutEntertainmentsHttpUpdateByUuidData {
  readonly uuid: string;
  readonly name: string;
  readonly urlSlug: string;
  readonly description: string;
  readonly uuidCategory: string;
  readonly priceRange: IPriceRange;
  readonly visibleOnSite: boolean;
  readonly uuidListGallery: IShortUuidListSortable;
  readonly uuidListHeroPictures: IShortUuidListSortable;
}

export interface IPutEntertainmentsHttpUpdateByUuidResponse {}

export class PutEntertainmentsHttp {
  async updateByUuid(
    data: IPutEntertainmentsHttpUpdateByUuidData
  ): Promise<IResponse<IPutEntertainmentsHttpUpdateByUuidResponse>> {
    const body: IBody = {
      name: data.name,
      urlSlug: data.urlSlug,
      priceRange: data.priceRange,
      uuidCategory: data.uuidCategory,
      visibleOnSite: data.visibleOnSite,
      uuidListGallery: data.uuidListGallery,
      uuidListHeroPictures: data.uuidListHeroPictures,
    };
    if (data.description && data.description.length > 0) {
      body.description = data.description;
    }

    const response =
      await Net.getInstance().put<IPutEntertainmentsHttpUpdateByUuidResponse>({
        path: `/entertainments/uuid/${data.uuid}`,
        body,
      });
    return response;
  }
}
