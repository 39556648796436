import { IPropertyModel } from "../../interfaces/models/properties.models.interfaces";
import { IResponse } from "../../utils/response.utils";
import Net, { IQuery } from "../net.http";

export interface IGetPropertiesHttpData {
  limit?: number;
  offset?: number;
}

export interface IGetPropertiesHttpResult {
  properties: Array<IPropertyModel>;
}

export interface IGetPropertiesHttpGetByUuidData {
  uuidProperty: string;
}

export interface IGetPropertiesHttpGetByUuidResult {
  property: IPropertyModel;
}

export class GetPropertiesHttp {
  async getAll(
    data: IGetPropertiesHttpData
  ): Promise<IResponse<IGetPropertiesHttpResult>> {
    const query: IQuery = {};
    if (data.limit) query.limit = data.limit;
    if (data.offset) query.offset = data.offset;
    const response = await Net.getInstance().get<IGetPropertiesHttpResult>({
      path: "/properties",
      query,
    });
    return response;
  }

  async getByUuid(
    data: IGetPropertiesHttpGetByUuidData
  ): Promise<IResponse<IGetPropertiesHttpGetByUuidResult>> {
    const response =
      await Net.getInstance().get<IGetPropertiesHttpGetByUuidResult>({
        path: `/properties/uuid/${data.uuidProperty}`,
      });
    return response;
  }
}
