import React from "react";
import { FunctionComponent } from "react";
import { ContentContainer } from "../../elements/content-container.elements";
import { HeaderElement } from "../../elements/header.elements";
import { AddEntertainmentFormEntertainmentsOrganism } from "../../organisms/entertainments/add-entertainment-form.entertainments.organisms";

export const AddEntertainmentsPage: FunctionComponent = () => {
  return (
    <>
      <HeaderElement title="Add Entertainment" />
      <ContentContainer>
        <AddEntertainmentFormEntertainmentsOrganism />
      </ContentContainer>
    </>
  );
};
