import React, { useContext, useEffect, useState } from "react";
import { FunctionComponent } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { NotificationContext } from "../../contexts/notification.contexts";
import LoadingElement from "../../elements/loading.elements";
import { GetEntertainmentsHttp } from "../../http/entertainments/get.entertainments.http";
import { PutEntertainmentsHttp } from "../../http/entertainments/put.entertainments.http";
import { IEntertainmentsModel } from "../../interfaces/models/entertainments.models.interfaces";
import {
  IEntertainmentsFormData,
  EntertainmentFormEntertainmentsOrganism,
} from "./entertainment-form.entertainments.organisms";

export interface IEditEntertainmentFormEntertainmentsOrganismProps {}

export const EditEntertainmentFormEntertainmentsOrganism: FunctionComponent<
  IEditEntertainmentFormEntertainmentsOrganismProps
> = () => {
  const { uuidEntertainment } = useParams();
  const navigate = useNavigate();
  const errorContext = useContext(NotificationContext);

  const [gettingEntertainment, setGettingEntertainment] =
    useState<boolean>(true);
  const [updaingEntertainment, setUpdatingEntertainment] =
    useState<boolean>(false);
  const [initialEntertainment, setInitialEntertainment] =
    useState<IEntertainmentsModel>();

  useEffect(() => {
    if (!uuidEntertainment) {
      return;
    }
    fetchEntertainment(uuidEntertainment);
  }, [uuidEntertainment]);

  async function fetchEntertainment(localUuidEntertainment: string) {
    setGettingEntertainment(true);
    const response = await new GetEntertainmentsHttp().getByUuid({
      uuidEntertainment: localUuidEntertainment,
    });
    if (response.error) {
      await errorContext.showError({
        message: response.errorData.message,
      });
      return setGettingEntertainment(false);
    }
    setInitialEntertainment(response.successData.entertainment);
    setGettingEntertainment(false);
  }

  async function updateEntertainment(data: IEntertainmentsFormData) {
    setUpdatingEntertainment(true);
    if (!initialEntertainment) {
      throw new Error("Initial entertainment not found");
    }
    if (!data.category) {
      throw new Error("Category is required");
    }
    const response = await new PutEntertainmentsHttp().updateByUuid({
      uuid: initialEntertainment.uuid,
      name: data.name,
      urlSlug: data.urlSlug,
      priceRange: data.priceRange,
      description: data.description,
      uuidCategory: data.category.uuid,
      visibleOnSite: data.visibleOnSite,
      uuidListGallery: data.gallery.map((i) => ({
        uuid: i.uuid,
        index: i.index,
      })),
      uuidListHeroPictures: data.heroPictures.map((i) => ({
        uuid: i.uuid,
        index: i.index,
      })),
    });
    if (response.error) {
      await errorContext.showError({
        message: response.errorData.message,
      });
      return setGettingEntertainment(false);
    }

    setUpdatingEntertainment(false);
    navigate(-1);
  }

  if (!uuidEntertainment) {
    throw new Error("Couldn't find uuidEntertainment param");
  }

  if (gettingEntertainment) {
    return (
      <div className="flex flex-1 justify-center">
        <LoadingElement />
      </div>
    );
  }

  if (!initialEntertainment) {
    return <p className="w-full text-center">Entertainment not found</p>;
  }

  return (
    <EntertainmentFormEntertainmentsOrganism
      initialData={{
        name: initialEntertainment.name,
        urlSlug: initialEntertainment.urlSlug,
        description: initialEntertainment.description || "",
        visibleOnSite: initialEntertainment.visibleOnSite,
        gallery: initialEntertainment.gallery,
        heroPictures: initialEntertainment.heroPictures,
        priceRange: initialEntertainment.priceRange,
        category: initialEntertainment.category,
      }}
      formType="edit"
      submitInProgress={updaingEntertainment}
      setSubmitInProgress={setUpdatingEntertainment}
      onSubmitForm={updateEntertainment}
    />
  );
};
