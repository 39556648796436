import React, { useContext, useState } from "react";
import { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";
import { NotificationContext } from "../../contexts/notification.contexts";
import { PostEntertainmentsCategoriesHttp } from "../../http/entertainments-categories/post.entertainments-categories.http";
import {
  IEntertainmentsCategoryFormData,
  FormEntertainmentsCategoryOrganism,
} from "./form.entertainments-categories.organisms";

export const AddEntertainmentCategoryFormOrganism: FunctionComponent = ({}) => {
  const errorContext = useContext(NotificationContext);
  const navigate = useNavigate();

  const [creatingEntertainmentsCategory, setCreatingEntertainmentsCategory] =
    useState<boolean>(false);

  async function addEntertainmentsCategory(
    data: IEntertainmentsCategoryFormData
  ) {
    setCreatingEntertainmentsCategory(true);
    const response = await new PostEntertainmentsCategoriesHttp().create({
      name: data.name,
      urlSlug: data.urlSlug,
      description: data.description,
      visibleOnSite: data.visibleOnSite,
    });
    if (response.error) {
      await errorContext.showError({
        message: response.errorData.message,
      });
      return setCreatingEntertainmentsCategory(false);
    }
    navigate(-1);
  }

  return (
    <FormEntertainmentsCategoryOrganism
      initialData={{
        name: "",
        urlSlug: "",
        description: "",
        visibleOnSite: false,
      }}
      formType="add"
      submitInProgress={creatingEntertainmentsCategory}
      setSubmitInProgress={setCreatingEntertainmentsCategory}
      onSubmitForm={addEntertainmentsCategory}
    />
  );
};
