import React from "react";
import { FunctionComponent } from "react";
import { ContentContainer } from "../../elements/content-container.elements";
import { HeaderElement } from "../../elements/header.elements";
import { AddVloggerFormVloggersOrganism } from "../../organisms/vloggers/add-vlogger-form.vloggers.organisms";

export const AddVloggersPage: FunctionComponent = () => {
  return (
    <>
      <HeaderElement title="Add Vlogger" />
      <ContentContainer>
        <AddVloggerFormVloggersOrganism />
      </ContentContainer>
    </>
  );
};
