import React, { useRef, useState } from "react";
import { FunctionComponent } from "react";
import { Formik, Field, Form } from "formik";
import {
  DropzoneMultiplePictureElement,
  IDropzoneMultiplePictureElementRef,
} from "../../elements/dropzone-multiple-picture.elements";
import LoadingElement from "../../elements/loading.elements";
import { IPictureListSortable } from "../../validators/common/picture-list-sortable.common.validators";
import { IPriceRange } from "../../validators/common/price-range.common.validators";
import { EntertainmentsFormSchema } from "../../validators/entertainments-form.validation";
import { Switch } from "@headlessui/react";
import { IEntertainmentsCategoryModel } from "../../interfaces/models/entertainments-categories.models.interfaces";
import { EntertainmentsCategoryDropdownMolecule } from "../../molecules/entertainments-category-dropdown.molecules";

export interface IEntertainmentsFormData {
  name: string;
  urlSlug: string;
  description: string;
  visibleOnSite: boolean;
  priceRange: IPriceRange;
  gallery: IPictureListSortable;
  heroPictures: IPictureListSortable;
  category: IEntertainmentsCategoryModel | null;
}

interface IEntertainmentsFormDataInternal extends IEntertainmentsFormData {
  priceRangeMin: string;
  priceRangeMax: string;
}

export interface IEntertainmentFormEntertainmentsOrganismProps {
  initialData: IEntertainmentsFormData;
  submitInProgress: boolean;
  setSubmitInProgress: (state: boolean) => void;
  formType: "add" | "edit";
  onSubmitForm: (data: IEntertainmentsFormData) => Promise<void>;
}

export const EntertainmentFormEntertainmentsOrganism: FunctionComponent<
  IEntertainmentFormEntertainmentsOrganismProps
> = ({
  submitInProgress,
  setSubmitInProgress,
  formType,
  initialData,
  onSubmitForm,
}) => {
  const [visibleOnSite, setVisibleOnSite] = useState(initialData.visibleOnSite);
  const galleryRef = useRef<IDropzoneMultiplePictureElementRef>(null);
  const heroPicturesRef = useRef<IDropzoneMultiplePictureElementRef>(null);
  const [category, setCategory] = useState<IEntertainmentsCategoryModel | null>(
    initialData.category
  );

  return (
    <Formik<IEntertainmentsFormDataInternal>
      initialValues={{
        name: initialData.name,
        urlSlug: initialData.urlSlug,
        category: initialData.category,
        description: initialData.description,
        visibleOnSite: initialData.visibleOnSite,
        priceRangeMin: initialData.priceRange?.min
          ? `${initialData.priceRange?.min}`
          : "",
        priceRangeMax: initialData.priceRange?.max
          ? `${initialData.priceRange?.max}`
          : "",
        priceRange: null, // priceRange is managed seperately
        gallery: [], // gallery is managed seperately
        heroPictures: [], // heroPictures is managed seperately
      }}
      validationSchema={EntertainmentsFormSchema}
      onSubmit={async (formData) => {
        if (!category) {
          throw new Error(
            "Category is required, this should have been caught by validation"
          );
        }
        if (!galleryRef.current) {
          throw new Error("galleryRef is null");
        }
        if (!heroPicturesRef.current) {
          throw new Error("heroPicturesRef is null");
        }
        setSubmitInProgress(true);

        const heroPictures = await heroPicturesRef.current.getFinalPictures();
        if (heroPictures.error) {
          return setSubmitInProgress(false);
        }
        const galleryPictures = await galleryRef.current.getFinalPictures();
        if (galleryPictures.error) {
          return setSubmitInProgress(false);
        }

        setSubmitInProgress(true);
        await onSubmitForm({
          ...formData,
          visibleOnSite,
          category,
          priceRange: formData.priceRangeMin
            ? {
                min: formData.priceRangeMin as unknown as number,
                max: formData.priceRangeMax as unknown as number,
              }
            : null,
          gallery: galleryPictures.data.pictures,
          heroPictures: heroPictures.data.pictures,
        });
      }}
    >
      {({ errors, touched, setFieldValue }) => {
        return (
          <Form className="flex flex-1 flex-col">
            <label className="mt-4">Hero Pictures</label>
            <div className="flex flex-1 border border-neutral-200 rounded-lg px-4 py-4 mt-1 flex-col items-center">
              <DropzoneMultiplePictureElement
                ref={heroPicturesRef}
                pictures={initialData.heroPictures}
              />
            </div>

            <label className="mt-4">
              URL Slug{" "}
              {errors.urlSlug && touched.urlSlug ? (
                <span className="mt-2 text-red-600 text-base font-normal">
                  ({errors.urlSlug})
                </span>
              ) : null}
            </label>
            <Field
              name="urlSlug"
              type="text"
              placeholder="URL Slug"
              className="flex border border-neutral-200 rounded-lg px-4 py-2 mt-1"
            />

            <label className="mt-4">
              Name{" "}
              {errors.name && touched.name ? (
                <span className="mt-2 text-red-600 text-base font-normal">
                  ({errors.name})
                </span>
              ) : null}
            </label>
            <Field
              name="name"
              type="text"
              placeholder="Name"
              className="flex border rounded-lg px-4 py-2 mt-1"
            />

            <label className="mt-4">
              Description{" "}
              {errors.description && touched.description ? (
                <span className="mt-2 text-red-600 text-base font-normal">
                  ({errors.description})
                </span>
              ) : null}
            </label>
            <Field
              name="description"
              as="textarea"
              placeholder="Description"
              className="flex border rounded-lg px-4 py-2 mt-1"
            />

            <div className="flex flex-1 mt-4 grid grid-cols-2 gap-6">
              <div className="flex flex-1 flex-col">
                <label className="">
                  Minimum Price{" "}
                  {errors.priceRangeMin && touched.priceRangeMin ? (
                    <span className="mt-2 text-red-600 text-base font-normal">
                      ({errors.priceRangeMin})
                    </span>
                  ) : null}
                </label>
                <Field
                  name="priceRangeMin"
                  type="text"
                  placeholder="Minimum Price"
                  className="flex border rounded-lg px-4 py-2 mt-1"
                />
              </div>

              <div className="flex flex-1 flex-col">
                <label className="">
                  Maximum Price{" "}
                  {errors.priceRangeMax && touched.priceRangeMax ? (
                    <span className="mt-2 text-red-600 text-base font-normal">
                      ({errors.priceRangeMax})
                    </span>
                  ) : null}
                </label>
                <Field
                  name="priceRangeMax"
                  type="text"
                  placeholder="Maximum Price"
                  className="flex border rounded-lg px-4 py-2 mt-1"
                />
              </div>
            </div>

            <label className="mt-4">
              Category{" "}
              {errors.category && touched.category ? (
                <span className="mt-2 text-red-600 text-base font-normal">
                  ({errors.category})
                </span>
              ) : null}
            </label>
            <EntertainmentsCategoryDropdownMolecule
              selected={category}
              onChange={(newCategory) => {
                setCategory(newCategory);
                setFieldValue("category", newCategory);
              }}
            />

            <label className="mt-4">Gallery</label>
            <div className="flex flex-1 border border-neutral-200 rounded-lg px-4 py-4 mt-1 flex-col items-center">
              <DropzoneMultiplePictureElement
                ref={galleryRef}
                pictures={initialData.gallery}
              />
            </div>

            <label className="mt-4">Visible on site</label>
            <div className="flex flex-1 border border-neutral-200 rounded-lg px-4 py-4 mt-1 flex-row items-center">
              <Switch
                checked={visibleOnSite}
                onChange={setVisibleOnSite}
                className={`${visibleOnSite ? "bg-sky-300" : "bg-gray-200"}
          relative inline-flex h-[38px] w-[74px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
              >
                <span
                  aria-hidden="true"
                  className={`${
                    visibleOnSite ? "translate-x-9" : "translate-x-0"
                  }
            pointer-events-none inline-block h-[34px] w-[34px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                />
              </Switch>
              <p className="pl-4">
                Item will be shown on website only when the toggle is on
              </p>
            </div>

            <button
              type="submit"
              className="border rounded-lg px-4 py-2 mt-6 bg-sky-200 border-sky-200 font-semibold text-center cursor-pointer"
            >
              {submitInProgress ? (
                <LoadingElement />
              ) : (
                <>{formType === "add" ? "Add Entertainment" : "Save Changes"}</>
              )}
            </button>
          </Form>
        );
      }}
    </Formik>
  );
};
