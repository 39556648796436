import React, { useContext, useEffect, useState } from "react";
import { FunctionComponent } from "react";
import { useLocation } from "react-router-dom";
import { NotificationContext } from "../../contexts/notification.contexts";
import { ContentContainer } from "../../elements/content-container.elements";
import { HeaderElement } from "../../elements/header.elements";
import { GetActivitiesHttp } from "../../http/activities/get.activities.http";
import { IActivityModel } from "../../interfaces/models/activities.models.interfaces";
import { ActivitiesListOrganism } from "../../organisms/activities/activities-list.activities.organisms";

export const IndexActivitiesPage: FunctionComponent = () => {
  const { pathname } = useLocation();
  const errorContext = useContext(NotificationContext);

  const [fetchingActivities, setFetchingActivities] = useState<boolean>(false);
  const [activities, setActivities] = useState<Array<IActivityModel>>();

  useEffect(() => {
    fetchActivities();
  }, []);

  async function fetchActivities() {
    setFetchingActivities(true);
    const response = await new GetActivitiesHttp().getAll({
      offset: activities ? activities.length : 0,
    });
    if (response.error) {
      await errorContext.showError({
        message: response.errorData.message,
      });
      return setFetchingActivities(false);
    }
    const tempActivities = (activities || []).concat(
      response.successData.activities
    );
    setActivities(tempActivities);
    setFetchingActivities(false);
  }

  return (
    <>
      <HeaderElement
        title="Activities"
        action={{
          title: "Add Activity",
          href: `${pathname}/add`,
        }}
      />
      <ContentContainer>
        <ActivitiesListOrganism
          loading={fetchingActivities}
          activities={activities}
          setActivities={setActivities}
        />
      </ContentContainer>
    </>
  );
};
