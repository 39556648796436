import React from "react";
import { FunctionComponent } from "react";
import { ContentContainer } from "../../elements/content-container.elements";
import { HeaderElement } from "../../elements/header.elements";
import { AddPropertiesFormPropertiesOrganism } from "../../organisms/properties/add-properties-form.properties.organisms";

export const AddPropertiesPage: FunctionComponent = () => {
  return (
    <>
      <HeaderElement title="Add Property" />
      <ContentContainer>
        <AddPropertiesFormPropertiesOrganism />
      </ContentContainer>
    </>
  );
};
