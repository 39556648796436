import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { RouteHelperMolecule } from "./molecules/route-helper.molecules";
import { AccountContextProvider } from "./contexts/account.contexts";
import { LoginAuthsPage } from "./pages/auths/login.auths.pages";
import { IndexLocationsPage } from "./pages/locations/index.locations.pages";
import { IndexPropertiesPage } from "./pages/properties/index.properties.pages";
import { DashboardOrganism } from "./organisms/dashboard.organisms";
import { IndexProfilePage } from "./pages/profile/index.profile.pages";
import { AddLocationsPage } from "./pages/locations/add.locations.pages";
import { AddPropertiesPage } from "./pages/properties/add.properties.pages";
import { EditPropertiesPage } from "./pages/properties/edit.properties.pages";
import { EditLocationsPage } from "./pages/locations/edit.locations.pages";
import { IndexActivitiesPage } from "./pages/activities/index.activities.pages";
import { AddActivitiesPage } from "./pages/activities/add.activities.pages";
import { EditActivitiesPage } from "./pages/activities/edit.activities.pages";
import { IndexVloggersPage } from "./pages/vloggers/index.vloggers.pages";
import { AddVloggersPage } from "./pages/vloggers/add.vloggers.pages";
import { EditVloggersPage } from "./pages/vloggers/edit.vloggers.pages";
import { NotificationOrganism } from "./organisms/notifications.organisms";
import { IndexCollectionsPage } from "./pages/collections/index.collections.pages";
import { AddCollectionsPage } from "./pages/collections/add.collections.pages";
import { EditCollectionsPage } from "./pages/collections/edit.collections.pages";
import { IndexInvoicePage } from "./pages/invoice";
import { InvoicePrintViewPage } from "./pages/invoice-print-view.pages";
import { IndexClientsPage } from "./pages/invoice/clients";
import { IndexEntertainmentsPage } from "./pages/entertainments/index.entertainments.pages";
import { AddEntertainmentsPage } from "./pages/entertainments/add.entertainments.pages";
import { EditEntertainmentsPage } from "./pages/entertainments/edit.entertainments.pages";
import { IndexEntertainmentsCategoriesPage } from "./pages/entertainments-categories/index.entertainments-categories.pages";
import { AddEntertainmentsCategoriesPage } from "./pages/entertainments-categories/add.entertainments-categories.pages";
import { EditEntertainmentsCategoriesPage } from "./pages/entertainments-categories/edit.entertainments-categories.pages";

function App() {
  return (
    <>
      <NotificationOrganism />
      <BrowserRouter>
        <AccountContextProvider>
          <Routes>
            <Route path="/auth">
              <Route path="login" element={<LoginAuthsPage />} />
            </Route>
            <Route path="/invoice-print-view">
              <Route index element={<InvoicePrintViewPage />} />
            </Route>
            <Route
              path="/"
              element={
                <RouteHelperMolecule>
                  <DashboardOrganism />
                </RouteHelperMolecule>
              }
            >
              <Route path="locations">
                <Route index element={<IndexLocationsPage />} />
                <Route path="add" element={<AddLocationsPage />} />
                <Route
                  path=":uuidLocation/edit"
                  element={<EditLocationsPage />}
                />
              </Route>

              <Route path="properties">
                <Route index element={<IndexPropertiesPage />} />
                <Route path="add" element={<AddPropertiesPage />} />
                <Route
                  path=":uuidProperty/edit"
                  element={<EditPropertiesPage />}
                />
              </Route>

              <Route path="activities">
                <Route index element={<IndexActivitiesPage />} />
                <Route path="add" element={<AddActivitiesPage />} />
                <Route
                  path=":uuidActivity/edit"
                  element={<EditActivitiesPage />}
                />
              </Route>

              <Route path="entertainments">
                <Route index element={<IndexEntertainmentsPage />} />
                <Route path="add" element={<AddEntertainmentsPage />} />
                <Route
                  path=":uuidEntertainment/edit"
                  element={<EditEntertainmentsPage />}
                />
              </Route>

              <Route path="entertainments-categories">
                <Route index element={<IndexEntertainmentsCategoriesPage />} />
                <Route
                  path="add"
                  element={<AddEntertainmentsCategoriesPage />}
                />
                <Route
                  path=":uuidEntertainmentsCategory/edit"
                  element={<EditEntertainmentsCategoriesPage />}
                />
              </Route>

              <Route path="vloggers">
                <Route index element={<IndexVloggersPage />} />
                <Route path="add" element={<AddVloggersPage />} />
                <Route
                  path=":uuidVlogger/edit"
                  element={<EditVloggersPage />}
                />
              </Route>

              <Route path="collections">
                <Route index element={<IndexCollectionsPage />} />
                <Route path="add" element={<AddCollectionsPage />} />
                <Route
                  path=":uuidCollection/edit"
                  element={<EditCollectionsPage />}
                />
              </Route>

              <Route path="invoice">
                <Route index element={<IndexInvoicePage />} />
                <Route path="clients" element={<IndexClientsPage />} />
              </Route>

              <Route path="profile">
                <Route index element={<IndexProfilePage />} />
              </Route>
            </Route>
          </Routes>
        </AccountContextProvider>
      </BrowserRouter>
    </>
  );
}

export default App;
