import React from "react";
import { FunctionComponent } from "react";
import { ContentContainer } from "../../elements/content-container.elements";
import { HeaderElement } from "../../elements/header.elements";
import { AddActivityFormActivitiesOrganism } from "../../organisms/activities/add-activity-form.activities.organisms";

export const AddActivitiesPage: FunctionComponent = () => {
  return (
    <>
      <HeaderElement title="Add Activity" />
      <ContentContainer>
        <AddActivityFormActivitiesOrganism />
      </ContentContainer>
    </>
  );
};
