import React, { FunctionComponent, ReactNode } from "react";

export interface IContentContainerProps {
  children: ReactNode;
}

export const ContentContainer: FunctionComponent<IContentContainerProps> = ({
  children,
}) => {
  return (
    <div className="flex flex-1 flex-row items-center container mx-auto max-w-screen-lg h-full px-2">
      {children}
    </div>
  );
};
