import { IAssetCommon } from "../../interfaces/common/asset.common.interfaces";
import { IResponse } from "../../utils/response.utils";
import Net, { IBody } from "../net.http";

export interface IPostAssetsHttpCreateData {
  file: File;
  path?: string;
}

export interface IPostAssetsHttpCreateRresponse {
  asset: IAssetCommon;
}

export class PostAssetsHttp {
  async create(
    data: IPostAssetsHttpCreateData
  ): Promise<IResponse<IPostAssetsHttpCreateRresponse>> {
    const body: IBody = new FormData();
    body.append("asset", data.file);

    const response =
      await Net.getInstance().post<IPostAssetsHttpCreateRresponse>({
        path: "/assets",
        multipart: true,
        body: body,
      });
    return response;
  }
}
