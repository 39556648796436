export enum DurationTypes {
  MINUTES = 2,
  HOURS = 3,
}

export const DurationTypesString: Record<DurationTypes, string> = {
  [DurationTypes.MINUTES]: "Minutes",
  [DurationTypes.HOURS]: "Hours",
};

export const DurationTypesValues = Object.values(DurationTypes).filter(
  (x) => typeof x === "number"
) as Array<DurationTypes>;
