import React from "react";
import { FunctionComponent } from "react";
import { ContentContainer } from "../../elements/content-container.elements";
import { HeaderElement } from "../../elements/header.elements";
import { EditActivitiesFormActivitiesOrganism } from "../../organisms/activities/edit-activity-form.activities.organisms";

export const EditActivitiesPage: FunctionComponent = () => {
  return (
    <>
      <HeaderElement title="Edit Activity" />
      <ContentContainer>
        <EditActivitiesFormActivitiesOrganism />
      </ContentContainer>
    </>
  );
};
