import React from "react";
import { FunctionComponent } from "react";

export interface ILoadingElementProps {
  colorClasses?: string;
}

const LoadingElement: FunctionComponent<ILoadingElementProps> = ({
  colorClasses,
}) => {
  return (
    <div className="flex justify-center items-center">
      <div
        className={`animate-spin rounded-full border-4 border-t-4 border-black/10 ${
          colorClasses || "border-t-sky-400"
        } h-6 w-6`}
      ></div>
    </div>
  );
};

export default LoadingElement;
