import React from "react";
import { FunctionComponent } from "react";
import { ContentContainer } from "../../elements/content-container.elements";
import { HeaderElement } from "../../elements/header.elements";
import { EditCollectionFormCollectionsOrganism } from "../../organisms/collections/edit-location-form.locations.organisms";

export const EditCollectionsPage: FunctionComponent = () => {
  return (
    <>
      <HeaderElement title="Edit Collection" />
      <ContentContainer>
        <EditCollectionFormCollectionsOrganism />
      </ContentContainer>
    </>
  );
};
