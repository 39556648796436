import { IEntertainmentsCategoryModel } from "../../interfaces/models/entertainments-categories.models.interfaces";
import { IResponse } from "../../utils/response.utils";
import Net, { IBody } from "../net.http";

export interface IPostEntertainmentsCategoriesHttpCreateData {
  readonly name: string;
  readonly urlSlug: string;
  readonly description: string;
  readonly visibleOnSite: boolean;
}

export interface IPostEntertainmentsCategoriesHttpCreateResult {
  readonly entertainment: IEntertainmentsCategoryModel;
}

export class PostEntertainmentsCategoriesHttp {
  async create(
    data: IPostEntertainmentsCategoriesHttpCreateData
  ): Promise<IResponse<IPostEntertainmentsCategoriesHttpCreateResult>> {
    const body: IBody = {
      name: data.name,
      urlSlug: data.urlSlug,
      visibleOnSite: data.visibleOnSite,
    };
    if (data.description && data.description.length > 0) {
      body.description = data.description;
    }

    const response =
      await Net.getInstance().post<IPostEntertainmentsCategoriesHttpCreateResult>(
        {
          path: "/entertainments-categories",
          body: body,
        }
      );
    return response;
  }
}
