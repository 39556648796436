import React, { useContext, useEffect, useState } from "react";
import { FunctionComponent } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { NotificationContext } from "../../contexts/notification.contexts";
import LoadingElement from "../../elements/loading.elements";
import { GetActivitiesHttp } from "../../http/activities/get.activities.http";
import { PutActivitiesHttp } from "../../http/activities/put.activities.http";
import { IActivityModel } from "../../interfaces/models/activities.models.interfaces";
import {
  IActivitiesFormData,
  ActivityFormActivitiesOrganism,
} from "./activity-form.activities.organisms";

export interface IEditActivitiesFormActivitiesOrganismProps {}

export const EditActivitiesFormActivitiesOrganism: FunctionComponent<
  IEditActivitiesFormActivitiesOrganismProps
> = () => {
  const { uuidActivity } = useParams();
  const navigate = useNavigate();
  const errorContext = useContext(NotificationContext);

  const [gettingActivity, setGettingActivity] = useState<boolean>(true);
  const [updaingActivity, setUpdatingActivity] = useState<boolean>(false);
  const [initialActivity, setInitialActivity] = useState<IActivityModel>();

  useEffect(() => {
    if (!uuidActivity) {
      return;
    }
    fetchActivity(uuidActivity);
  }, [uuidActivity]);

  async function fetchActivity(localUuidActivity: string) {
    setGettingActivity(true);
    const response = await new GetActivitiesHttp().getByUuid({
      uuidActivity: localUuidActivity,
    });
    if (response.error) {
      await errorContext.showError({
        message: response.errorData.message,
      });
      return setGettingActivity(false);
    }
    setInitialActivity(response.successData.activity);
    setGettingActivity(false);
  }

  async function updateActivity(data: IActivitiesFormData) {
    setUpdatingActivity(true);
    if (!initialActivity) {
      throw new Error("Initial activity not found");
    }
    const response = await new PutActivitiesHttp().updateByUuid({
      uuid: initialActivity.uuid,
      name: data.name,
      urlSlug: data.urlSlug,
      priceRange: data.priceRange,
      description: data.description,
      duration: data.duration,
      groupSize: data.groupSize,
      whereToPlay: data.whereToPlay,
      uuidListGallery: data.gallery.map((i) => ({
        uuid: i.uuid,
        index: i.index,
      })),
      visibleOnSite: data.visibleOnSite,
      uuidListHeroPictures: data.heroPictures.map((i) => ({
        uuid: i.uuid,
        index: i.index,
      })),
    });
    if (response.error) {
      await errorContext.showError({
        message: response.errorData.message,
      });
      return setGettingActivity(false);
    }

    setUpdatingActivity(false);
    navigate(-1);
  }

  if (!uuidActivity) {
    throw new Error("Couldn't find uuidActivity param");
  }

  if (gettingActivity) {
    return (
      <div className="flex flex-1 justify-center">
        <LoadingElement />
      </div>
    );
  }

  if (!initialActivity) {
    return <p className="w-full text-center">Activity not found</p>;
  }

  return (
    <ActivityFormActivitiesOrganism
      initialData={{
        name: initialActivity.name,
        urlSlug: initialActivity.urlSlug,
        description: initialActivity.description || "",
        visibleOnSite: initialActivity.visibleOnSite,
        gallery: initialActivity.gallery,
        heroPictures: initialActivity.heroPictures,
        priceRange: initialActivity.priceRange,
        duration: initialActivity.duration,
        groupSize: initialActivity.groupSize,
        whereToPlay: initialActivity.whereToPlay,
      }}
      formType="edit"
      submitInProgress={updaingActivity}
      setSubmitInProgress={setUpdatingActivity}
      onSubmitForm={updateActivity}
    />
  );
};
