import React, { useContext, useEffect, useState } from "react";
import { FunctionComponent } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { NotificationContext } from "../../contexts/notification.contexts";
import LoadingElement from "../../elements/loading.elements";
import { GetCollectionsHttp } from "../../http/collections/get.collections.http";
import { PutCollectionsHttp } from "../../http/collections/put.collections.http";
import { ICollectionsModel } from "../../interfaces/models/collections.models.interfaces";
import {
  ICollectionsFormData,
  CollectionsFormCollectionsOrganism,
} from "./collection-form.collections.organisms";

export const EditCollectionFormCollectionsOrganism: FunctionComponent = () => {
  const { uuidCollection } = useParams();
  const navigate = useNavigate();
  const errorContext = useContext(NotificationContext);

  const [gettingCollection, setGettingCollection] = useState<boolean>(true);
  const [updaingCollection, setUpdatingCollection] = useState<boolean>(false);
  const [initialCollection, setInitialCollection] =
    useState<ICollectionsModel>();

  useEffect(() => {
    if (!uuidCollection) {
      return;
    }
    fetchCollection(uuidCollection);
  }, [uuidCollection]);

  async function fetchCollection(localUuidCollection: string) {
    setGettingCollection(true);
    const response = await new GetCollectionsHttp().getByUuid({
      uuidCollection: localUuidCollection,
    });
    if (response.error) {
      await errorContext.showError({
        message: response.errorData.message,
      });
      return setGettingCollection(false);
    }
    setInitialCollection(response.successData.collection);
    setGettingCollection(false);
  }

  async function updateCollection(data: ICollectionsFormData) {
    setUpdatingCollection(true);
    if (!initialCollection) {
      await errorContext.showError({
        message: "Collection not found",
      });
      return setUpdatingCollection(true);
    }

    const response = await new PutCollectionsHttp().updateByUuid({
      uuid: initialCollection.uuid,
      name: data.name,
      urlSlug: data.urlSlug,
      description: data.description,
      visibleOnSite: data.visibleOnSite,
      uuidThumbnailPicture: data.thumbnailPicture?.uuid || null,
    });
    if (response.error) {
      await errorContext.showError({
        message: response.errorData.message,
      });
      return setUpdatingCollection(false);
    }

    setUpdatingCollection(false);
    navigate(-1);
  }

  if (!uuidCollection) {
    throw new Error("Couldn't find uuidCollection param");
  }

  if (gettingCollection) {
    return (
      <div className="flex flex-1 justify-center">
        <LoadingElement />
      </div>
    );
  }

  if (!initialCollection) {
    return <p className="w-full text-center">Collection not found</p>;
  }

  return (
    <CollectionsFormCollectionsOrganism
      initialData={{
        name: initialCollection.name,
        urlSlug: initialCollection.urlSlug,
        description: initialCollection.description || "",
        visibleOnSite: initialCollection.visibleOnSite,
        thumbnailPicture: initialCollection.thumbnailPicture,
      }}
      formType="edit"
      submitInProgress={updaingCollection}
      setSubmitInProgress={setUpdatingCollection}
      onSubmitForm={updateCollection}
    />
  );
};
