import React, {
  createContext,
  FunctionComponent,
  ReactNode,
  useEffect,
  useState,
} from "react";
import { v4 as uuidv4 } from "uuid";

const NOTIFICATION_AUTO_REMOVE_DURATION = 5000;

export interface INotificationContextProviderShowErrorData {
  message: string;
}

export interface INotificationContextProviderShowSuccessData {
  message: string;
}

export type NotificationTypes = "error" | "success" | "general";

export interface INotificationContextNotificationState {
  uuid: string;
  message: string;
  createdAt: Date;
  type: NotificationTypes;
}

export interface INotificationContext {
  notifications: Array<INotificationContextNotificationState>;
  showError: (
    error: INotificationContextProviderShowErrorData
  ) => Promise<void>;
  showSuccess: (
    error: INotificationContextProviderShowSuccessData
  ) => Promise<void>;
  deleteNotification: (uuid: string) => void;
}

export const NotificationContext = createContext<INotificationContext>({
  notifications: [],
  showError: async () => {},
  showSuccess: async () => {},
  deleteNotification: () => {},
});

export interface INotificationContextProviderProps {
  children: ReactNode;
}

export const NotificationContextProvider: FunctionComponent<
  INotificationContextProviderProps
> = ({ children }) => {
  const [notifications, setNotifications] = useState<
    Array<INotificationContextNotificationState>
  >([]);

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date();
      now.setSeconds(now.getSeconds() - 10);
      const newNotificationList = notifications.filter(
        (notif) => notif.createdAt > now
      );
      console.log(
        "newNotificationList.length !== notifications.length",
        newNotificationList.length !== notifications.length,
        newNotificationList.length,
        notifications.length
      );
      if (newNotificationList.length !== notifications.length) {
        setNotifications(newNotificationList);
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  });

  async function showError(error: INotificationContextProviderShowErrorData) {
    const notification: INotificationContextNotificationState = {
      uuid: uuidv4(),
      message: error.message,
      createdAt: new Date(),
      type: "error",
    };
    const tempErrors = [notification, ...notifications];
    setNotifications(tempErrors);
  }

  async function showSuccess(
    error: INotificationContextProviderShowSuccessData
  ) {
    const notification: INotificationContextNotificationState = {
      uuid: uuidv4(),
      message: error.message,
      createdAt: new Date(),
      type: "success",
    };
    const tempSuccess = [notification, ...notifications];
    setNotifications(tempSuccess);
  }

  async function deleteNotification(uuid: string) {
    setNotifications(notifications.filter((notif) => notif.uuid !== uuid));
  }

  return (
    <NotificationContext.Provider
      value={{ notifications, showError, showSuccess, deleteNotification }}
    >
      {children}
    </NotificationContext.Provider>
  );
};
