import React, { useContext, useState } from "react";
import { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";
import { NotificationContext } from "../../contexts/notification.contexts";
import { PostPropertiesHttp } from "../../http/properties/post.properties.http";
import {
  IPropertiesFormData,
  PropertiesFormPropertiesOrganism,
} from "./properties-form.properties.organisms";

export const AddPropertiesFormPropertiesOrganism: FunctionComponent = () => {
  const errorContext = useContext(NotificationContext);
  const navigate = useNavigate();

  const [creatingProperty, setCreatingProperty] = useState<boolean>(false);

  async function addProperty(data: IPropertiesFormData) {
    if (!data.location) {
      throw new Error("Location is required");
    }
    setCreatingProperty(true);
    const response = await new PostPropertiesHttp().create({
      name: data.name,
      urlSlug: data.urlSlug,
      priceRange: data.priceRange,
      summary: data.summary,
      description: data.description,
      uuidLocation: data.location.uuid,
      starRatings: data.starRatings,
      uuidListGallery: data.gallery.map((i) => ({
        uuid: i.uuid,
        index: i.index,
      })),
      roomsQuanity: data.roomsQuanity,
      visibleOnSite: data.visibleOnSite,
      uuidThumbnailPicture: data.thumbnailPicture?.uuid || null,
      uuidListCollection: data.collections.map((item, index) => {
        return {
          uuid: item.uuid,
          index: index,
        };
      }),
    });
    if (response.error) {
      await errorContext.showError({
        message: response.errorData.message,
      });
      return setCreatingProperty(false);
    }
    navigate(-1);
  }

  return (
    <PropertiesFormPropertiesOrganism
      initialData={{
        name: "",
        urlSlug: "",
        summary: "",
        description: "",
        visibleOnSite: false,
        gallery: [],
        location: null,
        priceRange: null,
        thumbnailPicture: null,
        roomsQuanity: null,
        collections: [],
        starRatings: null,
      }}
      formType="add"
      submitInProgress={creatingProperty}
      setSubmitInProgress={setCreatingProperty}
      onSubmitForm={addProperty}
    />
  );
};
