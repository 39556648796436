export enum LocalStorageKeys {
  ACCESS_TOKEN = "@ACCESS_TOKEN",
}

export class LocalStorage {
  static setItem(key: LocalStorageKeys, value: string) {
    window.localStorage.setItem(key, value);
  }

  static getItem(key: LocalStorageKeys) {
    return window.localStorage.getItem(key);
  }

  static removeItem(key: LocalStorageKeys) {
    window.localStorage.removeItem(key);
  }
}
