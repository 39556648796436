import React, { useContext, useEffect } from "react";
import { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";
import { AccountContext } from "../../contexts/account.contexts";
import { LoginFormEmailPasswordAuthsOrganism } from "../../organisms/auths/login-form-email-password.auths.organisms";

export const LoginAuthsPage: FunctionComponent = () => {
  const navigate = useNavigate();

  const accountContext = useContext(AccountContext);

  useEffect(() => {
    if (accountContext.isLoggedIn) {
      navigate("/");
    }
  }, [accountContext]);

  return (
    <div className="flex flex-1 bg-gray-200">
      <div className="container flex flex-1 mx-auto px-2 max-w-md">
        <div className="flex flex-1 items-center">
          <div className="flex flex-1 bg-white my-4 rounded-xl p-8">
            {!accountContext.isLoggedIn && (
              <LoginFormEmailPasswordAuthsOrganism />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
