import Net, { IBody } from "../net.http";
import { IResponse } from "../../utils/response.utils";
import { IShortUuidListSortable } from "../../validators/common/uuid.common.validations";
import { IPriceRange } from "../../validators/common/price-range.common.validators";
import { IDuration } from "../../validators/common/duration.common.validators";
import { IGroupSize } from "../../validators/common/group-size.common.validators";
import { IWhereToPlay } from "../../validators/common/where-to-play.common.validators";

export interface IPutActivitiesHttpUpdateByUuidData {
  readonly uuid: string;
  readonly name: string;
  readonly urlSlug: string;
  readonly description: string;
  readonly duration: IDuration;
  readonly groupSize: IGroupSize;
  readonly whereToPlay: IWhereToPlay;
  readonly visibleOnSite: boolean;
  readonly priceRange: IPriceRange;
  readonly uuidListGallery: IShortUuidListSortable;
  readonly uuidListHeroPictures: IShortUuidListSortable;
}

export interface IPutActivitiesHttpUpdateByUuidResponse {}

export class PutActivitiesHttp {
  async updateByUuid(
    data: IPutActivitiesHttpUpdateByUuidData
  ): Promise<IResponse<IPutActivitiesHttpUpdateByUuidResponse>> {
    const body: IBody = {
      name: data.name,
      urlSlug: data.urlSlug,
      duration: data.duration,
      groupSize: data.groupSize,
      whereToPlay: data.whereToPlay,
      priceRange: data.priceRange,
      visibleOnSite: data.visibleOnSite,
      uuidListGallery: data.uuidListGallery,
      uuidListHeroPictures: data.uuidListHeroPictures,
    };
    if (data.description && data.description.length > 0) {
      body.description = data.description;
    }

    const response =
      await Net.getInstance().put<IPutActivitiesHttpUpdateByUuidResponse>({
        path: `/activities/uuid/${data.uuid}`,
        body,
      });
    return response;
  }
}
