import { IEntertainmentsModel } from "../../interfaces/models/entertainments.models.interfaces";
import { IResponse } from "../../utils/response.utils";
import Net, { IQuery } from "../net.http";

export interface IGetEntertainmentsHttpData {
  readonly limit?: number;
  readonly offset?: number;
}

export interface IGetEntertainmentsHttpResult {
  readonly entertainments: Array<IEntertainmentsModel>;
}

export interface IGetEntertainmentsHttpGetByUuidData {
  readonly uuidEntertainment: string;
}

export interface IGetEntertainmentsHttpGetByUuidResult {
  readonly entertainment: IEntertainmentsModel;
}

export class GetEntertainmentsHttp {
  async getAll(
    data: IGetEntertainmentsHttpData
  ): Promise<IResponse<IGetEntertainmentsHttpResult>> {
    const query: IQuery = {};
    if (data.limit) query.limit = data.limit;
    if (data.offset) query.offset = data.offset;
    const response = await Net.getInstance().get<IGetEntertainmentsHttpResult>({
      path: "/entertainments",
      query,
    });
    return response;
  }

  async getByUuid(
    data: IGetEntertainmentsHttpGetByUuidData
  ): Promise<IResponse<IGetEntertainmentsHttpGetByUuidResult>> {
    const response =
      await Net.getInstance().get<IGetEntertainmentsHttpGetByUuidResult>({
        path: `/entertainments/uuid/${data.uuidEntertainment}`,
      });
    return response;
  }
}
