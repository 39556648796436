import React, { useContext } from "react";
import { FunctionComponent } from "react";
import { NavLink, Outlet } from "react-router-dom";
import { AccountContext } from "../contexts/account.contexts";

export const DashboardOrganism: FunctionComponent = () => {
  const accountContext = useContext(AccountContext);

  async function logout() {
    await accountContext?.logout();
  }

  return (
    <>
      <div className="fixed top-0 left-0 bottom-0 w-80 border-r flex flex-1 flex-col">
        <p className="text-2xl px-3 pt-4 pb-2 font-medium">TeamTrip</p>
        <ul className="flex flex-1 flex-col">
          {[
            {
              title: "Locations",
              href: "/locations",
            },
            {
              title: "Properties (Stays)",
              href: "/properties",
            },
            // {
            //   title: "Vloggers",
            //   href: "/vloggers",
            // },
            {
              title: "Activities (Games)",
              href: "/activities",
            },
            {
              title: "Entertainments",
              href: "/entertainments",
            },
            // {
            //   title: "Collections",
            //   href: "/collections",
            // },
            // {
            //   title: "Clients",
            //   href: "/clients",
            // },
            {
              title: "Invoice",
              href: "/invoice",
            },
          ].map((item) => {
            return (
              <li key={item.href}>
                <NavLink
                  to={item.href}
                  className={({ isActive }) => {
                    return `flex flex-1 relative my-1 mx-2 p-2 br-4 rounded-lg font-medium transparent hover:bg-neutral-300 ${
                      isActive ? "bg-sky-200 hover:bg-sky-200" : ""
                    }`;
                  }}
                >
                  {item.title}
                </NavLink>
              </li>
            );
          })}
        </ul>
        <ul className="flex flex-col mb-2">
          {[
            {
              title: "Profile",
              href: "/profile",
            },
          ].map((item) => {
            return (
              <li key={item.href}>
                <NavLink
                  to={item.href}
                  className={({ isActive }) => {
                    return `flex flex-1 relative my-1 mx-2 p-2 br-4 rounded-lg font-medium transparent hover:bg-neutral-300 ${
                      isActive ? "bg-sky-200 hover:bg-sky-200" : ""
                    }`;
                  }}
                >
                  {item.title}
                </NavLink>
              </li>
            );
          })}
          <li>
            <p
              className="flex flex-1 relative my-1 mx-2 p-2 br-4 rounded-lg font-medium transparent bg-red-200 cursor-pointer"
              onClick={logout}
            >
              Logout
            </p>
          </li>
        </ul>
      </div>
      <div className="ml-80">
        <Outlet />
      </div>
    </>
  );
};
