import React, { useContext, useEffect, useState } from "react";
import { FunctionComponent } from "react";
import { useLocation } from "react-router-dom";
import { NotificationContext } from "../../contexts/notification.contexts";
import { ContentContainer } from "../../elements/content-container.elements";
import { HeaderElement } from "../../elements/header.elements";
import { GetVloggersHttp } from "../../http/vloggers/get.vloggers.http";
import { IVloggersModel } from "../../interfaces/models/vloggers.models.interfaces";
import { VloggersListOrganism } from "../../organisms/vloggers/vloggers-list.vloggers.organisms";

export const IndexVloggersPage: FunctionComponent = () => {
  const { pathname } = useLocation();
  const errorContext = useContext(NotificationContext);

  const [fetchingVloggers, setFetchingVloggers] = useState<boolean>(false);
  const [vloggers, setVloggers] = useState<Array<IVloggersModel>>();

  useEffect(() => {
    fetchLocations();
  }, []);

  async function fetchLocations() {
    setFetchingVloggers(true);
    const response = await new GetVloggersHttp().getAll({
      offset: vloggers ? vloggers.length : 0,
    });
    if (response.error) {
      await errorContext.showError({
        message: response.errorData.message,
      });
      return setFetchingVloggers(false);
    }
    const tempLocations = (vloggers || []).concat(
      response.successData.vloggers
    );
    setVloggers(tempLocations);
    setFetchingVloggers(false);
  }

  return (
    <>
      <HeaderElement
        title="Vloggers"
        action={{
          title: "Add Vlogger",
          href: `${pathname}/add`,
        }}
      />
      <ContentContainer>
        <VloggersListOrganism
          loading={fetchingVloggers}
          vloggers={vloggers}
          setVloggers={setVloggers}
        />
      </ContentContainer>
    </>
  );
};
