import React, {
  createContext,
  FunctionComponent,
  ReactNode,
  useEffect,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import Net from "../http/net.http";
import { LocalStorage, LocalStorageKeys } from "../utils/local-storage.utils";

export interface IAccountContextProviderLoginData {
  accessToken: string;
  refreshToken: string;
  redirectOnLogin?: string;
}

export interface IAccountContextAccountState {
  name: string;
  email: string;
}

export interface IAccountContext {
  account: IAccountContextAccountState | null;
  isLoggedIn: boolean | null;
  updateAccount: (account: IAccountContextAccountState) => Promise<void>;
  login: (data: IAccountContextProviderLoginData) => Promise<void>;
  logout: () => Promise<void>;
}

export const AccountContext = createContext<IAccountContext>({
  account: null,
  isLoggedIn: null,
  updateAccount: async () => {},
  login: async () => {},
  logout: async () => {},
});

export interface IAccountContextProviderProps {
  children: ReactNode;
}

export const AccountContextProvider: FunctionComponent<
  IAccountContextProviderProps
> = ({ children }) => {
  const navigate = useNavigate();

  const [account, setAccount] = useState<IAccountContextAccountState | null>(
    null
  );
  const [isLoggedIn, setIsLoggedIn] = useState<boolean | null>(null);

  useEffect(() => {
    const accessToken = LocalStorage.getItem(LocalStorageKeys.ACCESS_TOKEN);
    if (accessToken) {
      _notifyLogin(accessToken);
    } else {
      setIsLoggedIn(false);
    }
  }, []);

  async function updateAccount(account: IAccountContextAccountState) {
    setAccount(account);
  }

  async function login(data: IAccountContextProviderLoginData) {
    LocalStorage.setItem(LocalStorageKeys.ACCESS_TOKEN, data.accessToken);
    _notifyLogin(data.accessToken);
    if (data.redirectOnLogin) {
      navigate(data.redirectOnLogin);
    }
  }

  function _notifyLogin(accessToken: string) {
    Net.getInstance().login(accessToken);
    setIsLoggedIn(true);
  }

  async function logout() {
    LocalStorage.removeItem(LocalStorageKeys.ACCESS_TOKEN);
    setIsLoggedIn(false);
    navigate("/auth/login");
  }

  return (
    <AccountContext.Provider
      value={{ account, isLoggedIn, updateAccount, login, logout }}
    >
      {children}
    </AccountContext.Provider>
  );
};
