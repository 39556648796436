import React, { useContext, useState } from "react";
import { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";
import { NotificationContext } from "../../contexts/notification.contexts";
import { PostAssetsHttp } from "../../http/assets/post.assets.http";
import { PostLocationHttp } from "../../http/locations/post.locations.http";
import {
  ILocationsFormData,
  LocationsFormLocationsOrganism,
} from "./location-form.locations.organisms";

export const AddLocationFormLocationsOrganism: FunctionComponent = () => {
  const errorContext = useContext(NotificationContext);
  const navigate = useNavigate();

  const [creatingLocation, setCreatingLocation] = useState<boolean>(false);

  async function addLocation(data: ILocationsFormData) {
    setCreatingLocation(true);
    const response = await new PostLocationHttp().create({
      name: data.name,
      urlSlug: data.urlSlug,
      description: data.description,
      visibleOnSite: data.visibleOnSite,
      uuidThumbnailPicture: data.thumbnailPicture?.uuid || null,
    });
    if (response.error) {
      await errorContext.showError({
        message: response.errorData.message,
      });
      return setCreatingLocation(false);
    }
    navigate(-1);
  }

  return (
    <LocationsFormLocationsOrganism
      initialData={{
        name: "",
        urlSlug: "",
        description: "",
        visibleOnSite: false,
        thumbnailPicture: null,
      }}
      formType="add"
      submitInProgress={creatingLocation}
      setSubmitInProgress={setCreatingLocation}
      onSubmitForm={addLocation}
    />
  );
};
