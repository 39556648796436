import { ILocationModel } from "../../interfaces/models/location.models.interfaces";
import { IResponse } from "../../utils/response.utils";
import Net, { IBody } from "../net.http";

export interface IPostLocationHttpCreateData {
  readonly name: string;
  readonly urlSlug: string;
  readonly visibleOnSite: boolean;
  readonly description: string | null;
  readonly uuidThumbnailPicture: string | null;
}

export interface IPostLocationHttpCreateResult {
  readonly location: ILocationModel;
}

export class PostLocationHttp {
  async create(
    data: IPostLocationHttpCreateData
  ): Promise<IResponse<IPostLocationHttpCreateResult>> {
    const body: IBody = {
      name: data.name,
      urlSlug: data.urlSlug,
      visibleOnSite: data.visibleOnSite,
      uuidThumbnailPicture: data.uuidThumbnailPicture,
    };
    if (data.description && data.description.length > 0) {
      body.description = data.description;
    }

    const response =
      await Net.getInstance().post<IPostLocationHttpCreateResult>({
        path: "/locations",
        body: body,
      });
    return response;
  }
}
