import Net from "../net.http";
import { IResponse } from "../../utils/response.utils";

export interface IDeletePropertiesHttpDeleteByUuidData {
  uuid: string;
}

export interface IDeletePropertiesHttpDeleteByUuidResponse {}

export class DeletePropertiesHttp {
  async deleteByUuid(
    data: IDeletePropertiesHttpDeleteByUuidData
  ): Promise<IResponse<IDeletePropertiesHttpDeleteByUuidResponse>> {
    const response =
      await Net.getInstance().delete<IDeletePropertiesHttpDeleteByUuidResponse>(
        {
          path: `/properties/uuid/${data.uuid}`,
        }
      );
    return response;
  }
}
