import React, { useContext, useEffect, useState } from "react";
import { FunctionComponent } from "react";
import { useLocation } from "react-router-dom";
import { NotificationContext } from "../../contexts/notification.contexts";
import { ContentContainer } from "../../elements/content-container.elements";
import { HeaderElement } from "../../elements/header.elements";
import { GetLocationHttp } from "../../http/locations/get.locations.http";
import { ILocationModel } from "../../interfaces/models/location.models.interfaces";
import { LocationListOrganism } from "../../organisms/locations/locations-list.locations.organisms";

export const IndexLocationsPage: FunctionComponent = () => {
  const { pathname } = useLocation();
  const errorContext = useContext(NotificationContext);

  const [fetchingLocations, setFetchingLocation] = useState<boolean>(false);
  const [locations, setLocations] = useState<Array<ILocationModel>>();

  useEffect(() => {
    fetchLocations();
  }, []);

  async function fetchLocations() {
    setFetchingLocation(true);
    const response = await new GetLocationHttp().getAll({
      offset: locations ? locations.length : 0,
    });
    if (response.error) {
      await errorContext.showError({
        message: response.errorData.message,
      });
      return setFetchingLocation(false);
    }
    const tempLocations = (locations || []).concat(
      response.successData.locations
    );
    setLocations(tempLocations);
    setFetchingLocation(false);
  }

  return (
    <>
      <HeaderElement
        title="Locations"
        action={{
          title: "Add Location",
          href: `${pathname}/add`,
        }}
      />
      <ContentContainer>
        <LocationListOrganism
          loading={fetchingLocations}
          locations={locations}
          setLocations={setLocations}
        />
      </ContentContainer>
    </>
  );
};
