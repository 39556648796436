import React, { useContext, useEffect, useState } from "react";
import { FunctionComponent } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { NotificationContext } from "../../contexts/notification.contexts";
import LoadingElement from "../../elements/loading.elements";
import { GetLocationHttp } from "../../http/locations/get.locations.http";
import { PutLocationsHttp } from "../../http/locations/put.locations.http";
import { ILocationModel } from "../../interfaces/models/location.models.interfaces";
import {
  ILocationsFormData,
  LocationsFormLocationsOrganism,
} from "./location-form.locations.organisms";

export const EditLocationFormLocationsOrganism: FunctionComponent = () => {
  const { uuidLocation } = useParams();
  const navigate = useNavigate();
  const errorContext = useContext(NotificationContext);

  const [gettingLocation, setGettingLocation] = useState<boolean>(true);
  const [updaingLocation, setUpdatingLocation] = useState<boolean>(false);
  const [initialLocation, setInitialLocation] = useState<ILocationModel>();

  useEffect(() => {
    if (!uuidLocation) {
      return;
    }
    fetchLocation(uuidLocation);
  }, [uuidLocation]);

  async function fetchLocation(localUuidLocation: string) {
    setGettingLocation(true);
    const response = await new GetLocationHttp().getByUuid({
      uuidLocation: localUuidLocation,
    });
    if (response.error) {
      await errorContext.showError({
        message: response.errorData.message,
      });
      return setGettingLocation(false);
    }
    setInitialLocation(response.successData.location);
    setGettingLocation(false);
  }

  async function updateLocation(data: ILocationsFormData) {
    setUpdatingLocation(true);
    if (!initialLocation) {
      await errorContext.showError({
        message: "Location not found",
      });
      return setUpdatingLocation(true);
    }

    const response = await new PutLocationsHttp().updateByUuid({
      uuid: initialLocation.uuid,
      name: data.name,
      urlSlug: data.urlSlug,
      description: data.description,
      visibleOnSite: data.visibleOnSite,
      uuidThumbnailPicture: data.thumbnailPicture?.uuid || null,
    });
    if (response.error) {
      await errorContext.showError({
        message: response.errorData.message,
      });
      return setUpdatingLocation(false);
    }

    setUpdatingLocation(false);
    navigate(-1);
  }

  if (!uuidLocation) {
    throw new Error("Couldn't find uuidLocation param");
  }

  if (gettingLocation) {
    return (
      <div className="flex flex-1 justify-center">
        <LoadingElement />
      </div>
    );
  }

  if (!initialLocation) {
    return <p className="w-full text-center">Location not found</p>;
  }

  return (
    <LocationsFormLocationsOrganism
      initialData={{
        name: initialLocation.name,
        urlSlug: initialLocation.urlSlug,
        description: initialLocation.description || "",
        visibleOnSite: initialLocation.visibleOnSite,
        thumbnailPicture: initialLocation.thumbnailPicture,
      }}
      formType="edit"
      submitInProgress={updaingLocation}
      setSubmitInProgress={setUpdatingLocation}
      onSubmitForm={updateLocation}
    />
  );
};
