import { FunctionComponent, useState } from "react";
import { Dialog } from "@headlessui/react";
import React from "react";
import LoadingElement from "./loading.elements";
import { IInvoiceData } from "../organisms/invoice/template.invoice.organisms";
import { GetPdfGeneratorHttp } from "../http/pdf-generator/get.pdf-generator.http";

export interface IGeneratePdfModalButtonElementProps {
  invoiceData: IInvoiceData;
  isIgst: boolean;
  filename: string;
}

export const GeneratePdfModalButtonElement: FunctionComponent<
  IGeneratePdfModalButtonElementProps
> = ({ invoiceData, isIgst, filename }) => {
  const [isOpen, setIsOpen] = useState(false);

  async function onClickGeneratePdfButton() {
    setIsOpen(true);

    try {
      const response = await new GetPdfGeneratorHttp().generate({
        url: getPreviewUrl(),
        filename,
      });
      console.log("isOpen", isOpen);
      console.log(response);
      if (response.error) {
        console.error(response.error);
        return;
      }
      window.open(response.successData.url, "_blank");
      setIsOpen(false);
    } catch (error) {
      console.error(error);
    }
  }

  async function onClickPreviewButton() {
    window.open(getPreviewUrl(), "_blank");
  }

  function getPreviewUrl() {
    const urlPrintView = `/invoice-print-view?invoiceData=${JSON.stringify(
      invoiceData
    )}&isIgst=${isIgst}`;
    // return `http://localhost:3000${urlPrintView}`;
    return `https://${window.location.hostname}${urlPrintView}`;
    // return `https://teamtrip-dashboard.ekaisoft.dev${urlPrintView}`;
  }

  return (
    <>
      <div className="flex flex-1 grid grid-cols-2 gap-4">
        <button
          onClick={onClickPreviewButton}
          className="rounded-md bg-blue-200 px-4 py-3 my-6 font-semibold"
        >
          Preview
        </button>
        <button
          onClick={onClickGeneratePdfButton}
          className="rounded-md bg-blue-200 px-4 py-3 my-6 font-semibold"
        >
          Generate PDF
        </button>
      </div>

      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        className="relative z-10"
      >
        <div className="fixed inset-0 z-20 bg-black/40 flex flex-1 items-center">
          <Dialog.Panel
            className={
              "container max-w-screen-sm mx-auto bg-white border rounded-xl p-12"
            }
          >
            <div className={"p-4"}>
              <LoadingElement />
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>
    </>
  );
};
