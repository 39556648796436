import { ICollectionsModel } from "../../interfaces/models/collections.models.interfaces";
import { IResponse } from "../../utils/response.utils";
import Net, { IBody } from "../net.http";

export interface IPostCollectionHttpCreateData {
  readonly name: string;
  readonly urlSlug: string;
  readonly visibleOnSite: boolean;
  readonly description: string | null;
  readonly uuidThumbnailPicture: string | null;
}

export interface IPostCollectionHttpCreateResult {
  readonly collection: ICollectionsModel;
}

export class PostCollectionHttp {
  async create(
    data: IPostCollectionHttpCreateData
  ): Promise<IResponse<IPostCollectionHttpCreateResult>> {
    const body: IBody = {
      name: data.name,
      urlSlug: data.urlSlug,
      visibleOnSite: data.visibleOnSite,
      uuidThumbnailPicture: data.uuidThumbnailPicture,
    };
    if (data.description && data.description.length > 0) {
      body.description = data.description;
    }

    const response =
      await Net.getInstance().post<IPostCollectionHttpCreateResult>({
        path: "/collections",
        body: body,
      });
    return response;
  }
}
