import React, { useContext, useEffect, useState } from "react";
import { FunctionComponent } from "react";
import { Link, useLocation } from "react-router-dom";
import { NotificationContext } from "../../contexts/notification.contexts";
import { ContentContainer } from "../../elements/content-container.elements";
import { HeaderElement } from "../../elements/header.elements";
import { GetEntertainmentsHttp } from "../../http/entertainments/get.entertainments.http";
import { IEntertainmentsModel } from "../../interfaces/models/entertainments.models.interfaces";
import { EntertainmentsListOrganism } from "../../organisms/entertainments/entertainment-list.entertainments.organisms";

export const IndexEntertainmentsPage: FunctionComponent = () => {
  const { pathname } = useLocation();
  const errorContext = useContext(NotificationContext);

  const [fetchingEntertainments, setFetchingEntertainments] =
    useState<boolean>(false);
  const [entertainments, setEntertainments] =
    useState<Array<IEntertainmentsModel>>();

  useEffect(() => {
    fetchEntertainments();
  }, []);

  async function fetchEntertainments() {
    setFetchingEntertainments(true);
    const response = await new GetEntertainmentsHttp().getAll({
      offset: entertainments ? entertainments.length : 0,
    });
    if (response.error) {
      await errorContext.showError({
        message: response.errorData.message,
      });
      return setFetchingEntertainments(false);
    }
    const tempEntertainments = (entertainments || []).concat(
      response.successData.entertainments
    );
    setEntertainments(tempEntertainments);
    setFetchingEntertainments(false);
  }

  return (
    <>
      <HeaderElement
        title="Entertainments"
        action={{
          title: "Add Entertainment",
          href: `${pathname}/add`,
        }}
      />
      <ContentContainer>
        <div className="w-full">
          <div className="mb-4">
            <Link to={`/entertainments-categories`}>
              <p className="p-4 border text-center rounded-md">Categories</p>
            </Link>
          </div>
          <EntertainmentsListOrganism
            loading={fetchingEntertainments}
            entertainments={entertainments}
            setEntertainments={setEntertainments}
          />
        </div>
      </ContentContainer>
    </>
  );
};
