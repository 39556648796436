import React, { useContext, useEffect, useState } from "react";
import { FunctionComponent } from "react";
import { useLocation } from "react-router-dom";
import { NotificationContext } from "../../contexts/notification.contexts";
import { ContentContainer } from "../../elements/content-container.elements";
import { HeaderElement } from "../../elements/header.elements";
import { GetCollectionsHttp } from "../../http/collections/get.collections.http";
import { ICollectionsModel } from "../../interfaces/models/collections.models.interfaces";
import { CollectionsListOrganism } from "../../organisms/collections/collections-list.collections.organisms";

export const IndexCollectionsPage: FunctionComponent = () => {
  const { pathname } = useLocation();
  const errorContext = useContext(NotificationContext);

  const [fetchingCollections, setFetchingCollections] =
    useState<boolean>(false);
  const [collections, setCollections] = useState<Array<ICollectionsModel>>();

  useEffect(() => {
    fetchCollections();
  }, []);

  async function fetchCollections() {
    setFetchingCollections(true);
    const response = await new GetCollectionsHttp().getAll({
      offset: collections ? collections.length : 0,
    });
    if (response.error) {
      await errorContext.showError({
        message: response.errorData.message,
      });
      return setFetchingCollections(false);
    }
    const tempCollection = (collections || []).concat(
      response.successData.collections
    );
    setCollections(tempCollection);
    setFetchingCollections(false);
  }

  return (
    <>
      <HeaderElement
        title="Collections"
        action={{
          title: "Add Collection",
          href: `${pathname}/add`,
        }}
      />
      <ContentContainer>
        <CollectionsListOrganism
          loading={fetchingCollections}
          collections={collections}
          setCollections={setCollections}
        />
      </ContentContainer>
    </>
  );
};
