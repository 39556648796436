import Net, { IBody } from "../net.http";
import { IResponse } from "../../utils/response.utils";

export interface IPutLocationsHttpUpdateByUuidData {
  readonly uuid: string;
  readonly name: string;
  readonly urlSlug: string;
  readonly visibleOnSite: boolean;
  readonly description: string | null;
  readonly uuidThumbnailPicture: string | null;
}

export interface IPutLocationsHttpUpdateByUuidResponse {}

export class PutLocationsHttp {
  async updateByUuid(
    data: IPutLocationsHttpUpdateByUuidData
  ): Promise<IResponse<IPutLocationsHttpUpdateByUuidResponse>> {
    const body: IBody = {
      name: data.name,
      urlSlug: data.urlSlug,
      visibleOnSite: data.visibleOnSite,
      uuidThumbnailPicture: data.uuidThumbnailPicture,
    };
    if (data.description && data.description.length > 0) {
      body.description = data.description;
    }

    const response =
      await Net.getInstance().put<IPutLocationsHttpUpdateByUuidResponse>({
        path: `/locations/uuid/${data.uuid}`,
        body,
      });
    return response;
  }
}
