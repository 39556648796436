import { IActivityModel } from "../../interfaces/models/activities.models.interfaces";
import { IResponse } from "../../utils/response.utils";
import { IPriceRange } from "../../validators/common/price-range.common.validators";
import { IShortUuidListSortable } from "../../validators/common/uuid.common.validations";
import Net, { IBody } from "../net.http";

export interface IPostVloggersHttpCreateData {
  readonly name: string;
  readonly urlSlug: string;
  readonly description: string;
  readonly priceRange: IPriceRange;
  readonly visibleOnSite: boolean;
  readonly uuidProfilePicture: string | null;
  readonly uuidListGallery: IShortUuidListSortable;
}

export interface IPostVloggersHttpCreateResult {
  readonly vloggers: IActivityModel;
}

export class PostVloggersHttp {
  async create(
    data: IPostVloggersHttpCreateData
  ): Promise<IResponse<IPostVloggersHttpCreateResult>> {
    const body: IBody = {
      name: data.name,
      urlSlug: data.urlSlug,
      priceRange: data.priceRange,
      visibleOnSite: data.visibleOnSite,
      uuidListGallery: data.uuidListGallery,
      uuidProfilePicture: data.uuidProfilePicture,
    };
    if (data.description && data.description.length > 0) {
      body.description = data.description;
    }

    const response =
      await Net.getInstance().post<IPostVloggersHttpCreateResult>({
        path: "/vloggers",
        body: body,
      });
    return response;
  }
}
