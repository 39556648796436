import { IActivityModel } from "../../interfaces/models/activities.models.interfaces";
import { IResponse } from "../../utils/response.utils";
import { IDuration } from "../../validators/common/duration.common.validators";
import { IGroupSize } from "../../validators/common/group-size.common.validators";
import { IPriceRange } from "../../validators/common/price-range.common.validators";
import { IShortUuidListSortable } from "../../validators/common/uuid.common.validations";
import { IWhereToPlay } from "../../validators/common/where-to-play.common.validators";
import Net, { IBody } from "../net.http";

export interface IPostActivitiesHttpCreateData {
  readonly name: string;
  readonly urlSlug: string;
  readonly description: string;
  readonly duration: IDuration;
  readonly groupSize: IGroupSize;
  readonly whereToPlay: IWhereToPlay;
  readonly priceRange: IPriceRange;
  readonly visibleOnSite: boolean;
  readonly uuidListGallery: IShortUuidListSortable;
  readonly uuidListHeroPictures: IShortUuidListSortable;
}

export interface IPostActivitiesHttpCreateResult {
  readonly activity: IActivityModel;
}

export class PostActivitiesHttp {
  async create(
    data: IPostActivitiesHttpCreateData
  ): Promise<IResponse<IPostActivitiesHttpCreateResult>> {
    const body: IBody = {
      name: data.name,
      urlSlug: data.urlSlug,
      duration: data.duration,
      groupSize: data.groupSize,
      whereToPlay: data.whereToPlay,
      priceRange: data.priceRange,
      visibleOnSite: data.visibleOnSite,
      uuidListGallery: data.uuidListGallery,
      uuidListHeroPictures: data.uuidListHeroPictures,
    };
    if (data.description && data.description.length > 0) {
      body.description = data.description;
    }

    const response =
      await Net.getInstance().post<IPostActivitiesHttpCreateResult>({
        path: "/activities",
        body: body,
      });
    return response;
  }
}
