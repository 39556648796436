import Net from "../net.http";
import { IResponse } from "../../utils/response.utils";

export interface IDeleteVloggersHttpDeleteByUuidData {
  uuid: string;
}

export interface IDeleteVloggersHttpDeleteByUuidResponse {}

export class DeleteVloggersHttp {
  async deleteByUuid(
    data: IDeleteVloggersHttpDeleteByUuidData
  ): Promise<IResponse<IDeleteVloggersHttpDeleteByUuidResponse>> {
    const response =
      await Net.getInstance().delete<IDeleteVloggersHttpDeleteByUuidResponse>({
        path: `/vloggers/uuid/${data.uuid}`,
      });
    return response;
  }
}
