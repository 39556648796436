import React from "react";
import { FunctionComponent } from "react";
import { ContentContainer } from "../../elements/content-container.elements";
import { HeaderElement } from "../../elements/header.elements";
import { EditPropertiesFormPropertiesOrganism } from "../../organisms/properties/edit-properties-form.properties.organisms";

export const EditPropertiesPage: FunctionComponent = () => {
  return (
    <>
      <HeaderElement title="Edit Property" />
      <ContentContainer>
        <EditPropertiesFormPropertiesOrganism />
      </ContentContainer>
    </>
  );
};
