import Net from "../net.http";
import { IResponse } from "../../utils/response.utils";

export interface IDeleteLocationsHttpDeleteByUuidData {
  uuid: string;
}

export interface IDeleteLocationsHttpDeleteByUuidResponse {}

export class DeleteLocationsHttp {
  async deleteByUuid(
    data: IDeleteLocationsHttpDeleteByUuidData
  ): Promise<IResponse<IDeleteLocationsHttpDeleteByUuidResponse>> {
    const response =
      await Net.getInstance().delete<IDeleteLocationsHttpDeleteByUuidResponse>({
        path: `/locations/uuid/${data.uuid}`,
      });
    return response;
  }
}
