import React, { FunctionComponent, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import LoadingElement from "../../elements/loading.elements";

import { ReactComponent as EditSvg } from "../../assets/svgs/edit.svg";
import { IPropertyModel } from "../../interfaces/models/properties.models.interfaces";
import { DeleteModalButtonElement } from "../../elements/delete-modal-button.elements";
import { NotificationContext } from "../../contexts/notification.contexts";
import { DeletePropertiesHttp } from "../../http/properties/delete.properties.http";
import { PutPropertiesHttp } from "../../http/properties/put.properties.http";

export interface IPropertyListOrganismProps {
  loading: boolean;
  properties?: Array<IPropertyModel>;
  setProperties: (properties: Array<IPropertyModel>) => void;
}

export const PropertiesListOrganism: FunctionComponent<
  IPropertyListOrganismProps
> = ({ loading, properties, setProperties }) => {
  const notificationContext = useContext(NotificationContext);
  const { pathname } = useLocation();

  if (!properties || loading) {
    return (
      <div className="flex flex-1 justify-center">
        <LoadingElement />
      </div>
    );
  }

  async function bestSellingToggle(
    property: IPropertyModel
  ): Promise<{ success: boolean }> {
    if (!properties) throw new Error("Properties cannot be undefined");
    const response = await new PutPropertiesHttp().updateByUuidBestSelling({
      uuid: property.uuid,
      bestSelling: property.bestSelling === null,
    });
    if (response.error) {
      await notificationContext.showError({
        message: response.errorData.message,
      });
      return {
        success: false,
      };
    }
    const propertyIndex = properties.findIndex((i) => i.uuid === property.uuid);
    console.log(propertyIndex);
    if (property.bestSelling === null) {
      properties[propertyIndex].bestSelling = Date.now();
    } else {
      properties[propertyIndex].bestSelling = null;
    }

    setProperties([...properties]);
    return {
      success: true,
    };
  }

  async function deleteLocation(
    uuidPropertyToDelete: string
  ): Promise<{ success: boolean }> {
    if (!properties) throw new Error("Properties cannot be undefined");
    const response = await new DeletePropertiesHttp().deleteByUuid({
      uuid: uuidPropertyToDelete,
    });
    if (response.error) {
      await notificationContext.showError({
        message: response.errorData.message,
      });
      return {
        success: false,
      };
    }
    setProperties(
      properties.filter((loc) => loc.uuid !== uuidPropertyToDelete)
    );
    return {
      success: true,
    };
  }

  if (properties.length === 0) {
    return <p className="w-full text-center">No Properties found</p>;
  }

  return (
    <ul className="flex flex-1 flex-col">
      {properties.map((property) => {
        return (
          <li
            key={property.uuid}
            className="flex flex-1 flex-col border rounded-xl p-4 mb-4 grid grid-cols-5 gap-6"
          >
            <div className="aspect-video overflow-hidden bg-neutral-200 col-span-2 rounded-md">
              {property.thumbnailPicture && (
                <img src={property.thumbnailPicture.url} alt="" />
              )}
            </div>
            <div className="flex flex-1 flex-row col-span-3">
              <div className="flex flex-1 flex-col">
                <div>
                  <button
                    onClick={() => bestSellingToggle(property)}
                    className={`border rounded-md px-3 py-1 flex items-center ${
                      property.bestSelling === null
                        ? ""
                        : "border-sky-400 bg-sky-200"
                    }`}
                  >
                    Best Selling
                  </button>
                </div>
                <h2 className="text-2xl font-semibold my-2">{property.name}</h2>
                <p>{property.summary}</p>
              </div>
              <ul className="flex flex-col pl-4">
                <Link to={`${pathname}/${property.uuid}/edit`}>
                  <EditSvg className="w-8 h-8 fill-neutral-600 hover:fill-blue-600" />
                </Link>
                <div className="mt-6">
                  <DeleteModalButtonElement
                    uuid={property.uuid}
                    itemToDelete="Property"
                    onClickDelete={deleteLocation}
                  />
                </div>
              </ul>
            </div>
          </li>
        );
      })}
    </ul>
  );
};
