import Net from "../net.http";
import { IResponse } from "../../utils/response.utils";

export interface IDeleteActivitiesHttpDeleteByUuidData {
  readonly uuid: string;
}

export interface IDeleteActivitiesHttpDeleteByUuidResponse {}

export class DeleteActivitiesHttp {
  async deleteByUuid(
    data: IDeleteActivitiesHttpDeleteByUuidData
  ): Promise<IResponse<IDeleteActivitiesHttpDeleteByUuidResponse>> {
    const response =
      await Net.getInstance().delete<IDeleteActivitiesHttpDeleteByUuidResponse>(
        {
          path: `/activities/uuid/${data.uuid}`,
        }
      );
    return response;
  }
}
