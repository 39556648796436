import { LoginModes } from "../../constants/login-modes.constants";
import { IResponse } from "../../utils/response.utils";
import Net from "../net.http";

export interface IPostAuthHttpData {
  mode: LoginModes;
  email: string;
  password: string;
}

export interface IPostAuthHttpResult {
  accessToken: string;
  refreshToken: string;
}

export class PostAuthHttp {
  async login(
    data: IPostAuthHttpData
  ): Promise<IResponse<IPostAuthHttpResult>> {
    const response = await Net.getInstance().post<IPostAuthHttpResult>({
      path: "/auths/login",
      body: {
        mode: data.mode,
        email: data.email,
        password: data.password,
      },
    });
    return response;
  }
}
