import React, { FunctionComponent, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import LoadingElement from "../../elements/loading.elements";

import { ReactComponent as EditSvg } from "../../assets/svgs/edit.svg";
import { DeleteModalButtonElement } from "../../elements/delete-modal-button.elements";
import { NotificationContext } from "../../contexts/notification.contexts";
import { IEntertainmentsCategoryModel } from "../../interfaces/models/entertainments-categories.models.interfaces";
import { DeleteEntertainmentsCategoriesHttp } from "../../http/entertainments-categories/delete.entertainments-categories.http";

export interface IEntertainmentsCategoriesListOrganismProps {
  loading: boolean;
  entertainmentsCategories?: Array<IEntertainmentsCategoryModel>;
  setEntertainmentsCategories: (
    entertainments: Array<IEntertainmentsCategoryModel>
  ) => void;
}

export const EntertainmentsCategoriesListOrganism: FunctionComponent<
  IEntertainmentsCategoriesListOrganismProps
> = ({ loading, entertainmentsCategories, setEntertainmentsCategories }) => {
  const notificationContext = useContext(NotificationContext);
  const { pathname } = useLocation();

  if (!entertainmentsCategories || loading) {
    return (
      <div className="flex flex-1 justify-center">
        <LoadingElement />
      </div>
    );
  }

  async function deleteEntertainmentCategory(
    uuidEntertainmentsCategoryToDelete: string
  ): Promise<{ success: boolean }> {
    if (!entertainmentsCategories)
      throw new Error("entertainments Category cannot be undefined");
    const response =
      await new DeleteEntertainmentsCategoriesHttp().deleteByUuid({
        uuid: uuidEntertainmentsCategoryToDelete,
      });
    if (response.error) {
      await notificationContext.showError({
        message: response.errorData.message,
      });
      return {
        success: false,
      };
    }
    setEntertainmentsCategories(
      entertainmentsCategories.filter(
        (loc) => loc.uuid !== uuidEntertainmentsCategoryToDelete
      )
    );
    return {
      success: true,
    };
  }

  if (entertainmentsCategories.length === 0) {
    return (
      <p className="w-full text-center">No Entertainment Categories found</p>
    );
  }

  return (
    <ul className="flex flex-1 flex-col">
      {entertainmentsCategories.map((category) => {
        return (
          <li
            key={category.uuid}
            className="flex flex-1 flex-col border rounded-xl p-4 mb-4"
          >
            <div className="flex flex-1 flex-row">
              <div className="flex flex-1 flex-col">
                <h2 className="text-2xl font-semibold mb-4">{category.name}</h2>
                <p>{category.description}</p>
              </div>
              <ul className="flex flex-col pl-4">
                <Link to={`${pathname}/${category.uuid}/edit`}>
                  <EditSvg className="w-8 h-8 fill-neutral-600 hover:fill-blue-600" />
                </Link>
                {/* <div className="mt-6">
                  <DeleteModalButtonElement
                    uuid={category.uuid}
                    itemToDelete="Entertainment Category"
                    onClickDelete={deleteEntertainmentCategory}
                  />
                </div> */}
              </ul>
            </div>
          </li>
        );
      })}
    </ul>
  );
};
