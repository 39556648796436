import React, { useContext, useEffect, useState } from "react";
import { FunctionComponent } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { NotificationContext } from "../../contexts/notification.contexts";
import LoadingElement from "../../elements/loading.elements";
import { GetVloggersHttp } from "../../http/vloggers/get.vloggers.http";
import { PutVloggersHttp } from "../../http/vloggers/put.vloggers.http";
import { IVloggersModel } from "../../interfaces/models/vloggers.models.interfaces";
import {
  IVloggersFormData,
  VloggerFormVloggersOrganism,
} from "./vlogger-form.vloggers.organisms";

export const EditVloggerFormVloggersOrganism: FunctionComponent = () => {
  const { uuidVlogger } = useParams();
  const navigate = useNavigate();
  const errorContext = useContext(NotificationContext);

  const [gettingVlogger, setGettingVlogger] = useState<boolean>(true);
  const [updaingVlogger, setUpdatingVlogger] = useState<boolean>(false);
  const [initialVlogger, setInitialVlogger] = useState<IVloggersModel>();

  useEffect(() => {
    if (!uuidVlogger) {
      return;
    }
    fetchVlogger(uuidVlogger);
  }, [uuidVlogger]);

  async function fetchVlogger(localUuidVlogger: string) {
    setGettingVlogger(true);
    const response = await new GetVloggersHttp().getByUuid({
      uuidVlogger: localUuidVlogger,
    });
    if (response.error) {
      await errorContext.showError({
        message: response.errorData.message,
      });
      return setGettingVlogger(false);
    }
    setInitialVlogger(response.successData.vlogger);
    setGettingVlogger(false);
  }

  async function updateVlogger(data: IVloggersFormData) {
    setUpdatingVlogger(true);
    if (!initialVlogger) {
      throw new Error("Initial vlogger not found");
    }

    console.log(`data.visibleOnSite ${data.visibleOnSite}`);

    const response = await new PutVloggersHttp().updateByUuid({
      uuid: initialVlogger.uuid,
      name: data.name,
      urlSlug: data.urlSlug,
      priceRange: data.priceRange,
      description: data.description,
      uuidListGallery: data.gallery.map((i) => ({
        uuid: i.uuid,
        index: i.index,
      })),
      uuidProfilePicture: data.profilePicture?.uuid || null,
      visibleOnSite: data.visibleOnSite,
    });
    if (response.error) {
      await errorContext.showError({
        message: response.errorData.message,
      });
      return setGettingVlogger(false);
    }

    setUpdatingVlogger(false);
    navigate(-1);
  }

  if (!uuidVlogger) {
    throw new Error("Couldn't find uuidVlogger param");
  }

  if (gettingVlogger) {
    return (
      <div className="flex flex-1 justify-center">
        <LoadingElement />
      </div>
    );
  }

  if (!initialVlogger) {
    return <p className="w-full text-center">Vlogger not found</p>;
  }

  return (
    <VloggerFormVloggersOrganism
      initialData={{
        name: initialVlogger.name,
        urlSlug: initialVlogger.urlSlug,
        priceRange: initialVlogger.priceRange,
        description: initialVlogger.description,
        visibleOnSite: initialVlogger.visibleOnSite,
        profilePicture: initialVlogger.profilePicture,
        gallery: initialVlogger.gallery,
      }}
      formType="edit"
      submitInProgress={updaingVlogger}
      setSubmitInProgress={setUpdatingVlogger}
      onSubmitForm={updateVlogger}
    />
  );
};
