import { Listbox } from "@headlessui/react";
import React, { FunctionComponent } from "react";
import {
  DurationTypes,
  DurationTypesString,
  DurationTypesValues,
} from "../constants/duration-types.constants";

export interface IDurationTypeDropdownMoleculeProps {
  selected: DurationTypes | null;
  onChange: (quantityType: DurationTypes) => void;
}

const ListItem: FunctionComponent<{
  quantityType?: DurationTypes;
}> = ({ quantityType }) => {
  return (
    <div className="flex flex-1 items-center h-8">
      {quantityType !== undefined ? (
        <p className="text-center w-full">
          {DurationTypesString[quantityType]}
        </p>
      ) : (
        <p className="font-semibold text-center w-full">Select Type</p>
      )}
    </div>
  );
};

export const DurationTypeDropdownMolecule: FunctionComponent<
  IDurationTypeDropdownMoleculeProps
> = ({ selected: selected, onChange: onChange }) => {
  return (
    <div className="relative flex flex-1 mt-1">
      <Listbox value={selected} onChange={onChange}>
        <Listbox.Button
          className={"flex flex-1 border rounded-md items-center px-2"}
        >
          {<ListItem quantityType={selected || undefined} />}
        </Listbox.Button>
        <Listbox.Options className="flex flex-1 flex-col bg-white border rounded-md mt-2 absolute bottom-12 left-0 right-0 max-h-[60vh] overflow-auto">
          {DurationTypesValues.map((quantityType) => (
            <Listbox.Option
              key={quantityType}
              value={quantityType}
              className="flex flex-1 px-4 py-2 cursor-pointer"
            >
              <ListItem quantityType={quantityType} />
            </Listbox.Option>
          ))}
        </Listbox.Options>
      </Listbox>
    </div>
  );
};
