import { Listbox } from "@headlessui/react";
import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from "react";
import { NotificationContext } from "../contexts/notification.contexts";
import LoadingElement from "../elements/loading.elements";
import { GetCollectionsHttp } from "../http/collections/get.collections.http";
import { ICollectionsModel } from "../interfaces/models/collections.models.interfaces";

import { ReactComponent as CloseSvg } from "../assets/svgs/close.svg";

export interface ICollectionDropdownMoleculeProps {
  selectedCollections: Array<ICollectionsModel>;
  onChangeCollections: (collections: Array<ICollectionsModel>) => void;
}

const ListItem: FunctionComponent<{
  collection?: ICollectionsModel;
  areSelectedCollections: boolean;
  removeItem?: (item: ICollectionsModel) => void;
}> = ({ collection, removeItem, areSelectedCollections }) => {
  return (
    <div
      className={`flex ${
        areSelectedCollections ? "h-12" : "flex-1 h-8"
      } items-center`}
    >
      {collection !== undefined ? (
        <div
          className={`flex items-center ${
            areSelectedCollections ? "bg-neutral-200 rounded-md m-2 pr-2" : ""
          }`}
        >
          <div className="h-8 aspect-video overflow-hidden bg-neutral-200 rounded-md mr-2">
            {collection.thumbnailPicture && (
              <img src={collection.thumbnailPicture.url} alt="" />
            )}
          </div>
          <p>{collection.name}</p>
          {areSelectedCollections && (
            <div
              onClick={(ev) => {
                if (areSelectedCollections && removeItem && collection) {
                  ev.preventDefault();
                  ev.stopPropagation();
                  removeItem(collection);
                }
              }}
            >
              <CloseSvg className="w-6 h-6 p-1 ml-2" />
            </div>
          )}
        </div>
      ) : (
        <p className="font-semibold text-center w-full px-2">
          Select Collections
        </p>
      )}
    </div>
  );
};

const CollectionsList: FunctionComponent<{
  selectedCollections: Array<ICollectionsModel>;
}> = ({ selectedCollections }) => {
  const notificationContext = useContext(NotificationContext);
  const [gettingCollections, setGettingCollections] = useState<boolean>(true);
  const [collections, setCollections] = useState<Array<ICollectionsModel>>([]);

  useEffect(() => {
    getCollections();
  }, []);

  async function getCollections() {
    setGettingCollections(true);
    const response = await new GetCollectionsHttp().getAll({});
    if (response.error) {
      await notificationContext.showError({
        message: response.errorData.message,
      });
      return setGettingCollections(false);
    }
    setCollections(response.successData.collections);
    setGettingCollections(false);
  }

  if (gettingCollections) {
    return (
      <div className="flex flex-1 justify-center py-2">
        <LoadingElement />
      </div>
    );
  }

  const collectionsToShow = collections.filter(
    (i) => selectedCollections.findIndex((o) => o.uuid === i.uuid) === -1
  );

  return (
    <>
      {collectionsToShow.length > 0 ? (
        collectionsToShow.map((collection) => (
          <Listbox.Option
            key={collection.uuid}
            value={collection}
            className="flex flex-1 px-2 py-2 cursor-pointer"
          >
            <ListItem collection={collection} areSelectedCollections={false} />
          </Listbox.Option>
        ))
      ) : (
        <div className="h-12 flex items-center">
          <p className="px-4">No collections</p>
        </div>
      )}
    </>
  );
};

export const CollectionsDropdownMolecule: FunctionComponent<
  ICollectionDropdownMoleculeProps
> = ({ selectedCollections, onChangeCollections }) => {
  function removeItem(item: ICollectionsModel) {
    onChangeCollections(
      selectedCollections.filter((i) => i.uuid !== item.uuid)
    );
  }

  return (
    <div className="relative flex flex-1 mt-1">
      <Listbox
        value={selectedCollections}
        onChange={onChangeCollections}
        multiple={true}
      >
        <Listbox.Button className={"flex flex-1 border rounded-md"}>
          {selectedCollections.length > 0 ? (
            <>
              {selectedCollections.map((collection) => {
                return (
                  <ListItem
                    key={collection.uuid}
                    collection={collection}
                    removeItem={removeItem}
                    areSelectedCollections={true}
                  />
                );
              })}
            </>
          ) : (
            <>
              <ListItem collection={undefined} areSelectedCollections={true} />
            </>
          )}
        </Listbox.Button>
        <Listbox.Options className="flex flex-1 flex-col bg-white border rounded-md mt-2 absolute bottom-14 left-0 right-0 max-h-[60vh] overflow-auto">
          <CollectionsList selectedCollections={selectedCollections} />
        </Listbox.Options>
      </Listbox>
    </div>
  );
};
