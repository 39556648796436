import * as yup from "yup";
import {
  PriceRangeMaxCommonValidator,
  PriceRangeMinCommonValidator,
} from "./common/price-range.common.validators";
import { UrlSlugCommonValidator } from "./common/url-slug.common.validators";

export const PropertiesFormSchema = yup
  .object({
    name: yup.string().required("Required"),
    summary: yup.string().required("Required"),
    description: yup.string(),
    location: yup.object({}).nullable().required("Required"),
    urlSlug: UrlSlugCommonValidator.required("Required"),
    priceRangeMin: PriceRangeMinCommonValidator,
    priceRangeMax: PriceRangeMaxCommonValidator,
  })
  .required();
