import React, { useState } from "react";
import { FunctionComponent } from "react";
import { Formik, Field, Form } from "formik";
import LoadingElement from "../../elements/loading.elements";
import { Switch } from "@headlessui/react";
import { EntertainmentsCategoryFormSchema } from "../../validators/entertainments-categories-form.validation";

export interface IEntertainmentsCategoryFormData {
  name: string;
  urlSlug: string;
  description: string;
  visibleOnSite: boolean;
}

interface IEntertainmentsCategoryFormDataInternal
  extends IEntertainmentsCategoryFormData {}

export interface IFormEntertainmentsCategoryOrganismProps {
  initialData: IEntertainmentsCategoryFormData;
  submitInProgress: boolean;
  setSubmitInProgress: (state: boolean) => void;
  formType: "add" | "edit";
  onSubmitForm: (data: IEntertainmentsCategoryFormData) => Promise<void>;
}

export const FormEntertainmentsCategoryOrganism: FunctionComponent<
  IFormEntertainmentsCategoryOrganismProps
> = ({
  submitInProgress,
  setSubmitInProgress,
  formType,
  initialData,
  onSubmitForm,
}) => {
  const [visibleOnSite, setVisibleOnSite] = useState(initialData.visibleOnSite);

  return (
    <Formik<IEntertainmentsCategoryFormDataInternal>
      initialValues={{
        name: initialData.name,
        urlSlug: initialData.urlSlug,
        description: initialData.description,
        visibleOnSite: initialData.visibleOnSite,
      }}
      validationSchema={EntertainmentsCategoryFormSchema}
      onSubmit={async (formData) => {
        setSubmitInProgress(true);
        await onSubmitForm({
          ...formData,
          visibleOnSite,
        });
      }}
    >
      {({ errors, touched, setFieldValue }) => {
        return (
          <Form className="flex flex-1 flex-col">
            <label className="mt-4">
              URL Slug{" "}
              {errors.urlSlug && touched.urlSlug ? (
                <span className="mt-2 text-red-600 text-base font-normal">
                  ({errors.urlSlug})
                </span>
              ) : null}
            </label>
            <Field
              name="urlSlug"
              type="text"
              placeholder="URL Slug"
              className="flex border border-neutral-200 rounded-lg px-4 py-2 mt-1"
            />

            <label className="mt-4">
              Name{" "}
              {errors.name && touched.name ? (
                <span className="mt-2 text-red-600 text-base font-normal">
                  ({errors.name})
                </span>
              ) : null}
            </label>
            <Field
              name="name"
              type="text"
              placeholder="Name"
              className="flex border rounded-lg px-4 py-2 mt-1"
            />

            <label className="mt-4">
              Description{" "}
              {errors.description && touched.description ? (
                <span className="mt-2 text-red-600 text-base font-normal">
                  ({errors.description})
                </span>
              ) : null}
            </label>
            <Field
              name="description"
              as="textarea"
              placeholder="Description"
              className="flex border rounded-lg px-4 py-2 mt-1"
            />

            <label className="mt-4">Visible on site</label>
            <div className="flex flex-1 border border-neutral-200 rounded-lg px-4 py-4 mt-1 flex-row items-center">
              <Switch
                checked={visibleOnSite}
                onChange={setVisibleOnSite}
                className={`${visibleOnSite ? "bg-sky-300" : "bg-gray-200"}
          relative inline-flex h-[38px] w-[74px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
              >
                <span
                  aria-hidden="true"
                  className={`${
                    visibleOnSite ? "translate-x-9" : "translate-x-0"
                  }
            pointer-events-none inline-block h-[34px] w-[34px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                />
              </Switch>
              <p className="pl-4">
                Item will be shown on website only when the toggle is on
              </p>
            </div>

            <button
              type="submit"
              className="border rounded-lg px-4 py-2 mt-6 bg-sky-200 border-sky-200 font-semibold text-center cursor-pointer"
            >
              {submitInProgress ? (
                <LoadingElement />
              ) : (
                <>{formType === "add" ? "Add Category" : "Save Changes"}</>
              )}
            </button>
          </Form>
        );
      }}
    </Formik>
  );
};
