import React, { useContext, useEffect, useState } from "react";
import { FunctionComponent } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { NotificationContext } from "../../contexts/notification.contexts";
import LoadingElement from "../../elements/loading.elements";
import { GetPropertiesHttp } from "../../http/properties/get.properties.http";
import { PutPropertiesHttp } from "../../http/properties/put.properties.http";
import { IPropertyModel } from "../../interfaces/models/properties.models.interfaces";
import {
  IPropertiesFormData,
  PropertiesFormPropertiesOrganism,
} from "./properties-form.properties.organisms";

export const EditPropertiesFormPropertiesOrganism: FunctionComponent = () => {
  const { uuidProperty } = useParams();
  const navigate = useNavigate();
  const errorContext = useContext(NotificationContext);

  const [gettingProperty, setGettingProperty] = useState<boolean>(true);
  const [updaingProperty, setUpdatingProperty] = useState<boolean>(false);
  const [initialProperty, setInitialProperty] = useState<IPropertyModel>();

  useEffect(() => {
    if (!uuidProperty) {
      return;
    }
    fetchProperty(uuidProperty);
  }, [uuidProperty]);

  async function fetchProperty(localUuidProperty: string) {
    setGettingProperty(true);
    const response = await new GetPropertiesHttp().getByUuid({
      uuidProperty: localUuidProperty,
    });
    if (response.error) {
      await errorContext.showError({
        message: response.errorData.message,
      });
      return setGettingProperty(false);
    }
    setInitialProperty(response.successData.property);
    setGettingProperty(false);
  }

  async function updateProperty(data: IPropertiesFormData) {
    setUpdatingProperty(true);
    if (!initialProperty) {
      throw new Error("Initial property not found");
    }
    if (!data.location) {
      throw new Error("Location is required");
    }
    const response = await new PutPropertiesHttp().updateByUuid({
      uuid: initialProperty.uuid,
      name: data.name,
      urlSlug: data.urlSlug,
      priceRange: data.priceRange,
      summary: data.summary,
      description: data.description,
      visibleOnSite: data.visibleOnSite,
      uuidLocation: data.location.uuid,
      starRatings: data.starRatings,
      uuidListGallery: data.gallery.map((i) => ({
        uuid: i.uuid,
        index: i.index,
      })),
      roomsQuanity: data.roomsQuanity,
      uuidThumbnailPicture: data.thumbnailPicture?.uuid || null,
      uuidListCollection: data.collections.map((item, index) => {
        return {
          uuid: item.uuid,
          index: index,
        };
      }),
    });
    if (response.error) {
      await errorContext.showError({
        message: response.errorData.message,
      });
      return setGettingProperty(false);
    }

    setUpdatingProperty(false);
    navigate(-1);
  }

  if (!uuidProperty) {
    throw new Error("Couldn't find uuidProperty param");
  }

  if (gettingProperty) {
    return (
      <div className="flex flex-1 justify-center">
        <LoadingElement />
      </div>
    );
  }

  if (!initialProperty) {
    return <p className="w-full text-center">Property not found</p>;
  }

  return (
    <PropertiesFormPropertiesOrganism
      initialData={{
        name: initialProperty.name,
        summary: initialProperty.summary || "",
        urlSlug: initialProperty.urlSlug || "",
        description: initialProperty.description || "",
        visibleOnSite: initialProperty.visibleOnSite,
        location: initialProperty.location,
        gallery: initialProperty.gallery,
        priceRange: initialProperty.priceRange,
        thumbnailPicture: initialProperty.thumbnailPicture,
        roomsQuanity: initialProperty.roomsQuantity,
        collections: initialProperty.collections,
        starRatings: initialProperty.starRatings,
      }}
      formType="edit"
      submitInProgress={updaingProperty}
      setSubmitInProgress={setUpdatingProperty}
      onSubmitForm={updateProperty}
    />
  );
};
