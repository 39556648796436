import * as yup from "yup";
import {
  PriceRangeMinCommonValidator,
  PriceRangeMaxCommonValidator,
} from "./common/price-range.common.validators";
import { UrlSlugCommonValidator } from "./common/url-slug.common.validators";

export const VloggerFormSchema = yup
  .object({
    name: yup.string().required("Required"),
    urlSlug: UrlSlugCommonValidator.required("Required"),
    description: yup.string(),
    priceRangeMin: PriceRangeMinCommonValidator,
    priceRangeMax: PriceRangeMaxCommonValidator,
  })
  .required();
