import React, {
  forwardRef,
  useContext,
  useImperativeHandle,
  useState,
} from "react";
import Dropzone from "react-dropzone";
import { NotificationContext } from "../contexts/notification.contexts";
import { PostAssetsHttp } from "../http/assets/post.assets.http";
import { IFunctionReturn } from "../interfaces/common/function-return.common.interfaces";
import { IPictureListSortable } from "../validators/common/picture-list-sortable.common.validators";
import { ReactComponent as CloseSvg } from "../assets/svgs/close.svg";

export interface IGetFinalPicturesResult {
  pictures: IPictureListSortable;
}

export interface IDropzoneMultiplePictureElementRef {
  getFinalPictures: () => Promise<IFunctionReturn<IGetFinalPicturesResult>>;
}

export interface IDropzoneMultiplePictureElementProps {
  pictures: IPictureListSortable;
}

export const DropzoneMultiplePictureElement = forwardRef<
  IDropzoneMultiplePictureElementRef,
  IDropzoneMultiplePictureElementProps
>(({ pictures }, ref) => {
  const notificationContext = useContext(NotificationContext);
  const [pictureFiles, setPictureFiles] = useState<Array<File>>([]);
  const [picturesList, setPicturesList] =
    useState<IPictureListSortable>(pictures);

  useImperativeHandle(ref, () => ({
    getFinalPictures,
  }));

  async function getFinalPictures(): Promise<
    IFunctionReturn<IGetFinalPicturesResult>
  > {
    const uploadedPicturesList: IPictureListSortable = [];
    for await (const pictureFile of pictureFiles) {
      const fileUploadResponse = await new PostAssetsHttp().create({
        file: pictureFile,
      });
      if (fileUploadResponse.error) {
        await notificationContext.showError({
          message: fileUploadResponse.errorData.message,
        });
        return {
          error: true,
          message: fileUploadResponse.errorData.message,
        };
      }
      uploadedPicturesList.push({
        uuid: fileUploadResponse.successData.asset.uuid,
        url: fileUploadResponse.successData.asset.url,
        index: 0,
      });
    }

    // set index in the order of array
    const finalList: IPictureListSortable = uploadedPicturesList
      .concat(picturesList)
      .map((item, index) => ({
        uuid: item.uuid,
        url: item.url,
        index,
      }));

    return {
      error: false,
      data: {
        pictures: finalList,
      },
    };
  }

  function removeItemFromPictureList(uuidItem: string) {
    const newPicturesList = picturesList.filter((i) => i.uuid !== uuidItem);
    setPicturesList(newPicturesList);
  }

  return (
    <div className="flex flex-1 flex-col aspect-video">
      <ul className="grid grid-cols-3 gap-4">
        <li key={"dropzone-KuO6PNVYZ5Hn7yoRBOwfA7wEGgjZDsNW"}>
          <Dropzone
            accept={{
              "image/png": [".png", ".jpg", ".jpeg"],
            }}
            onDrop={(acceptedFiles) => {
              setPictureFiles(acceptedFiles);
            }}
          >
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <div className="cursor-pointer flex flex-1 w-full max-w-lg aspect-video overflow-hidden bg-neutral-200 rounded-md">
                  <p className="flex flex-1 p-4 text-center">
                    Drag 'n' drop an image, or click to select a file
                  </p>
                </div>
              </div>
            )}
          </Dropzone>
        </li>
        {pictureFiles.map((pictureFile) => {
          return (
            <li key={pictureFile.name}>
              <div className="flex flex-1 w-full max-w-lg aspect-video overflow-hidden bg-neutral-200 rounded-md">
                <img
                  src={URL.createObjectURL(pictureFile)}
                  className="w-full h-full object-contain"
                  alt="Thumbnail"
                />
              </div>
            </li>
          );
        })}
        {picturesList.map((picture) => {
          return (
            <li key={picture.uuid}>
              <div className="relative flex flex-1 w-full max-w-lg aspect-video overflow-hidden bg-neutral-200 rounded-md">
                <img
                  src={picture.url}
                  className="w-full h-full object-contain"
                  alt="Thumbnail"
                />
                <div className="absolute inset-0 flex flex-col p-4">
                  <div className="flex justify-end">
                    <CloseSvg
                      className="w-7 h-7 border-2 border-white rounded-full cursor-pointer bg-white"
                      onClick={() => removeItemFromPictureList(picture.uuid)}
                    />
                  </div>
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
});
