import React, { useRef, useState } from "react";
import { FunctionComponent } from "react";
import MDEditor, { commands } from "@uiw/react-md-editor";
import { Formik, Field, Form } from "formik";
import {
  DropzoneSinglePictureElement,
  IDropzoneSinglePictureElementRef,
} from "../../elements/dropzone-single-picture.elements";
import { PropertiesFormSchema } from "../../validators/properties-form.validation";
import { ILocationModel } from "../../interfaces/models/location.models.interfaces";
import { LocationDropdownMolecule } from "../../molecules/location-dropdown.molecules";
import { IAssetCommon } from "../../interfaces/common/asset.common.interfaces";
import { IPriceRange } from "../../validators/common/price-range.common.validators";
import LoadingElement from "../../elements/loading.elements";
import {
  DropzoneMultiplePictureElement,
  IDropzoneMultiplePictureElementRef,
} from "../../elements/dropzone-multiple-picture.elements";
import { IPictureListSortable } from "../../validators/common/picture-list-sortable.common.validators";
import {
  IRoomsQuantity,
  RoomsQuantityTypes,
} from "../../validators/common/rooms-quantity.common.data-validators";
import { RoomsQuantityTypeDropdownMolecule } from "../../molecules/rooms-quantity-type-dropdown.molecules";
import { CollectionsDropdownMolecule } from "../../molecules/collections-dropdown.molecules";
import { ICollectionsModel } from "../../interfaces/models/collections.models.interfaces";
import { Switch } from "@headlessui/react";
import { ReactComponent as StarSvg } from "../../assets/svgs/star.svg";

export interface IPropertiesFormData {
  name: string;
  urlSlug: string;
  summary: string;
  description: string;
  visibleOnSite: boolean;
  roomsQuanity: IRoomsQuantity;
  location: ILocationModel | null;
  gallery: IPictureListSortable;
  priceRange: IPriceRange | null;
  thumbnailPicture: IAssetCommon | null;
  collections: Array<ICollectionsModel>;
  starRatings: number | null;
}

interface IPropertiesFormDataInternal extends IPropertiesFormData {
  priceRangeMin: string;
  priceRangeMax: string;
  roomQuantityNumber: number | null;
  roomQuantityType: RoomsQuantityTypes | null;
}

export interface IPropertiesFormPropertiesOrganismProps {
  initialData: IPropertiesFormData;
  submitInProgress: boolean;
  setSubmitInProgress: (state: boolean) => void;
  formType: "add" | "edit";
  onSubmitForm: (data: IPropertiesFormData) => Promise<void>;
}

export const PropertiesFormPropertiesOrganism: FunctionComponent<
  IPropertiesFormPropertiesOrganismProps
> = ({
  submitInProgress,
  setSubmitInProgress,
  formType,
  initialData,
  onSubmitForm,
}) => {
  const [starRatings, setStarRatings] = useState<number | null>(
    initialData.starRatings
  );
  const [visibleOnSite, setVisibleOnSite] = useState(initialData.visibleOnSite);
  const thumbnailImageRef = useRef<IDropzoneSinglePictureElementRef>(null);
  const galleryRef = useRef<IDropzoneMultiplePictureElementRef>(null);

  const [location, setLocation] = useState<ILocationModel | null>(
    initialData.location
  );
  const [collections, setCollections] = useState<Array<ICollectionsModel>>(
    initialData.collections
  );

  return (
    <Formik<IPropertiesFormDataInternal>
      initialValues={{
        name: initialData.name,
        urlSlug: initialData.urlSlug,
        summary: initialData.summary,
        location: initialData.location,
        description: initialData.description,
        visibleOnSite: initialData.visibleOnSite,
        priceRangeMin: initialData.priceRange?.min
          ? `${initialData.priceRange?.min}`
          : "",
        priceRangeMax: initialData.priceRange?.max
          ? `${initialData.priceRange?.max}`
          : "",
        roomsQuanity: initialData.roomsQuanity,
        thumbnailPicture: initialData.thumbnailPicture,
        gallery: [],
        priceRange: null,
        collections: initialData.collections,
        roomQuantityNumber: initialData.roomsQuanity?.quantity || null,
        roomQuantityType: initialData.roomsQuanity?.type || null,
        starRatings: initialData.starRatings,
      }}
      validationSchema={PropertiesFormSchema}
      onSubmit={async (formData) => {
        if (!location) {
          throw new Error(
            "Location is required, this should have been caught by validation"
          );
        }
        if (!thumbnailImageRef.current) {
          throw new Error("thumbnailImageRef is null");
        }
        if (!galleryRef.current) {
          throw new Error("galleryRef is null");
        }

        setSubmitInProgress(true);

        const thumbnailPicture =
          await thumbnailImageRef.current.getFinalPicture();
        if (thumbnailPicture.error) {
          return setSubmitInProgress(false);
        }

        const galleryPictures = await galleryRef.current.getFinalPictures();
        if (galleryPictures.error) {
          return setSubmitInProgress(false);
        }

        await onSubmitForm({
          ...formData,
          visibleOnSite,
          location: location,
          roomsQuanity:
            formData.roomQuantityNumber && formData.roomQuantityType
              ? {
                  quantity: Number(formData.roomQuantityNumber),
                  type: formData.roomQuantityType,
                }
              : null,
          priceRange:
            formData.priceRangeMin.length > 0
              ? {
                  min: Number(formData.priceRangeMin),
                  max:
                    formData.priceRangeMax.length > 0
                      ? Number(formData.priceRangeMax)
                      : Number(formData.priceRangeMin),
                }
              : null,
          collections: collections,
          starRatings: starRatings,
          gallery: galleryPictures.data.pictures,
          thumbnailPicture: thumbnailPicture.data.picture,
        });
      }}
    >
      {({ errors, touched, setFieldValue, values }) => {
        return (
          <Form className="flex flex-1 flex-col">
            <label className="mt-4">Thumbnail Picture</label>
            <div className="flex flex-1 border rounded-lg px-4 py-4 mt-1 flex-col items-center">
              <div className="flex flex-1 w-full max-w-md">
                <DropzoneSinglePictureElement
                  ref={thumbnailImageRef}
                  picture={initialData.thumbnailPicture}
                />
              </div>
            </div>

            <label className="mt-4">
              URL Slug{" "}
              {errors.urlSlug && touched.urlSlug ? (
                <span className="mt-2 text-red-600 text-base font-normal">
                  ({errors.urlSlug})
                </span>
              ) : null}
            </label>
            <Field
              name="urlSlug"
              type="text"
              placeholder="URL Slug"
              className="flex border border-neutral-200 rounded-lg px-4 py-2 mt-1"
            />

            <label className="mt-4">
              Name{" "}
              {errors.name && touched.name ? (
                <span className="mt-2 text-red-600 text-base font-normal">
                  ({errors.name})
                </span>
              ) : null}
            </label>
            <Field
              name="name"
              type="text"
              placeholder="Name"
              className="flex border rounded-lg px-4 py-2 mt-1"
            />

            <label className="mt-4">
              Summary{" "}
              {errors.summary && touched.summary ? (
                <span className="mt-2 text-red-600 text-base font-normal">
                  ({errors.summary})
                </span>
              ) : null}
            </label>
            <Field
              name="summary"
              as="textarea"
              placeholder="Summary"
              className="flex border rounded-lg px-4 py-2 mt-1"
            />

            <label className="mt-4">
              Location{" "}
              {errors.location && touched.location ? (
                <span className="mt-2 text-red-600 text-base font-normal">
                  ({errors.location})
                </span>
              ) : null}
            </label>
            <LocationDropdownMolecule
              selectedLocation={location}
              onChangeLocation={(newLocation) => {
                setLocation(newLocation);
                setFieldValue("location", newLocation);
              }}
            />

            <div className="flex flex-1 mt-4 grid grid-cols-2 gap-6">
              <div className="flex flex-1 flex-col">
                <label className="">
                  Minimum Price{" "}
                  {errors.priceRangeMin && touched.priceRangeMin ? (
                    <span className="mt-2 text-red-600 text-base font-normal">
                      ({errors.priceRangeMin})
                    </span>
                  ) : null}
                </label>
                <Field
                  name="priceRangeMin"
                  type="text"
                  placeholder="Minimum Price"
                  className="flex border rounded-lg px-4 py-2 mt-1"
                />
              </div>

              <div className="flex flex-1 flex-col">
                <label className="">
                  Maximum Price{" "}
                  {errors.priceRangeMax && touched.priceRangeMax ? (
                    <span className="mt-2 text-red-600 text-base font-normal">
                      ({errors.priceRangeMax})
                    </span>
                  ) : null}
                </label>
                <Field
                  name="priceRangeMax"
                  type="text"
                  placeholder="Maximum Price"
                  className="flex border rounded-lg px-4 py-2 mt-1"
                />
              </div>
            </div>

            <div className="flex flex-1 mt-4 grid grid-cols-2 gap-6">
              <div className="flex flex-1 flex-col">
                <label className="">
                  Room Quanity Type{" "}
                  {errors.roomQuantityType && touched.roomQuantityType ? (
                    <span className="mt-2 text-red-600 text-base font-normal">
                      ({errors.roomQuantityType})
                    </span>
                  ) : null}
                </label>
                <RoomsQuantityTypeDropdownMolecule
                  selected={values.roomQuantityType || null}
                  onChange={(newType) => {
                    setFieldValue("roomQuantityType", newType);
                  }}
                />
              </div>

              <div className="flex flex-1 flex-col">
                <label className="">
                  Room Quanity{" "}
                  {errors.roomQuantityNumber && touched.roomQuantityNumber ? (
                    <span className="mt-2 text-red-600 text-base font-normal">
                      ({errors.roomQuantityNumber})
                    </span>
                  ) : null}
                </label>
                <Field
                  name="roomQuantityNumber"
                  type="text"
                  placeholder="Number of rooms"
                  className="flex border rounded-lg px-4 py-2 mt-1"
                />
              </div>
            </div>

            <label className="mt-4">Collections </label>
            <CollectionsDropdownMolecule
              selectedCollections={collections}
              onChangeCollections={(newCollections) => {
                setCollections(newCollections);
              }}
            />

            <label className="mt-4">Rating</label>
            <div className="flex border rounded-lg px-4 py-2 mt-1">
              {[...Array(5).keys()].map((i) => (
                <div key={i} className="m-2">
                  <div
                    onClick={() =>
                      setStarRatings(starRatings === i + 1 ? null : i + 1)
                    }
                  >
                    <StarSvg
                      className={`w-8 h-8 ${
                        starRatings && starRatings > i ? "fill-yellow-500" : ""
                      }`}
                    />
                  </div>
                </div>
              ))}
            </div>

            <label className="mt-4">
              Description{" "}
              {errors.description && touched.description ? (
                <span className="mt-2 text-red-600 text-base font-normal">
                  ({errors.description})
                </span>
              ) : null}
            </label>
            <div
              data-color-mode="light"
              className="border rounded-lg mt-1 overflow-hidden"
            >
              <MDEditor
                value={values.description}
                commands={[
                  commands.bold,
                  commands.italic,
                  commands.checkedListCommand,
                  commands.orderedListCommand,
                  commands.unorderedListCommand,
                ]}
                onChange={(value) => setFieldValue("description", value)}
              />
            </div>

            <label className="mt-4">Gallery</label>
            <div className="flex flex-1 border border-neutral-200 rounded-lg px-4 py-4 mt-1 flex-col items-center">
              <DropzoneMultiplePictureElement
                ref={galleryRef}
                pictures={initialData.gallery}
              />
            </div>

            <label className="mt-4">Visible on site</label>
            <div className="flex flex-1 border border-neutral-200 rounded-lg px-4 py-4 mt-1 flex-row items-center">
              <Switch
                checked={visibleOnSite}
                onChange={setVisibleOnSite}
                className={`${visibleOnSite ? "bg-sky-300" : "bg-gray-200"}
          relative inline-flex h-[38px] w-[74px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
              >
                <span
                  aria-hidden="true"
                  className={`${
                    visibleOnSite ? "translate-x-9" : "translate-x-0"
                  }
            pointer-events-none inline-block h-[34px] w-[34px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                />
              </Switch>
              <p className="pl-4">
                Item will be shown on website only when the toggle is on
              </p>
            </div>

            <button
              type="submit"
              className="border rounded-lg px-4 py-2 mt-6 bg-sky-200 border-sky-200 font-semibold text-center cursor-pointer"
            >
              {submitInProgress ? (
                <LoadingElement />
              ) : (
                <>{formType === "add" ? "Add Property" : "Save Changes"}</>
              )}
            </button>
          </Form>
        );
      }}
    </Formik>
  );
};
