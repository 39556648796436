import React, { useContext } from "react";
import { FunctionComponent } from "react";
import { Formik, Field, Form } from "formik";
import { LoginFormSchema } from "../../validators/login-form.validations";
import { PostAuthHttp } from "../../http/auth/post.auth.http";
import { AccountContext } from "../../contexts/account.contexts";
import { LoginModes } from "../../constants/login-modes.constants";

export interface ILoginFormData {
  email: string;
  password: string;
}

export const LoginFormEmailPasswordAuthsOrganism: FunctionComponent = () => {
  const accountContext = useContext(AccountContext);

  async function loginWithEmailPassword(data: ILoginFormData) {
    const response = await new PostAuthHttp().login({
      mode: LoginModes.EMAIL_PASSWORD,
      email: data.email,
      password: data.password,
    });
    if (response.error) {
      // FIXME: handle error
      return;
    }
    await accountContext?.login({
      accessToken: response.successData.accessToken,
      refreshToken: response.successData.refreshToken,
      redirectOnLogin: "/",
    });
  }

  return (
    <Formik
      initialValues={
        {
          email: "",
          password: "",
        } as ILoginFormData
      }
      validationSchema={LoginFormSchema}
      onSubmit={loginWithEmailPassword}
    >
      {({ errors, touched }) => (
        <Form className="flex flex-1 flex-col">
          <label className="mt-4">
            Email{" "}
            {errors.email && touched.email ? (
              <span className="mt-2 text-red-600 text-base font-normal">
                ({errors.email})
              </span>
            ) : null}
          </label>
          <Field
            name="email"
            type="text"
            placeholder="Eamil"
            className="flex flex-1 border border-neutral-200 rounded-lg px-4 py-2 mt-1"
          />
          <label className="mt-4">
            Password{" "}
            {errors.password && touched.password ? (
              <span className="mt-2 text-red-600 text-base font-normal">
                ({errors.password})
              </span>
            ) : null}
          </label>
          <Field
            name="password"
            type="password"
            placeholder="Password"
            className="flex flex-1 border border-neutral-200 rounded-lg px-4 py-2 mt-1"
          />
          <button
            type="submit"
            className="border border-neutral-200 rounded-lg px-4 py-2 mt-6 bg-sky-200 border-sky-200 font-semibold text-center cursor-pointer"
          >
            Login
          </button>
        </Form>
      )}
    </Formik>
  );
};
