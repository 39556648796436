import React, { useContext, useEffect, useState } from "react";
import { FunctionComponent } from "react";
import { useLocation } from "react-router-dom";
import { NotificationContext } from "../../contexts/notification.contexts";
import { ContentContainer } from "../../elements/content-container.elements";
import { HeaderElement } from "../../elements/header.elements";
import { GetPropertiesHttp } from "../../http/properties/get.properties.http";
import { IPropertyModel } from "../../interfaces/models/properties.models.interfaces";
import { PropertiesListOrganism } from "../../organisms/properties/properties-list.properties.organisms";

export const IndexPropertiesPage: FunctionComponent = () => {
  const { pathname } = useLocation();
  const errorContext = useContext(NotificationContext);

  const [fetchingProperties, setFetchingProperties] = useState<boolean>(false);
  const [properties, setProperties] = useState<Array<IPropertyModel>>();

  useEffect(() => {
    fetchProperties();
  }, []);

  async function fetchProperties() {
    setFetchingProperties(true);
    const response = await new GetPropertiesHttp().getAll({
      limit: 1000,
      offset: properties ? properties.length : 0,
    });
    if (response.error) {
      await errorContext.showError({
        message: response.errorData.message,
      });
      return setFetchingProperties(false);
    }
    const tempLocations = (properties || []).concat(
      response.successData.properties
    );
    setProperties(tempLocations);
    setFetchingProperties(false);
  }

  return (
    <>
      <HeaderElement
        title="Properties"
        action={{
          title: "Add Property",
          href: `${pathname}/add`,
        }}
      />
      <ContentContainer>
        <PropertiesListOrganism
          loading={fetchingProperties}
          properties={properties}
          setProperties={setProperties}
        />
      </ContentContainer>
    </>
  );
};
