import React, { FunctionComponent, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import LoadingElement from "../../elements/loading.elements";
import { ILocationModel } from "../../interfaces/models/location.models.interfaces";

import { ReactComponent as EditSvg } from "../../assets/svgs/edit.svg";
import { DeleteModalButtonElement } from "../../elements/delete-modal-button.elements";
import { DeleteLocationsHttp } from "../../http/locations/delete.locations.http";
import { NotificationContext } from "../../contexts/notification.contexts";

export interface ILocationListOrganismProps {
  loading: boolean;
  locations?: Array<ILocationModel>;
  setLocations: (locations: Array<ILocationModel>) => void;
}

export const LocationListOrganism: FunctionComponent<
  ILocationListOrganismProps
> = ({ loading, locations, setLocations }) => {
  const notificationContext = useContext(NotificationContext);
  const { pathname } = useLocation();

  if (!locations || loading) {
    return (
      <div className="flex flex-1 justify-center">
        <LoadingElement />
      </div>
    );
  }

  if (locations.length === 0) {
    return <p className="w-full text-center">No locations found</p>;
  }

  async function deleteLocation(
    uuidLocationToDelete: string
  ): Promise<{ success: boolean }> {
    if (!locations) throw new Error("Locations cannot be undefined");
    const response = await new DeleteLocationsHttp().deleteByUuid({
      uuid: uuidLocationToDelete,
    });
    if (response.error) {
      await notificationContext.showError({
        message: response.errorData.message,
      });
      return {
        success: false,
      };
    }
    setLocations(locations.filter((loc) => loc.uuid !== uuidLocationToDelete));
    return {
      success: true,
    };
  }

  return (
    <ul className="flex flex-1 flex-col">
      {locations.map((location) => {
        return (
          <li
            key={location.uuid}
            className="flex flex-1 flex-col border rounded-xl p-4 mb-4 grid grid-cols-5 gap-6"
          >
            <div className="aspect-video overflow-hidden bg-neutral-200 col-span-2 rounded-md">
              {location.thumbnailPicture && (
                <img
                  src={location.thumbnailPicture.url}
                  alt=""
                  className="object-cover"
                />
              )}
            </div>
            <div className="flex flex-1 flex-row col-span-3">
              <div className="flex flex-1 flex-col">
                <h2 className="text-2xl font-semibold mb-4">{location.name}</h2>
                <p>{location.description}</p>
              </div>
              <ul className="flex flex-col pl-4">
                <Link to={`${pathname}/${location.uuid}/edit`}>
                  <EditSvg className="w-8 h-8 fill-neutral-600 hover:fill-blue-600" />
                </Link>
                <div className="mt-6">
                  <DeleteModalButtonElement
                    uuid={location.uuid}
                    itemToDelete="Location"
                    onClickDelete={deleteLocation}
                  />
                </div>
              </ul>
            </div>
          </li>
        );
      })}
    </ul>
  );
};
