import React, { useContext, useState } from "react";
import { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";
import { NotificationContext } from "../../contexts/notification.contexts";
import { PostActivitiesHttp } from "../../http/activities/post.activities.http";
import {
  IActivitiesFormData,
  ActivityFormActivitiesOrganism,
} from "./activity-form.activities.organisms";

export const AddActivityFormActivitiesOrganism: FunctionComponent = () => {
  const errorContext = useContext(NotificationContext);
  const navigate = useNavigate();

  const [creatingActivities, setCreatingActivities] = useState<boolean>(false);

  async function addActivity(data: IActivitiesFormData) {
    setCreatingActivities(true);
    const response = await new PostActivitiesHttp().create({
      name: data.name,
      urlSlug: data.urlSlug,
      priceRange: data.priceRange,
      description: data.description,
      duration: data.duration,
      groupSize: data.groupSize,
      whereToPlay: data.whereToPlay,
      uuidListGallery: data.gallery.map((i) => ({
        uuid: i.uuid,
        index: i.index,
      })),
      visibleOnSite: data.visibleOnSite,
      uuidListHeroPictures: data.heroPictures.map((i) => ({
        uuid: i.uuid,
        index: i.index,
      })),
    });
    if (response.error) {
      await errorContext.showError({
        message: response.errorData.message,
      });
      return setCreatingActivities(false);
    }
    navigate(-1);
  }

  return (
    <ActivityFormActivitiesOrganism
      initialData={{
        name: "",
        urlSlug: "",
        description: "",
        visibleOnSite: false,
        gallery: [],
        heroPictures: [],
        priceRange: null,
        duration: null,
        groupSize: null,
        whereToPlay: null,
      }}
      formType="add"
      submitInProgress={creatingActivities}
      setSubmitInProgress={setCreatingActivities}
      onSubmitForm={addActivity}
    />
  );
};
