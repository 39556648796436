import { IPropertyModel } from "../../interfaces/models/properties.models.interfaces";
import { IResponse } from "../../utils/response.utils";
import { IPriceRange } from "../../validators/common/price-range.common.validators";
import { IRoomsQuantity } from "../../validators/common/rooms-quantity.common.data-validators";
import { IShortUuidListSortable } from "../../validators/common/uuid.common.validations";
import Net, { IBody } from "../net.http";

export interface IPostPropertiesHttpCreateData {
  readonly name: string;
  readonly urlSlug: string;
  readonly summary: string;
  readonly description: string | null;
  readonly uuidLocation: string;
  readonly uuidThumbnailPicture: string | null;
  readonly priceRange: IPriceRange;
  readonly visibleOnSite: boolean;
  readonly roomsQuanity: IRoomsQuantity;
  readonly uuidListGallery: IShortUuidListSortable;
  readonly uuidListCollection: IShortUuidListSortable;
  readonly starRatings: number | null;
}

export interface IPostPropertiesHttpCreateResult {
  readonly property: IPropertyModel;
}

export class PostPropertiesHttp {
  async create(
    data: IPostPropertiesHttpCreateData
  ): Promise<IResponse<IPostPropertiesHttpCreateResult>> {
    const body: IBody = {
      name: data.name,
      urlSlug: data.urlSlug,
      summary: data.summary,
      priceRange: data.priceRange,
      starRatings: data.starRatings,
      uuidLocation: data.uuidLocation,
      roomsQuantity: data.roomsQuanity,
      visibleOnSite: data.visibleOnSite,
      uuidListGallery: data.uuidListGallery,
      uuidListCollection: data.uuidListCollection,
      uuidThumbnailPicture: data.uuidThumbnailPicture,
    };
    if (data.description && data.description.length > 0) {
      body.description = data.description;
    }

    const response =
      await Net.getInstance().post<IPostPropertiesHttpCreateResult>({
        path: "/properties",
        body: body,
      });
    return response;
  }
}
