import React, { FunctionComponent, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import LoadingElement from "../../elements/loading.elements";

import { ReactComponent as EditSvg } from "../../assets/svgs/edit.svg";
import { ReactComponent as DeleteSvg } from "../../assets/svgs/delete.svg";
import { ICollectionsModel } from "../../interfaces/models/collections.models.interfaces";
import { DeleteModalButtonElement } from "../../elements/delete-modal-button.elements";
import { DeleteCollectionsHttp } from "../../http/collections/delete.collections.http";
import { NotificationContext } from "../../contexts/notification.contexts";

export interface ICollectionsListOrganismProps {
  loading: boolean;
  collections?: Array<ICollectionsModel>;
  setCollections: (collections: Array<ICollectionsModel>) => void;
}

export const CollectionsListOrganism: FunctionComponent<
  ICollectionsListOrganismProps
> = ({ loading, collections, setCollections }) => {
  const notificationContext = useContext(NotificationContext);
  const { pathname } = useLocation();

  if (!collections || loading) {
    return (
      <div className="flex flex-1 justify-center">
        <LoadingElement />
      </div>
    );
  }

  async function deleteCollection(
    uuidCollectionToDelete: string
  ): Promise<{ success: boolean }> {
    if (!collections) throw new Error("collections cannot be undefined");
    const response = await new DeleteCollectionsHttp().deleteByUuid({
      uuid: uuidCollectionToDelete,
    });
    if (response.error) {
      await notificationContext.showError({
        message: response.errorData.message,
      });
      return {
        success: false,
      };
    }
    setCollections(
      collections.filter((loc) => loc.uuid !== uuidCollectionToDelete)
    );
    return {
      success: true,
    };
  }

  if (collections.length === 0) {
    return <p className="w-full text-center">No Collections found</p>;
  }

  return (
    <ul className="flex flex-1 flex-col">
      {collections.map((collection) => {
        return (
          <li
            key={collection.uuid}
            className="flex flex-1 flex-col border rounded-xl p-4 mb-4 grid grid-cols-5 gap-6"
          >
            <div className="aspect-video overflow-hidden bg-neutral-200 col-span-2 rounded-md">
              {collection.thumbnailPicture && (
                <img
                  src={collection.thumbnailPicture.url}
                  alt=""
                  className="object-cover"
                />
              )}
            </div>
            <div className="flex flex-1 flex-row col-span-3">
              <div className="flex flex-1 flex-col">
                <h2 className="text-2xl font-semibold mb-4">
                  {collection.name}
                </h2>
                <p>{collection.description}</p>
              </div>
              <ul className="flex flex-col pl-4">
                <Link to={`${pathname}/${collection.uuid}/edit`}>
                  <EditSvg className="w-8 h-8 fill-neutral-600 hover:fill-blue-600" />
                </Link>
                <div className="mt-6">
                  <DeleteModalButtonElement
                    uuid={collection.uuid}
                    itemToDelete="Collection"
                    onClickDelete={deleteCollection}
                  />
                </div>
              </ul>
            </div>
          </li>
        );
      })}
    </ul>
  );
};
