import { Listbox } from "@headlessui/react";
import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from "react";
import { NotificationContext } from "../contexts/notification.contexts";
import LoadingElement from "../elements/loading.elements";
import { GetLocationHttp } from "../http/locations/get.locations.http";
import { ILocationModel } from "../interfaces/models/location.models.interfaces";

export interface ILocationDropdownMoleculeProps {
  selectedLocation: ILocationModel | null;
  onChangeLocation: (location: ILocationModel) => void;
}

const ListItem: FunctionComponent<{ location?: ILocationModel }> = ({
  location,
}) => {
  return (
    <div className="flex flex-1 items-center h-8">
      {location !== undefined ? (
        <>
          <div className="h-8 aspect-video overflow-hidden bg-neutral-200 rounded-md mr-2">
            {location.thumbnailPicture && (
              <img src={location.thumbnailPicture.url} alt="" />
            )}
          </div>
          <p>{location.name}</p>
        </>
      ) : (
        <p className="font-semibold text-center w-full">Select Location</p>
      )}
    </div>
  );
};

const LocationsList: FunctionComponent = () => {
  const notificationContext = useContext(NotificationContext);
  const [gettingLocations, setGettingLocation] = useState<boolean>(true);
  const [locations, setLocations] = useState<Array<ILocationModel>>([]);

  useEffect(() => {
    getLocations();
  }, []);

  async function getLocations() {
    setGettingLocation(true);
    const response = await new GetLocationHttp().getAll({});
    if (response.error) {
      await notificationContext.showError({
        message: response.errorData.message,
      });
      return setGettingLocation(false);
    }
    setLocations(response.successData.locations);
    setGettingLocation(false);
  }

  if (gettingLocations) {
    return (
      <div className="flex flex-1 justify-center py-2">
        <LoadingElement />
      </div>
    );
  }

  return (
    <>
      {locations.map((location) => (
        <Listbox.Option
          key={location.uuid}
          value={location}
          className="flex flex-1 px-4 py-2 cursor-pointer"
        >
          <ListItem location={location} />
        </Listbox.Option>
      ))}
    </>
  );
};

export const LocationDropdownMolecule: FunctionComponent<
  ILocationDropdownMoleculeProps
> = ({ selectedLocation, onChangeLocation }) => {
  return (
    <div className="relative flex flex-1 mt-1">
      <Listbox value={selectedLocation} onChange={onChangeLocation}>
        <Listbox.Button className={"flex flex-1 border rounded-md p-2"}>
          {<ListItem location={selectedLocation || undefined} />}
        </Listbox.Button>
        <Listbox.Options className="flex flex-1 flex-col bg-white border rounded-md mt-2 absolute bottom-14 left-0 right-0 max-h-[60vh] overflow-auto">
          <LocationsList />
        </Listbox.Options>
      </Listbox>
    </div>
  );
};
