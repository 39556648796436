import * as yup from "yup";

export const PriceRangeMinCommonValidator = yup
  .number()
  .typeError("Should be a number");
export const PriceRangeMaxCommonValidator = yup
  .number()
  .typeError("Should be a number");

export const PriceRangeCommonValidator = yup
  .object({
    min: PriceRangeMinCommonValidator.required("Required"),
    max: PriceRangeMaxCommonValidator.required("Required"),
  })
  .nullable()
  .default(null);

export type IPriceRange = yup.InferType<typeof PriceRangeCommonValidator>;
