import React from "react";
import { FunctionComponent } from "react";
import { ContentContainer } from "../../elements/content-container.elements";
import { HeaderElement } from "../../elements/header.elements";

export const IndexProfilePage: FunctionComponent = () => {
  return (
    <>
      <HeaderElement title="Profile" />
      <ContentContainer>
        <p>Profile</p>
      </ContentContainer>
    </>
  );
};
