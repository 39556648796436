import React, {
  Fragment,
  MutableRefObject,
  useEffect,
  useRef,
  useState,
} from "react";
import toWords from "convert-rupees-into-words";
import { FunctionComponent } from "react";
import ReactQuill from "react-quill";
import { GeneratePdfModalButtonElement } from "../../elements/generate-pdf-modal-button.elements";

import "react-quill/dist/quill.snow.css";

interface IInvoiceItemData {
  item: string;
  hsnSac: string;
  gstRate: string;
  quantity: string;
  rate: string;
  gstAmount: string;
  amount: string;
  total: string;
  note: string;
}

export interface IInvoiceData {
  invoiceNo: string;
  invoiceDate: string;
  dueDate: string;
  billedBy: {
    name: string;
    address: string;
    gstin: string;
  };
  billedTo: {
    name: string;
    address: string;
    gstin: string;
  };
  account: {
    holderName: string;
    number: string;
    ifsc: string;
    type: string;
    bank: string;
  };
  invoiceItems: Record<string, IInvoiceItemData>;
  amount: string;
  discount: string;
  advance: string;
  gstAmount: string;
  totalAmount: string;
}

interface Props {
  invoiceData: IInvoiceData;
  isPreview: boolean;
  isIgst: boolean;
}

export const TemplateInvoiceOrganism: FunctionComponent<Props> = ({
  invoiceData,
  isPreview,
  isIgst,
}) => {
  const [invoiceNo, setInvoiceNo] = useState(invoiceData.invoiceNo);
  const [invoiceDate, setInvoiceDate] = useState(invoiceData.invoiceDate);
  const [dueDate, setDueDate] = useState(invoiceData.dueDate);

  const [billedByName, setBilledByName] = useState(invoiceData.billedBy.name);
  const [billedByAddress, setBilledByAddress] = useState(
    invoiceData.billedBy.address
  );
  const [billedByGstin, setBilledByGstin] = useState(
    invoiceData.billedBy.gstin
  );
  const [billedByPan, setBilledByPan] = useState("");

  const [billedToName, setBilledToName] = useState(invoiceData.billedTo.name);
  const [billedToAddress, setBilledToAddress] = useState(
    invoiceData.billedTo.address
  );
  const [billedToGstin, setBilledToGstin] = useState(
    invoiceData.billedTo.gstin
  );
  const [billedToPan, setBilledToPan] = useState("");

  const [accountHolderName, setAccountHolderName] = useState(
    invoiceData.account.holderName
  );
  const [accountNumber, setAccountNumber] = useState(
    invoiceData.account.number
  );
  const [accountIfsc, setAccountIfsc] = useState(invoiceData.account.ifsc);
  const [accountType, setAccountType] = useState(invoiceData.account.type);
  const [accountBank, setAccountBank] = useState(invoiceData.account.bank);

  const [invoiceItems, setInvoiceItems] = useState<
    Record<string, IInvoiceItemData>
  >(invoiceData.invoiceItems);

  const [amount, setAmount] = useState(invoiceData.amount);
  const [discount, setDiscount] = useState(invoiceData.discount);
  const [advance, setAdvance] = useState(invoiceData.advance);
  const [gstAmount, setGstAmount] = useState(invoiceData.gstAmount);
  const [totalAmount, setTotalAmount] = useState(invoiceData.totalAmount);

  const [filename, setFilename] = useState("");

  function onChangeInvoiceItem(
    id: string,
    key: keyof IInvoiceItemData,
    value: string
  ) {
    const invoiceItemsNew = { ...invoiceItems };
    invoiceItemsNew[id][key] = value;

    if (
      invoiceItemsNew[id].gstRate.length > 0 &&
      invoiceItemsNew[id].quantity.length > 0 &&
      (invoiceItemsNew[id].rate.length > 0 ||
        invoiceItemsNew[id].amount.length > 0)
    ) {
      const gstRate = Number(invoiceItemsNew[id].gstRate);
      const quantity = Number(invoiceItemsNew[id].quantity);

      let rate: number;
      let amount: number;

      if (key === "amount") {
        amount = Number(invoiceItemsNew[id].amount);
        rate = amount / quantity;
      } else {
        rate = Number(invoiceItemsNew[id].rate);
        amount = quantity * rate;
      }

      const gstAmount = (gstRate / 100) * amount;
      const total = amount + gstAmount;

      invoiceItemsNew[id].rate = `${rate}`;
      invoiceItemsNew[id].amount = `${amount}`;
      invoiceItemsNew[id].gstAmount = `${gstAmount.toFixed(2)}`;

      invoiceItemsNew[id].total = `${total.toFixed(2)}`;
    } else {
      invoiceItemsNew[id].amount = "";
      invoiceItemsNew[id].gstAmount = "";
      invoiceItemsNew[id].total = "";
    }

    setInvoiceItems(invoiceItemsNew);
  }

  function addInoviceItem() {
    const invoiceItemsNew = { ...invoiceItems };
    invoiceItemsNew[Date.now()] = {
      item: "",
      hsnSac: "",
      gstRate: "",
      quantity: "1",
      rate: "",
      amount: "",
      note: "",
      gstAmount: "",
      total: "",
    };
    setInvoiceItems(invoiceItemsNew);
  }

  function getPanFromGstin(gstin: string): string {
    if (gstin.length !== 15) {
      return "";
    }
    return gstin.substring(2, 12);
  }

  useEffect(() => {
    setBilledByPan(getPanFromGstin(billedByGstin));
  }, [billedByGstin]);

  useEffect(() => {
    setBilledToPan(getPanFromGstin(billedToGstin));
  }, [billedToGstin]);

  useEffect(() => {
    let tempAmount = 0;
    let tempTotalAmount = 0;
    let tempGstAmount = 0;
    for (const key in invoiceItems) {
      const item = invoiceItems[key];

      tempAmount += Number(Number(item.amount).toFixed(2));
      tempTotalAmount += Number(Number(item.total).toFixed(2));
      tempGstAmount += Number(Number(item.gstAmount).toFixed(2));
    }

    if (!isNaN(tempAmount) && !isNaN(tempTotalAmount)) {
      const tempAdvance = Number(advance);
      if (!isNaN(tempAdvance)) {
        tempTotalAmount -= tempAdvance;
      }
      setAmount(`${tempAmount.toFixed(2)}`);
      setGstAmount(`${tempGstAmount.toFixed(2)}`);
      setTotalAmount(`${Math.round(tempTotalAmount).toFixed(2)}`);
    } else {
      setAmount(`0`);
      setGstAmount(`0`);
      setTotalAmount(`0`);
    }
  }, [invoiceItems]);

  return (
    <div id="invoice" className="px-4">
      <div className="p-4 flex flex-1 flex-col text-[12px]">
        <section className="flex flex-row justify-between">
          <div>
            <p className="text-color-invoice-primary text-4xl mb-4">Invoice</p>
            <table>
              <tbody>
                <tr>
                  <td>
                    <p className="text-neutral-400 font-semibold">
                      Invoice No #
                    </p>
                  </td>
                  <td>
                    {isPreview ? (
                      <p className="ml-4">{invoiceNo}</p>
                    ) : (
                      <>
                        <input
                          type="text"
                          placeholder="XX"
                          required={true}
                          className="ml-4"
                          value={invoiceNo}
                          onChange={(e) => setInvoiceNo(e.target.value)}
                        />
                      </>
                    )}
                  </td>
                </tr>

                <tr>
                  <td>
                    <p className="text-neutral-400 font-semibold">
                      Invoice Date
                    </p>
                  </td>
                  <td>
                    {isPreview ? (
                      <>
                        <p className="ml-4">
                          {new Date(invoiceDate).toString().substring(4, 15)}
                        </p>
                      </>
                    ) : (
                      <>
                        <input
                          type="date"
                          required={true}
                          className="ml-4"
                          value={invoiceDate}
                          onChange={(e) => setInvoiceDate(e.target.value)}
                        />
                      </>
                    )}
                  </td>
                </tr>

                <tr>
                  <td>
                    <p className="text-neutral-400 font-semibold">Due Date</p>
                  </td>
                  <td>
                    {isPreview ? (
                      <>
                        <p className="ml-4">
                          {new Date(dueDate).toString().substring(4, 15)}
                        </p>
                      </>
                    ) : (
                      <>
                        <input
                          type="date"
                          required={true}
                          className="ml-4"
                          value={dueDate}
                          onChange={(e) => setDueDate(e.target.value)}
                        />
                      </>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="flex flex-1 items-end justify-end">
            <img src="/logo.svg" alt="TeamTrip" className="h-12" />
          </div>
        </section>

        <section className="grid grid-cols-2 gap-4 mt-4">
          <div className="bg-color-invoice-primary/10 rounded-md p-4 flex flex-col">
            <p className="text-color-invoice-primary text-2xl">Billed By</p>
            {isPreview ? (
              <p className="whitespace-pre-line font-semibold">
                {billedByName}
              </p>
            ) : (
              <input
                type="text"
                placeholder="Name Billed By"
                className="bg-transparent font-semibold"
                value={billedByName}
                onChange={(e) => setBilledByName(e.target.value)}
              />
            )}
            {isPreview ? (
              <p className="whitespace-pre-line flex flex-1">
                {billedByAddress}
              </p>
            ) : (
              <textarea
                rows={4}
                placeholder="Address Billed By"
                className="bg-transparent"
                value={billedByAddress}
                onChange={(e) => setBilledByAddress(e.target.value)}
              />
            )}
            <div className="flex">
              <span className="font-semibold">GSTIN:</span>{" "}
              <input
                type="text"
                placeholder="GSTIN Billed By"
                className="bg-transparent w-full ml-2"
                value={billedByGstin}
                onChange={(e) => setBilledByGstin(e.target.value)}
              />
            </div>
            <div className="flex">
              <p className="w-full">
                <span className="font-semibold">PAN:</span> {billedByPan}
              </p>
            </div>
          </div>
          <div className="bg-color-invoice-primary/10 rounded-md p-4 flex flex-col">
            <p className="text-color-invoice-primary text-2xl">Billed To</p>
            {isPreview ? (
              <p className="whitespace-pre-line font-semibold">
                {billedToName}
              </p>
            ) : (
              <input
                type="text"
                placeholder="Name Billed To"
                className="bg-transparent font-semibold"
                value={billedToName}
                onChange={(e) => setBilledToName(e.target.value)}
              />
            )}

            {isPreview ? (
              <p className="whitespace-pre-line flex flex-1">
                {billedToAddress}
              </p>
            ) : (
              <textarea
                rows={4}
                placeholder="Address Billed To"
                className="bg-transparent"
                value={billedToAddress}
                onChange={(e) => setBilledToAddress(e.target.value)}
              />
            )}
            <div className="flex">
              <span className="font-semibold">GSTIN:</span>{" "}
              <input
                type="text"
                placeholder="GSTIN Billed To"
                className="bg-transparent w-full ml-2"
                value={billedToGstin}
                onChange={(e) => setBilledToGstin(e.target.value)}
              />
            </div>
            <div className="flex">
              <p className="w-full">
                <span className="font-semibold">PAN:</span> {billedToPan}
              </p>
            </div>
          </div>
        </section>

        <section className="my-4 max-w-full w-full">
          <table className="table-auto border-separate border rounded-md border-spacing-0	 w-full">
            <thead>
              <tr>
                <td className="px-1"></td>
                <td className="px-1">Item</td>
                {isPreview ? (
                  <></>
                ) : (
                  <td className="border-l px-1 w-[40px]">HSN/SAC</td>
                )}
                <td className="border-l px-1 w-[64px]">GST Rate</td>
                <td className="border-l px-1 w-[50px]">Quantity</td>
                <td className="border-l px-1 w-[92px]">Rate</td>
                <td className="border-l px-1 w-[92px]">Amount</td>
                {isIgst ? (
                  <>
                    <td className="border-l px-1 w-[82px]">IGST</td>
                  </>
                ) : (
                  <>
                    <td className="border-l px-1 w-[82px]">CGST</td>
                    <td className="border-l px-1 w-[82px]">SGST</td>
                  </>
                )}
                <td className="border-l px-1 w-[92px]">Total</td>
              </tr>
            </thead>
            <tbody>
              {Object.keys(invoiceItems).map((key, index) => {
                return (
                  <Fragment key={key}>
                    <tr>
                      <td className="border-t px-1">{index + 1}</td>
                      <td className="border-l border-t">
                        {isPreview ? (
                          <p className="whitespace-pre-line px-1">
                            {invoiceItems[key].item}{" "}
                            <span className="text-[10px] whitespace-nowrap	">
                              (HSN/SAC: {invoiceItems[key].hsnSac})
                            </span>
                          </p>
                        ) : (
                          <textarea
                            rows={2}
                            placeholder="Item"
                            className="w-full block px-1"
                            value={invoiceItems[key].item}
                            onChange={(e) =>
                              onChangeInvoiceItem(key, "item", e.target.value)
                            }
                          ></textarea>
                        )}
                      </td>
                      {isPreview ? (
                        <></>
                      ) : (
                        <td className="border-l border-t">
                          <input
                            type="text"
                            className="w-[60px] px-1"
                            placeholder="HSN/SAC"
                            value={invoiceItems[key].hsnSac}
                            onChange={(e) =>
                              onChangeInvoiceItem(key, "hsnSac", e.target.value)
                            }
                          />
                        </td>
                      )}
                      <td className="border-l border-t">
                        {isPreview ? (
                          <p className="px-1">{invoiceItems[key].gstRate}%</p>
                        ) : (
                          <input
                            type="text"
                            className="w-[58px] px-1"
                            placeholder="GST Rate"
                            value={invoiceItems[key].gstRate}
                            onChange={(e) =>
                              onChangeInvoiceItem(
                                key,
                                "gstRate",
                                e.target.value
                              )
                            }
                          />
                        )}
                      </td>
                      <td className="border-l border-t">
                        {isPreview ? (
                          <p className="px-1">{invoiceItems[key].quantity}</p>
                        ) : (
                          <input
                            type="text"
                            className="w-[50px] px-1"
                            placeholder="Quantity"
                            value={invoiceItems[key].quantity}
                            onChange={(e) =>
                              onChangeInvoiceItem(
                                key,
                                "quantity",
                                e.target.value
                              )
                            }
                          />
                        )}
                      </td>
                      <td className="border-l border-t">
                        {isPreview ? (
                          <p className="px-1">
                            ₹{Number(invoiceItems[key].rate).toFixed(2)}
                          </p>
                        ) : (
                          <input
                            type="text"
                            className="w-[100px] w-20 px-1"
                            placeholder="Rate"
                            value={invoiceItems[key].rate}
                            onChange={(e) =>
                              onChangeInvoiceItem(key, "rate", e.target.value)
                            }
                          />
                        )}
                      </td>
                      <td className="border-l border-t">
                        {isPreview ? (
                          <p className="w-20 px-1">
                            ₹{Number(invoiceItems[key].amount).toFixed(2)}
                          </p>
                        ) : (
                          <input
                            type="text"
                            className="w-[100px] w-20 px-1"
                            placeholder="Amount"
                            value={invoiceItems[key].amount}
                            onChange={(e) =>
                              onChangeInvoiceItem(key, "amount", e.target.value)
                            }
                          />
                        )}
                      </td>
                      {isIgst ? (
                        <>
                          <td className="border-l border-t">
                            <p className="w-20 px-1">
                              ₹{invoiceItems[key].gstAmount}
                            </p>
                          </td>
                        </>
                      ) : (
                        <>
                          <td className="border-l border-t">
                            <p className="w-20 px-1">
                              ₹
                              {(
                                Number(invoiceItems[key].gstAmount) / 2
                              ).toFixed(2)}
                            </p>
                          </td>
                          <td className="border-l border-t">
                            <p className="w-20 px-1">
                              ₹
                              {(
                                Number(invoiceItems[key].gstAmount) / 2
                              ).toFixed(2)}
                            </p>
                          </td>
                        </>
                      )}
                      <td className="border-l border-t">
                        <p className="w-20 px-1">₹{invoiceItems[key].total}</p>
                      </td>
                    </tr>
                    <tr>
                      {isPreview ? (
                        <>
                          {invoiceItems[key].note.length > 0 ? (
                            <td colSpan={100} className="border-t">
                              <div
                                className="w-full block p-4"
                                style={{
                                  listStyle: "unset",
                                }}
                                dangerouslySetInnerHTML={{
                                  __html: invoiceItems[key].note,
                                }}
                              ></div>
                            </td>
                          ) : (
                            <></>
                          )}
                        </>
                      ) : (
                        <td colSpan={100} className="border-t">
                          <div className="h-60">
                            <ReactQuill
                              theme="snow"
                              value={invoiceItems[key].note}
                              className="h-48"
                              onChange={(e) =>
                                onChangeInvoiceItem(key, "note", e)
                              }
                            />
                          </div>
                        </td>
                      )}
                    </tr>
                  </Fragment>
                );
              })}
            </tbody>
          </table>
          {isPreview ? (
            <></>
          ) : (
            <button
              className="bg-blue-200 w-full px-4 py-3 mt-2 rounded-md font-semibold"
              onClick={() => addInoviceItem()}
            >
              Add Item
            </button>
          )}
        </section>

        <section className="grid grid-cols-6 gap-4">
          <div className="col-span-4">
            <div className="">
              <p className="uppercase">
                Total (in words):{" "}
                <span className="font-semibold">
                  {toWords(Number(totalAmount))} ONLY
                </span>
              </p>
              <div className="bg-color-invoice-primary/10 rounded-md p-4 flex flex-col mt-2">
                <p className="text-color-invoice-primary text-xl mb-2">
                  Bank Details
                </p>
                <table>
                  <tbody>
                    <tr>
                      <td className=" font-semibold">Account Holder Name</td>
                      <td>
                        {isPreview ? (
                          <p className="">{accountHolderName}</p>
                        ) : (
                          <input
                            type="text"
                            className="w-full bg-transparent"
                            value={accountHolderName}
                            onChange={(e) =>
                              setAccountHolderName(e.target.value)
                            }
                          />
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td className="font-semibold">Account Number</td>
                      <td>
                        {isPreview ? (
                          <p className="">{accountNumber}</p>
                        ) : (
                          <input
                            type="text"
                            className="w-full bg-transparent"
                            value={accountNumber}
                            onChange={(e) => setAccountNumber(e.target.value)}
                          />
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td className="font-semibold">IFSC</td>
                      <td>
                        {isPreview ? (
                          <p className="">{accountIfsc}</p>
                        ) : (
                          <input
                            type="text"
                            className="w-full bg-transparent"
                            value={accountIfsc}
                            onChange={(e) => setAccountIfsc(e.target.value)}
                          />
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td className="font-semibold">Account Type</td>
                      <td>
                        {isPreview ? (
                          <p className="">{accountType}</p>
                        ) : (
                          <input
                            type="text"
                            className="w-full bg-transparent"
                            value={accountType}
                            onChange={(e) => setAccountType(e.target.value)}
                          />
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td className="font-semibold">Bank</td>
                      <td>
                        {isPreview ? (
                          <p className="">{accountBank}</p>
                        ) : (
                          <input
                            type="text"
                            className="w-full bg-transparent"
                            value={accountBank}
                            onChange={(e) => setAccountBank(e.target.value)}
                          />
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="col-span-2">
            <div>
              <div className="flex flex-1 justify-between">
                <p>Amount</p>
                <p className="text-right">{`₹${amount}`}</p>
              </div>

              {isIgst ? (
                <div className="flex flex-1 justify-between">
                  <p>IGST</p>
                  <p className="text-right">{`₹${gstAmount}`}</p>
                </div>
              ) : (
                <>
                  <div className="flex flex-1 justify-between">
                    <p>SGST</p>
                    <p className="text-right">{`₹${(
                      Number(gstAmount) / 2
                    ).toFixed(2)}`}</p>
                  </div>
                  <div className="flex flex-1 justify-between">
                    <p>CGST</p>
                    <p className="text-right">{`₹${(
                      Number(gstAmount) / 2
                    ).toFixed(2)}`}</p>
                  </div>
                </>
              )}

              {isPreview ? (
                <>
                  {discount.length > 0 && Number(discount) > 0 ? (
                    <div className="flex flex-1 justify-between">
                      <p>Discount</p>
                      <p className="text-right">{`₹${Number(discount).toFixed(
                        2
                      )}`}</p>
                    </div>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <div className="flex flex-1 justify-between">
                  <p>Discount</p>
                  <input
                    type="text"
                    className="w-full bg-transparent text-right ml-2"
                    value={discount}
                    onChange={(e) => setDiscount(e.target.value)}
                  />
                </div>
              )}

              {isPreview ? (
                <>
                  {advance.length > 0 && Number(advance) > 0 ? (
                    <div className="flex flex-1 justify-between">
                      <p>Advance</p>
                      <p className="text-right">{`₹${Number(advance).toFixed(
                        2
                      )}`}</p>
                    </div>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <div className="flex flex-1 justify-between">
                  <p>Advance</p>
                  <input
                    type="text"
                    className="w-full bg-transparent text-right ml-2"
                    value={advance}
                    onChange={(e) => setAdvance(e.target.value)}
                  />
                </div>
              )}

              <div className="flex flex-1 justify-between">
                <p>Total (INR)</p>
                <p className="text-right">{`₹${totalAmount}`}</p>
              </div>
            </div>
          </div>
        </section>
      </div>

      {isPreview ? (
        <></>
      ) : (
        <div className="">
          <input
            type="text"
            placeholder="Filename"
            value={filename}
            onChange={(e) => setFilename(e.target.value)}
            className="w-full border rounded-md mt-6 px-4 py-3"
          />
          <GeneratePdfModalButtonElement
            isIgst={isIgst}
            filename={filename}
            invoiceData={{
              invoiceNo,
              invoiceDate,
              dueDate,
              billedBy: {
                name: billedByName,
                address: billedByAddress,
                gstin: billedByGstin,
              },
              billedTo: {
                name: billedToName,
                address: billedToAddress,
                gstin: billedToGstin,
              },
              account: {
                holderName: accountHolderName,
                number: accountNumber,
                ifsc: accountIfsc,
                type: accountType,
                bank: accountBank,
              },
              invoiceItems,
              amount,
              discount,
              advance,
              gstAmount,
              totalAmount,
            }}
          />
        </div>
      )}
    </div>
  );
};
