import { IEntertainmentsModel } from "../../interfaces/models/entertainments.models.interfaces";
import { IResponse } from "../../utils/response.utils";
import { IPriceRange } from "../../validators/common/price-range.common.validators";
import { IShortUuidListSortable } from "../../validators/common/uuid.common.validations";
import Net, { IBody } from "../net.http";

export interface IPostEntertainmentsHttpCreateData {
  readonly name: string;
  readonly urlSlug: string;
  readonly description: string;
  readonly priceRange: IPriceRange;
  readonly visibleOnSite: boolean;
  readonly uuidCategory: string;
  readonly uuidListGallery: IShortUuidListSortable;
  readonly uuidListHeroPictures: IShortUuidListSortable;
}

export interface IPostEntertainmentsHttpCreateResult {
  readonly entertainment: IEntertainmentsModel;
}

export class PostEntertainmentsHttp {
  async create(
    data: IPostEntertainmentsHttpCreateData
  ): Promise<IResponse<IPostEntertainmentsHttpCreateResult>> {
    const body: IBody = {
      name: data.name,
      urlSlug: data.urlSlug,
      priceRange: data.priceRange,
      uuidCategory: data.uuidCategory,
      visibleOnSite: data.visibleOnSite,
      uuidListGallery: data.uuidListGallery,
      uuidListHeroPictures: data.uuidListHeroPictures,
    };
    if (data.description && data.description.length > 0) {
      body.description = data.description;
    }

    const response =
      await Net.getInstance().post<IPostEntertainmentsHttpCreateResult>({
        path: "/entertainments",
        body: body,
      });
    return response;
  }
}
