import { ICollectionsModel } from "../../interfaces/models/collections.models.interfaces";
import { IResponse } from "../../utils/response.utils";
import Net, { IQuery } from "../net.http";

export interface IGetCollectionsHttpData {
  limit?: number;
  offset?: number;
}

export interface IGetCollectionsHttpResult {
  collections: Array<ICollectionsModel>;
}

export interface IGetCollectionHttpGetByUuidData {
  uuidCollection: string;
}

export interface IGetCollectionHttpGetByUuidResult {
  collection: ICollectionsModel;
}

export class GetCollectionsHttp {
  async getAll(
    data: IGetCollectionsHttpData
  ): Promise<IResponse<IGetCollectionsHttpResult>> {
    const query: IQuery = {};
    if (data.limit) query.limit = data.limit;
    if (data.offset) query.offset = data.offset;
    const response = await Net.getInstance().get<IGetCollectionsHttpResult>({
      path: "/collections",
      query,
    });
    return response;
  }

  async getByUuid(
    data: IGetCollectionHttpGetByUuidData
  ): Promise<IResponse<IGetCollectionHttpGetByUuidResult>> {
    const response =
      await Net.getInstance().get<IGetCollectionHttpGetByUuidResult>({
        path: `/collections/uuid/${data.uuidCollection}`,
      });
    return response;
  }
}
