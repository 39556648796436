import React, { useContext, useState } from "react";
import { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";
import { NotificationContext } from "../../contexts/notification.contexts";
import { PostEntertainmentsHttp } from "../../http/entertainments/post.entertainments.http";
import {
  IEntertainmentsFormData,
  EntertainmentFormEntertainmentsOrganism,
} from "./entertainment-form.entertainments.organisms";

export const AddEntertainmentFormEntertainmentsOrganism: FunctionComponent =
  ({}) => {
    const errorContext = useContext(NotificationContext);
    const navigate = useNavigate();

    const [creatingEntertainments, setCreatingEntertainments] =
      useState<boolean>(false);

    async function addEntertainments(data: IEntertainmentsFormData) {
      if (!data.category) {
        throw new Error("Category is required");
      }
      setCreatingEntertainments(true);
      const response = await new PostEntertainmentsHttp().create({
        name: data.name,
        urlSlug: data.urlSlug,
        priceRange: data.priceRange,
        description: data.description,
        uuidCategory: data.category.uuid,
        visibleOnSite: data.visibleOnSite,
        uuidListGallery: data.gallery.map((i) => ({
          uuid: i.uuid,
          index: i.index,
        })),
        uuidListHeroPictures: data.heroPictures.map((i) => ({
          uuid: i.uuid,
          index: i.index,
        })),
      });
      if (response.error) {
        await errorContext.showError({
          message: response.errorData.message,
        });
        return setCreatingEntertainments(false);
      }
      navigate(-1);
    }

    return (
      <EntertainmentFormEntertainmentsOrganism
        initialData={{
          name: "",
          urlSlug: "",
          description: "",
          visibleOnSite: false,
          gallery: [],
          heroPictures: [],
          priceRange: null,
          category: null,
        }}
        formType="add"
        submitInProgress={creatingEntertainments}
        setSubmitInProgress={setCreatingEntertainments}
        onSubmitForm={addEntertainments}
      />
    );
  };
